import React, { useState, useEffect, useRef } from "react";
import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import deleteIcon from "../../../Asset/images/icons/deleteIcon.svg";
import { styled } from "@mui/material/styles";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ApiLib from "../../../Api";
import { notify, notifyTypes } from "../../../Utils";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import { Value } from "sass";
import AddAuthor from "../../../UICommon/Modal/Author/AddAuthor";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";
const EditCaseStudy = (props) => {
  const API = new ApiLib();
  const [selectedInterest, setSelectedInterest] = useState(props?.data);
  const [values, setValues] = useState({
    title: "",
    tags: [],
    interests: [],
    authors: [],
    industries: [],
    audio: "",
    contentLevel: [],
    trending: false,
    isPremium: false,
    isCertified: false,
    isActive: false,
    minsToRead: "",
    imageValues: {},
    authorImageValues: {},
    audioValues: {},
    isGcc: false,
    isBec: false,
  });
  const audioRef = useRef(null);
  const audioInputRef = useRef(null);
  const [audioFileDeleted, setAudioFileDeleted] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    video: "",
    description: "",
    interests: "",
    imageValues: "",
    speakers: "",
    audioFileError: ""
  });
  const [audioValue, setAudioValue] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });
  const [authorPhoto, setAuthorPhoto] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });
  const [isActive, setIsActive] = useState(props?.data?.isActive);

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };

  const validateForm = (valuesX, shouldeSubmitForm = false) => {
    var isValidForm = true;
    var errorsX = {};
    console.log(valuesX?.imageValues);
    if (valuesX?.imageValues?.loaded === false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.authorImageValues?.loaded === false) {
      isValidForm = false;
      errorsX.authorImageValues = "Author Image is required.";
    }

    if (valuesX?.title === "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.description === "") {
      isValidForm = false;
      errorsX.description = "description is required.";
    }
    if (valuesX?.authors?.length === 0) {
      isValidForm = false;
      errorsX.authors = "Please add atleast one author.";
    }
    if (!valuesX?.minsToRead) {
      isValidForm = false;
      errorsX.minsToRead = "Minutes to read is required.";
    }
    if (valuesX?.scheduledTime
      &&
      new Date(valuesX?.scheduledTime) < new Date()
      &&
      !valuesX?.isPublished
      &&
      valuesX?.isPublished != undefined) {
      isValidForm = false;
      errorsX.scheduledTime = "Please select valid date and time.";
      setPublishOption((publishOption) => true);
    }
    // if (!documentFile?.name) {
    //   isValidForm = false;
    //   errorsX.documentFile = "Document is required.";
    // }

    if (valuesX?.interests?.length == 0) {
      isValidForm = false;
      errorsX.interests = "Please select atleast one interest.";
    }
    if (valuesX?.industries?.length == 0) {
      isValidForm = false;
      errorsX.industries = "Please select atleast one industry.";
    }


    console.log(errorsX);
    if (isValidForm && shouldeSubmitForm) {
      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium"))
        valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free"))
        valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified"))
        valuesX.contentLevel.push("certified");
      if (
        !valuesX.isCertified &&
        !valuesX.contentLevel.includes("non-certified")
      )
        valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC"))
        valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC"))
        valuesX.contentLevel.push("BEC");

      setValues({
        ...values,
        contentLevel: [...valuesX.contentLevel],
      });

      editCaseStudy();
    }
    setErrors({ ...errorsX });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let type = e.target.files[0].type;
      let img = e?.target?.files[0];
      console.log(img, type);
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });
        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      }
    }
  };

  const onAudioChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let audio = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      console.log("audio  -----------------------> type", type);
      if (!type.includes('audio')) {
        setErrors({ ...errors, audioFileError: 'Please select only valid audio files' })
        return;
      }
      // if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
      if (1) {
        setValues({
          ...values,
          audioValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(audio),
            name: e?.target?.files[0]?.name,
          },
        });
        setAudioFileDeleted(false);
        validateForm(
          {
            ...values,
            audioValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(audio),
              name: e?.target?.files[0]?.name,
            },
          },
          false
        );
        if (!values?.audioValues?.loaded) {

        }
        else {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
    audioInputRef.current.value = null;
  };

  const onAuthorImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let type = e.target.files[0].type;
      let img = e?.target?.files[0];
      console.log(img, type);
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          authorImageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });
        validateForm(
          {
            ...values,
            authorImageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      }
    }
  };

  const editCaseStudy = () => {
    var id = selectedInterest?._id;
    console.log("Data Case Study", values);
    if (audioFileDeleted)
      values.audio = "";
    API.editCaseStudy(id, values)
      .then(async (res) => {
        notify(notifyTypes?.success, "Case Study Edited Successfully");
        if (documentFile?.name) {
          const formdata = new FormData();
          formdata.append("content", documentFile, documentFile?.name);
          API.uploadCaseStudyContent(id, formdata)
            .then((res) => console.log(res.data, " updated content"))
            .catch((err) => console.log(err, " error in upload content"));
        }
        if (values?.imageValues?.source) {
          try {
            const res2 = await uploadMedia(id);
            console.log(res2.data, " image updated");
          } catch (err) {
            console.log(err, " image update err");
          }
        }
        if (values?.audioValues?.loaded == true) {
          try {
            const res3 = await uploadMedia3(id);
            console.log(res3.data, " audio updated");
          } catch (err) {
            console.log(err, " audio update err");
          }
        }
        if (values?.authorImageValues?.source) {
          try {
            const res2 = await uploadMedia1(id);
            console.log(res2.data, " author image updated");
          } catch (err) {
            console.log(err, " image update err");
          }
        }
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Edit Case Study");
      })
      .finally((e) => {
        props?.onEdited();
      });
  };

  const uploadMedia1 = async (id) => {
    var mediaData = new FormData();
    mediaData.append("authors", JSON.stringify(values?.authors));
    for (let i in values?.authors) {
      mediaData.append("authorImages", values?.authorImageValues?.source);
    }
    console.log(mediaData, id, "mediaData, id");
    const res = await API.uploadCaseStudyMedia(id, mediaData);
    return res;
  };

  const uploadMedia = async (id) => {
    var mediaData = new FormData();
    mediaData.append("coverImage", values?.imageValues?.source);
    console.log(mediaData, id, "mediaData, id");
    const res = await API.uploadCaseStudyMedia(id, mediaData);
    return res;
  };
  const uploadMedia3 = async (id) => {
    var mediaData = new FormData();
    mediaData.append("audio", values?.audioValues?.source);
    console.log(mediaData, id, "mediaData, id");
    const res = await API.uploadCaseStudyMedia(id, mediaData);
    return res;
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const [publishOption, setPublishOption] = useState(false);
  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
    console.log("yooo");
  };

  useEffect(() => {
    return () => {
      setAudioFileDeleted(false);
    }
  }, [])

  useEffect(() => {
    console.log(props?.data, "props");
    setValues({
      ...values,
      title: props?.data?.title,
      tags: props?.data?.tags,
      interests: props?.data?.interests,
      authors: props?.data?.authors,
      industries: props?.data?.industries,
      audio: props?.data?.audio,
      contentLevel: props?.data?.contentLevel,
      trending: props?.data?.trending,
      isPremium: props?.data?.isPremium,
      isCertified: props?.data?.isCertified,
      isActive: props?.data?.isActive,
      minsToRead: props?.data?.minsToRead,
      coverImage: props?.data?.coverImage,
      imageValues: props?.data?.coverImage
        ? {
          loaded: true,
          url: props?.data?.coverImage,
          type: "",
          name: "",
          source: "",
        }
        : {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
      audioValues: props?.data?.audio
        ? {
          loaded: true,
          url: props?.data?.audio,
          type: "",
          name: "",
          source: "",
        }
        : {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
      authorImageValues: props?.data?.authors[0]?.profilePic
        ? {
          loaded: true,
          url: props?.data?.authors[0]?.profilePic,
        }
        : {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
      isPremium: props?.data?.contentLevel.includes("premium"),
      isCertified: props?.data?.contentLevel.includes("certified"),
      isGcc: props?.data?.contentLevel.includes("GCC"),
      isBec: props?.data?.contentLevel.includes("BEC"),
      scheduledTime: props?.data?.scheduledTime,
      isPublished: props?.data?.isPublished,
    });
  }, []);

  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const addAuthor = () => {
    setErrors({});
    let authorsCp = [...values.authors];
    if (text && text.length >= 3) {
      authorsCp.push({ name: text });
      setValues({ ...values, authors: authorsCp });
      setText("");
    } else {
      setErrors({ ...errors, authors: 'Please enter a valid author name.' })
    }
  };

  const removeAuthor = (authorName) => {
    let authorsCp = [...values.authors];
    authorsCp = authorsCp.filter((el) => el.name !== authorName);
    setValues({ ...values, authors: authorsCp });
  };
  const [showAddAuthor, setShowAddAuthor] = useState(false);

  const textInput = useRef(null);
  const AddCategory = (event) => {
    event.preventDefault();
    const _newCategory = textInput.current.value;
    // console.log(_newCategory);
    setCategories([...new Set([...categories, _newCategory])]);
    setText("");
  };

  const removeCategory = (category) => {
    const _newCategories = [];
    categories.map((existingcategory) => {
      if (existingcategory != category) {
        _newCategories.push(existingcategory);
      }
    });
    setCategories(_newCategories);
  };

  const [documentFile, setDocumentFile] = useState({});

  const handleFile = (e) => {
    setDocumentFile(e?.target?.files[0]);
  };

  const inputRef = React.useRef(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };
  console.log(props?.data?.isPublished, "values?.isPublished")


  const _onDeleteAudio = () => {
    setValues({
      ...values,
      audioValues: {},
    });
    setAudioFileDeleted(true);
  }

  return (
    <div className="add_case_study_main">
      <div
        style={{ position: "relative" }}
        className="heading_publish_container "
      >
        <h1>Edit Case Study</h1>
        {!props?.data?.isPublished && props?.data?.isPublished != undefined ? <button className="custom_publish_button" onClick={publishOptionHandler}>
          Publish <img src={BlueDropdownIcon} alt="BlueDropdownIcon" />
        </button> : null}
        {publishOption ? (
          <div className="publish_option_container">
            <CommonDatePicker values={values} setValues={setValues} validateForm={validateForm} errors={errors} />

          </div>
        ) : null}
      </div>
      {/* <form className="form_add_case_study" action=""> */}
      <div className="form_add_case_study">
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Title</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Title"
                name="title"
                value={values?.title}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>
            {values?.imageValues?.loaded ? (
              <div>
                <img
                  style={{
                    height: 250,
                    width: "100%",
                  }}
                  src={values?.imageValues?.url}
                />
                <br></br>
                <button
                  onClick={() => {
                    setValues({
                      ...values,
                      imageValues: {
                        loaded: false,
                        url: "",
                      },
                    });
                    validateForm(
                      {
                        ...values,
                        imageValues: {
                          loaded: false,
                          url: "",
                        },
                      },
                      false
                    );
                  }}
                >
                  Remove Image
                </button>
              </div>
            ) : (
              <div class="form-group">
                <label>Thumbnail Image Upload (400x320px)</label>
                <br />
                <span style={{ fontSize: "10px", color: "#002566" }}>
                  {" "}
                  (Accepted format is .jpg, .png, .jpeg and size 50-100kb)
                </span>
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="AddPhoto"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
                <div className="error-text">{errors?.imageValues}</div>
                <input
                  type="file"
                  class="form-control"
                  id="AddPhoto"
                  placeholder="Name"
                  style={{ display: "none" }}
                  onChange={onImageChange}
                />
              </div>
            )}
            {/* <div class="form-group">
              <label htmlFor="">Audio</label>
              <label className="custom_audio " htmlFor="Audio">
                {audioValue.loaded == true ? (
                  <img src={audioValue.url} />
                ) : (
                  <img src={importPhotoIcon} alt="" />
                )}
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder=""
                onChange={onImageChange}
              />
            </div> */}
            <div class="form-group">
              <label htmlFor="">Document</label>
              <br />
              <span style={{ fontSize: "10px", color: "#002566" }}>
                {" "}
                (Accepted format is .pdf and size upto 10MB)
              </span>
              {/* <label className="custom_audio " htmlFor="Audio">
                {" "}
                <img src={importPhotoIcon} alt="" />
              </label> */}
              <input
                className="form-control"
                type="file"
                id="Audio1"
                placeholder=""
                onChange={handleFile}
              />
            </div>

            <div class="form-group">
              <label htmlFor="">Audio</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className={values?.audioValues?.loaded ? "custom_audio1" : "custom_audio"} htmlFor="Audio">
                  {values?.audioValues?.loaded ? (
                    <audio ref={audioRef} controls>
                      <source key={values?.audioValues?.url} src={values?.audioValues?.url} />
                    </audio>
                  ) : " Add audio file   "}
                  <img src={importPhotoIcon} alt="" />
                </label>
                {
                  values?.audioValues?.loaded ?
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={_onDeleteAudio}>
                      <img src={deleteIcon} alt="" width={22} className="m-2" />
                    </label>
                    : null
                }

              </div>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder="Audio file"
                onChange={onAudioChange}
                ref={audioInputRef}
              />
              <div className="error-text">{errors?.audioFileError}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Mins to Read</label>
              <input
                className="form-control"
                type="number"
                id=""
                name="minsToRead"
                placeholder="Add Mins to Read"
                value={values.minsToRead}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.minsToRead}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Case Study Deep Link</label>
              <div className="custom_copy_container">
                <input
                  className="form-control"
                  type="text"
                  ref={inputRef}
                  disabled
                  value={`https://forallcommunity.com/case-study/${props?.data?._id}`}
                />
                <button onClick={handleCopy}>
                  {isCopied ? (
                    <DoneIcon color="success" />
                  ) : (
                    <ContentCopyIcon />
                  )}
                </button>
              </div>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Content</label>
              <Editor
                apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Type here...</p>"
                init={{
                  height: TextEditorHeight,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                    "emoticons",
                  ],
                  toolbar:
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | link image media mergetags emoticons ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              /> 
              <textarea
                class="form-control"
                style={{
                  height: 500,
                  width: "100%",
                }}
                name="description"
                value={values?.description}
                onChange={handleChangeValues}
              ></textarea>
            </div> */}
          </div>
          <div
            className="col-lg-6"
            style={
              {
                // marginTop: "11vh"
              }
            }
          >
            <h6 className="setting_heading">Settings</h6>

            <div className="member_free_button_container">
              <p className="member_type">Member type</p>
              <p className="free_premium_container">
                <span className="free_Font">Free</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isPremium}
                        onChange={() => {
                          setValues({
                            ...values,
                            isPremium: !values?.isPremium,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Premium</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">Certification</p>
              <p className="free_premium_container">
                <span className="free_Font">Non-Certified</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isCertified}
                        onChange={() => {
                          setValues({
                            ...values,
                            isCertified: !values?.isCertified,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Certified</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">GCC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isGcc}
                        onChange={() => {
                          setValues({
                            ...values,
                            isGcc: !values?.isGcc,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div className="form-group member_free_button_container">
              <p className="member_type">BEC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isBec}
                        onChange={() => {
                          setValues({
                            ...values,
                            isBec: !values?.isBec,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Tags</label>
              <ChipComponent />
            </div> */}
            <div class="form-group">
              <label htmlFor="">Interest area</label>
              <InterestAreaDropDown
                selectedData={values?.interests}
                onSelectChange={(data) => {
                  setValues({
                    ...values,
                    interests: [...data],
                  });
                }}
              />
              <div className="error-text">{errors?.interests}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Industry type</label>
              <IndustryTypeDropDown
                selectedData={values?.industries}
                onSelectChange={(data) => {
                  setValues({
                    ...values,
                    industries: [...data],
                  });
                }}
              />
              <div className="error-text">{errors?.industries}</div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Organization Type</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}
            {/* <div class="form-group">
              <label htmlFor="">Author Photo/Company Icon</label>
              <label className="custom_audio " htmlFor="Audio">
                {authorPhoto.loaded == true ? (
                  <img src={authorPhoto.url} />
                ) : (
                  <img src={importPhotoIcon} alt="" />
                )}
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder=""
              />
            </div> */}
            {/* <div class="form-group">
              <label htmlFor="">Author Name</label>
              <ChipComponent />
            </div> */}

            <div class="form-group">
              <label htmlFor="">Author Name</label>
              <div className="inline_label_add">
                <input
                  className="ADD_category_input"
                  type="text"
                  placeholder="Add Author Name"
                  name="categoryInput"
                  value={text}
                  onChange={(event) => setText(event?.target?.value)}
                />
                <button className="submitModalButton" onClick={addAuthor}>
                  Add
                </button>
              </div>
              <div className="error-text">{errors?.authors}</div>
              <div className="category_container">
                {values.authors.map((category) => (
                  <div className="category_tile">
                    {" "}
                    <p className="CategoryName"> {category?.name} </p>
                    <button
                      type="button"
                      className="remove_category"
                      onClick={() => removeAuthor(category?.name)}
                    >
                      {" "}
                      <img src={closeIcon} alt="" />{" "}
                    </button>
                  </div>
                ))}
              </div>

              {/* <div class="form-group">
                <label htmlFor="">Author Photo/Company Icon</label>
                <input
                  className="form-control"
                  type="file"
                  id="Audio"
                  placeholder=""
                  onChange={onAuthorImageChange}
                />
                <div className="error-text">{errors?.authorImageValues}</div>
              </div> */}

              <div class="form-group">
                <label>Author Photo/Company Icon (1080x1080px)</label>
                <br />
                <span style={{ fontSize: "10px", color: "#002566" }}>
                  {" "}
                  (Accepted format is .jpg, .png, .jpeg and size 300-350kb)
                </span>
                {values?.authorImageValues?.loaded ? (
                  <div>
                    <img
                      style={{
                        height: 250,
                        width: "100%",
                      }}
                      src={values?.authorImageValues?.url}
                    />
                    <br></br>
                    <button
                      onClick={() => {
                        setValues({
                          ...values,
                          authorImageValues: {
                            loaded: false,
                            url: "",
                          },
                        });
                        validateForm(
                          {
                            ...values,
                            authorImageValues: {
                              loaded: false,
                              url: "",
                            },
                          },
                          false
                        );
                      }}
                      style={{ marginTop: "1rem" }}
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div className="upload_photo_container">
                    <img src={importPhotoIcon} alt="" />
                    <p>
                      Drag and Drop or{" "}
                      <label htmlFor="authorImage"> Browse </label> to upload
                    </p>
                  </div>
                )}
                <div className="error-text">{errors?.authorImageValues}</div>
                <input
                  type="file"
                  class="form-control"
                  id="authorImage"
                  placeholder="Author Photo/Company Icon"
                  onChange={onAuthorImageChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                props?.setOpenEditModel(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {/* </form> */}
      {showAddAuthor ? (
        <ModalContainer setShowSelectSpeaker={setShowAddAuthor}>
          <AddAuthor {...{ setShowAddAuthor, addAuthor }} />
        </ModalContainer>
      ) : null}
    </div>
  );
};

export default EditCaseStudy;
