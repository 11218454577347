import React, { useState, useEffect } from "react";
import "./AddPracticeArea-Modal.scss";
import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import ApiLib from "../../../Api"
import { notify, notifyTypes } from "../../../Utils";


const AddPracticeAreaModal = (props) => {
  const API = new ApiLib();
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const addCategory = (event) => {

    setCategories([...new Set([...categories, text])]);
    setText("");
    console.log(categories,"Added Practice Areas")
  };

  const removeCategory = (category) => {
    const _newCategories = [];
    categories.map((existingcategory) => {
      if (existingcategory != category) {
        _newCategories.push(existingcategory);
      }
    });
    setCategories(_newCategories);
  };


  const validateRequest = () => {
    var isValid = true;
    if (categories?.length == 0) {
      isValid = false;
      setError("Please add Practice Area");
    } else {
      setError("");
    }

    if (isValid) {
      addPractiseArea();
    }
  };

  const addPractiseArea = () => {
    var interestX = [];
    for (let index = 0; index < categories.length; index++) {
      var obj = {
        name: categories[index],
      };
      interestX.push(obj);
    }
    var data = {
      bulk: true,
      practises: interestX,
    };

    console.log(data, "DataPractiseArea" )
    API.addPractiseArea(data)
      .then((res) => {
        console.log("get Practise areas", res?.data);
        notify(notifyTypes?.success, "Practice Area Added Successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error,  `${e.response.data.message}`);
      })
      .finally((e) => {
        props?.onAdded();
        // setLoading(false);
      });
  };

  return (
    <div>
    <h1 className="modal_heading">Add Practice Area</h1>
    <div>
      <p className="ModalPara">Add a Practice Area and press enter</p>
      {/* <form onSubmit={addCategory}> */}
      <input
        className="ADD_category_input"
        type="text"
        placeholder="Add a Practice Area"
        name="categoryInput"
        value={text}
        onChange={handleChange}
        onKeyPress={(event) => {
          if (event.key === "Enter" && text != "") {
            addCategory();
          }
        }}
      />
      <div
        style={{
          color: "red",
        }}
      >
        {error}
      </div>

      <div className="category_container">
        {categories.map((category) => (
          <div key={category} className="category_tile">
            {" "}
            <p className="CategoryName"> {category} </p>
            <button
              type="button"
              className="remove_category "
              onClick={() => removeCategory(category)}
            >
              {" "}
              <img src={closeIcon} alt="" />{" "}
            </button>
          </div>
        ))}
      </div>

      <button
        onClick={() => {
          validateRequest();
        }}
        className="submitModalButton"
        type="submit"
      >
        Save
      </button>
      {/* </form> */}
    </div>
  </div>
  );
};

export default AddPracticeAreaModal;
