import React, { useState, useRef } from "react";
// import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

const CropDemo = ({ src,setCrop,crop,onSelectFile,imgSrc,completedCrop, setCompletedCrop,previewCanvasRef,imgRef,aspect,onImageLoad }) => {
  
  
 
 
 


  console.log(completedCrop,"hiii");
  return (
    <div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          // onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          locked={true}
        >
          <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      )}
    </div>
  );
};
export default CropDemo;
