import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import SearchBarNActionButtonContainer from "../../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../../Asset/images/icons/threeDots.svg";
import "../../InterestArea/interestArea.scss";
// import "../../AddSpeakers/AddSpeakers.scss";



import closeIcon from "../../../Asset/images/icons/closeIcon.svg";

import Pagination from "@mui/material/Pagination";


import svg1 from "../../../Asset/images/avatars/1.svg";


import Apilib from "../../../Api";
import { getDateString, notify, notifyTypes } from "../../../Utils";

import ThreeDotOption from "../../../UICommon/ThreeDotOption/ThreeDotOption";

import "../../InterestArea/interestArea.scss";

import "./../../Users/users.scss"

const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
    <button className="Disabled_statusButton"> Blocked </button>
);
function createData(
    id,
    profileImg,
    name,
    email,
    phoneNo,
    organization,
    certified,
    membership,
    status
) {
    return {
        id,
        profileImg,
        name,
        email,
        phoneNo,
        organization,
        certified,
        membership,
        status,
    };
}

// const rows = [
//   createData(
//     1,
//     svg1,
//     "Regina Cooper",
//     "cooper@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     2,
//     svg2,
//     "Judith Black",
//     "black@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     3,
//     svg3,
//     "Ronald Robertson",
//     "robe@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     4,
//     svg4,
//     "Dustin Williamson",
//     "williams@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     5,
//     svg5,
//     "Calvin Flores",
//     "flores@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     6,
//     svg6,
//     "Robert Edwards",
//     "edwards@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     7,
//     svg7,
//     "Colleen Warren",
//     "warren@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     8,
//     svg8,
//     "Nathan Fox",
//     "fox@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
// ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "firstname",
        numeric: true,
        disablePadding: false,
        label: "Name",
        enableSorting: true,
    },
    {
        id: "Phone",
        numeric: false,
        disablePadding: false,
        label: "Phone",
        enableSorting: false,
    },
    // {
    //     id: "organization",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "organization",
    //     enableSorting: false,

    // },
    // {
    //     id: "isCertified",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Certified",
    //     enableSorting: true,
    // },
    // {
    //     id: "Membership",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Membership ",
    //     enableSorting: false,
    // },
    // {
    //     id: "createdAt",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Created On ",
    //     enableSorting: true,
    // },
    // {
    //   id: "isActive",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Status ",
    //   enableSorting: true,
    // },
    // {
    //     id: "",
    //     numeric: true,
    //     disablePadding: false,
    //     label: " ",
    // },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        debugger;
        onRequestSort(event, property);
    };

    // console.log(headCells);

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                        {headCell.enableSorting && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >

                                {orderBy === headCell.id ? (
                                    <Box className="SortArrow" component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {/* Nutrition */}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

// const rows = [
//     { _id: 1, firstName: "John", lastName: "Doe" },
//     { _id: 2, firstName: "Alice", lastName: "Smith" },
//     { _id: 3, firstName: "Michael", lastName: "Johnson" },
//     { _id: 4, firstName: "Emily", lastName: "Brown" },
//     { _id: 5, firstName: "David", lastName: "Taylor" },
//     { _id: 6, firstName: "Olivia", lastName: "Anderson" },
//     { _id: 7, firstName: "Daniel", lastName: "Martinez" },
//     { _id: 8, firstName: "Sophia", lastName: "Lopez" },
//     { _id: 9, firstName: "Matthew", lastName: "Harris" },
//     { _id: 10, firstName: "Ava", lastName: "Garcia" },
//     { _id: 11, firstName: "Andrew", lastName: "Clark" },
//     { _id: 12, firstName: "Mia", lastName: "Lewis" },
//     { _id: 13, firstName: "Joseph", lastName: "Lee" },
//     { _id: 14, firstName: "Charlotte", lastName: "Walker" },
//     { _id: 15, firstName: "John", lastName: "Smith" },
//     { _id: 16, firstName: "Emily", lastName: "Johnson" },
//     { _id: 17, firstName: "Alice", lastName: "Brown" },
//     { _id: 18, firstName: "Michael", lastName: "Taylor" },
//     { _id: 19, firstName: "David", lastName: "Anderson" },
//     { _id: 20, firstName: "Olivia", lastName: "Martinez" },
//     { _id: 21, firstName: "Daniel", lastName: "Lopez" },
//     { _id: 22, firstName: "Sophia", lastName: "Harris" },
//     { _id: 23, firstName: "Matthew", lastName: "Garcia" },
//     { _id: 24, firstName: "Ava", lastName: "Clark" },
//     { _id: 25, firstName: "Andrew", lastName: "Lewis" },
//     { _id: 26, firstName: "Mia", lastName: "Lee" },
//     { _id: 27, firstName: "Joseph", lastName: "Walker" },
//     { _id: 28, firstName: "Charlotte", lastName: "Smith" },
//     { _id: 29, firstName: "John", lastName: "Johnson" },
//     { _id: 30, firstName: "Emily", lastName: "Brown" },
//     { _id: 31, firstName: "Alice", lastName: "Taylor" },
//     { _id: 32, firstName: "Michael", lastName: "Anderson" },
//     { _id: 33, firstName: "David", lastName: "Martinez" },
//     { _id: 34, firstName: "Olivia", lastName: "Lopez" },
//     { _id: 35, firstName: "Daniel", lastName: "Harris" },
//     { _id: 36, firstName: "Sophia", lastName: "Garcia" },
//     { _id: 37, firstName: "Matthew", lastName: "Clark" },
//     { _id: 38, firstName: "Ava", lastName: "Lewis" },
//     { _id: 39, firstName: "Andrew", lastName: "Lee" },
//     { _id: 40, firstName: "Mia", lastName: "Walker" },
//     { _id: 41, firstName: "Joseph", lastName: "Smith" },
//     { _id: 42, firstName: "Charlotte", lastName: "Johnson" },
//     { _id: 43, firstName: "John", lastName: "Brown" },
//     { _id: 44, firstName: "Emily", lastName: "Taylor" },
//     { _id: 45, firstName: "Alice", lastName: "Anderson" },
//     { _id: 46, firstName: "Michael", lastName: "Martinez" },
//     { _id: 47, firstName: "David", lastName: "Lopez" },
//     { _id: 48, firstName: "Olivia", lastName: "Harris" },
//     { _id: 49, firstName: "Daniel", lastName: "Garcia" },
//     { _id: 50, firstName: "Sophia", lastName: "asdi" },
// ]

const ViewCommunityMembers = (props, { setViewCommunityMembers, id }) => {
    const API = new Apilib();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("firstname");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [deleteID, setDeleteID] = useState(null);

    const [selectedRow, setSelectedRow] = React.useState({});
    const [searchValue, setSearchValue] = React.useState("");
    const [showMoreActionForID, setShowMoreActionForID] = useState(null);

    const [openEditModel, setOpenEditModel] = useState(false);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelection = (event, name) => {
        const selectedIndex = selected.indexOf(name);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        console.log('Page');
        console.log(newPage)
        console.log(rowsPerPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log('Rows change');
        console.log(rowsPerPage)
        console.log(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };





    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

    // const handleChange = (event, value) => {
    //   setPage(value);
    // };

    const needLength = Math.ceil(rows.length / rowsPerPage);

    const [showMoreAction, setShowMoreAction] = useState(false);

    const [openAddUsers, setOpenAddUsers] = useState(false);
    const openAddUserModelHandler = () => {
        setOpenAddUsers(true);
    };

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    const [openAddUSers, setOpenAddUSers] = useState(false);
    const [openEditUSers, setOpenEditUSers] = useState(false);




    useEffect(() => {
        fetchCommunityMembers();
        setOrder("desc");
        setOrderBy('firstname');
    }, []);

    const fetchCommunityMembers = (text) => {
        API.getCommunityMembers(props.id, text)
            .then((res) => {
                console.log("Community Members", res?.data?.data);
                const response = res?.data?.data;
                const arr = response.map((ele) => {
                    return ele.user
                })
                console.log("my new array", arr);
                setRows(arr);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally((e) => {
                // setLoading(false);
            });
    };

    ;









    return (
        <>
            <div>
                <div style={{ marginBottom: "2rem" }} className="heading_publish_container">
                    <h1>View Members</h1>
                </div>
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>

                        <SearchBarNActionButtonContainer
                            onTextChange={(text) => {
                                console.log('This is my seratch text');
                                console.log(text);
                                setPage(1);
                                setSearchValue(text);
                                fetchCommunityMembers(text)
                            }}



                            {...{ setOpenDeleteConfirmation }}
                        />


                        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                        {

                            (rows.length <= 0) ?
                                (
                                    <>

                                        <div style={{ height: 100, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <p>Members <b>Not Found</b></p>
                                        </div>

                                    </>

                                ) :
                                <TableContainer>
                                    <Table
                                        className="custom_table_css"
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>

                                            {stableSort(rows, getComparator(order, orderBy))
                                                .slice(
                                                    (page - 1) * rowsPerPage,
                                                    (page - 1) * rowsPerPage + rowsPerPage
                                                )
                                                .map((row, index) => {
                                                    // console.log(labelId,"labelId");

                                                    return (
                                                        <TableRowComponent
                                                            key={row?._id}

                                                            {...{
                                                                row,
                                                                index,
                                                                selected,
                                                                handleSelection,
                                                                showMoreActionForID,
                                                                setShowMoreActionForID,
                                                                setOpenEditUSers,
                                                                setOpenEditModel,
                                                                setOpenDeleteConfirmation,
                                                                setSelectedRow,
                                                                setDeleteID,
                                                            }}
                                                        />
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (dense ? 33 : 53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                        }

                        <div className="table_pageination">
                            <TablePagination
                                className="rowNo_pageCounting"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page - 1}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />

                            <Pagination
                                className="Table_Pagination_number"
                                shape="rounded"
                                count={needLength}
                                page={page}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                    </Paper>
                    {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
                    <div style={styles.closeButtonContainer}>
                        <div>
                            <button className="viewCommunityCancel" onClick={() => props.setViewCommunityMembers(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>

                </Box>
            </div>
        </>
    );
};
const TableRowComponent = ({
    row,
    index,
    selected,
    handleSelection,
    showMoreActionForID,
    setShowMoreActionForID,
    setOpenEditModel,
    setOpenDeleteConfirmation,
    setSelectedRow,
    changeActiveStatus,
    setDeleteID,
}) => {
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(row.name);
    const labelId = `enhanced-table-checkbox-${index}`;

    //////////////////////////////////////////////////////////////////
    const ref = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            console.log("only outside")
            e.stopPropagation();
            if (
                showMoreActionForID &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                // console.log(ref.current.contains(e.target))
                return setShowMoreActionForID(null);
            }
        };
        const checkIfClickedInside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showMoreActionForID) {
                console.log("only inside")
                e.stopPropagation();
                document
                    .querySelector("*")
                    .addEventListener("mousedown", checkIfClickedOutside);
                // return   setShowMoreActionForID(null);
            }
        };
        document
            .querySelector("*")
            .addEventListener("mousedown", checkIfClickedInside);


        return () => {
            // Cleanup the event listener
            console.log("only cleanup")
            document
                .querySelector("*")
                .removeEventListener("mousedown", checkIfClickedOutside);
            document
                .querySelector("*")
                .removeEventListener("mousedown", checkIfClickedInside);
        };
    }, [showMoreActionForID]);
    //////////////////////////////////////////////////////////////////////////

    const openMoreMenuHandler = () => {
        if (showMoreActionForID == row?._id) {
            setShowMoreActionForID(null);
        } else {
            setShowMoreActionForID(row?._id);
        }
    };

    return (
        <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.name}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                {/*  {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
            </TableCell>
            <TableCell
                className="profileImg_name_container  "
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                style={{ cursor: "pointer" }}

            >
                <div className="users_profileImg_name_email_container">
                    <img
                        style={{
                            height: 40,
                            width: 40,
                        }}
                        src={row?.profilePic ? row?.profilePic : svg1}
                        alt=""
                    />
                    <div className="users_name_email_container">
                        <div>
                            {row.firstname} {row.lastname}
                        </div>
                        <div>{row.email}</div>
                    </div>
                </div>
            </TableCell>

            {
                row?.mobile?.countryCode ?
                    <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            setShowMoreActionForID(null);
                            setOpenEditModel(true);
                            setSelectedRow(row);
                        }}
                        align="left"
                    >
                        + {row?.mobile?.countryCode} {row?.mobile?.number}
                    </TableCell> : <TableCell>
                        N/A
                    </TableCell>
            }

            {/* <TableCell
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                    setShowMoreActionForID(null);
                    setOpenEditModel(true);
                    setSelectedRow(row);
                }}
                align="left"
            >
                {row?.organization?.client_name}
            </TableCell> */}
            {/* <TableCell align="left">
                {row?.isCertified ? (
                    <button className="Active_statusButton"> Yes </button>
                ) : (
                    <button className="Disabled_statusButton"> No </button>
                )}
            </TableCell> */}
            {/* <TableCell align="left">{row.membership}</TableCell>
            <TableCell align="left">{getDateString(row.createdAt)}</TableCell> */}
            {/* <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell> */}
            {/* <TableCell id={labelId} align="left" ref={ref}>
                {" "}
                <button className="more_option_manu" onClick={openMoreMenuHandler}>
                    <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
                </button>
                <ThreeDotOption
                    show={showMoreActionForID === row._id}
                    isActive={row?.isActive}
                    changeActiveStatus={(status) => {
                        console.log("status", status);
                        changeActiveStatus(status, row?._id);
                    }}
                    setOpenEditModel={() => {
                        setShowMoreActionForID(null);
                        setOpenEditModel(true);
                        setSelectedRow(row);
                    }}
                    setDeleteID={() => {
                        setDeleteID(row?._id);
                    }}
                    hideEditStatus={true}
                    {...{ setOpenDeleteConfirmation }}
                />
            </TableCell> */}
        </TableRow>
    );
};


export default ViewCommunityMembers;


const styles = {
    closeButtonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    box: {
        width: '60vw'
    }
}