import React from 'react'
import "../Interest-Area-modal/Add-ModalContainer.scss"
import closeIcon from "../../../Asset/images/icons/closeIcon.svg"

const AddUserModalcontainer = ({children,openAddUsers, setOpenAddUsers}) => {
    const closeModel = () =>{
        setOpenAddUsers(false)
    }
    return (
        <div className="Main_Modal_container">
            <div className="modal_container custom_height_modals">
                {/* <button className='CloseButton' onClick={closeModel}><img src={closeIcon} alt="" /></button>  */}
               {children}
            </div>
        </div>
    )
}

export default AddUserModalcontainer;
