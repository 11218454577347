import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// preview header
import MobileSignal from "../../../Asset/images/icons/MobileSignal.svg";
import Wifi from "../../../Asset/images/icons/Wifi.svg";
import Battery from "../../../Asset/images/icons/Battery.svg";

import preview_video_thumbnail from "../../../Asset/images/speakerVideo/preview_video_thumbnail.png";
import bookmarkIcon from "../../../Asset/images/icons/bookmarkIcon.svg";
import shareIcon from "../../../Asset/images/icons/shareIcon.svg";
import thumbsUp from "../../../Asset/images/icons/thumbsUp.svg";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PreviewSpeakerVideoModel = ({
  data,
  showPreview,
  edit,
  setShowPreview,
  onPublish,
}) => {
  const [values, setValues] = useState(data || {});
  const date = new Date();
  const dateString =
    monthNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear();

  return (
    <div>
      <h6>Preview Mode</h6>
      <div className="row">
        <div className="offset-lg-1 col-lg-4">
          <div className="speaker_video_list_container">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <Stack className="skeleton_square_container1" spacing={1}>
              <Skeleton variant="rectangular" width={30} height={30} />
              <Skeleton variant="rectangular" width={30} height={30} />
            </Stack>

            <div className="preview_HeadingParaTringle">
              <h1>Speaker videos</h1>
              <p>Simply dummy text of the printing</p>
              <Stack className="skeleton_rectangular_container">
                <Skeleton variant="rectangular" width={50} height={20} />
                <Skeleton variant="rectangular" width={50} height={20} />
                <Skeleton variant="rectangular" width={50} height={20} />
              </Stack>
            </div>

            <div className="speaker_video_details_container">
              <div>
                {/* {console.log(values, "values")} */}
                <img
                  className="custom_thumbnain_img"
                  src={values?.imageValues?.url}
                  alt=""
                />
                <div className="flex_container_detail">
                  <div className="speaker_title_disc">
                    <h6>{values?.title}</h6>
                    <span>{dateString}</span>
                    <p>
                      Speaker:{" "}
                      {values?.speakers?.length > 1
                        ? `${values?.speakers[0]?.name}, +${values?.speakers?.length - 1
                        } more`
                        : values?.speakers[0]?.name}
                    </p>
                  </div>
                  <div className="detail_icons">
                    <img src={bookmarkIcon} alt="" />
                    <img src={shareIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="speaker_video_details_container">
              <div>
                <Skeleton variant="rectangular" width={"100%"} height={150} />
                <div className="flex_container_detail">
                  <div className="custom_skeleton_details">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={10}
                    />
                    <Skeleton variant="rectangular" width={"60%"} height={6} />
                    <Skeleton variant="rectangular" width={"80%"} height={8} />
                    {/* <p style={{display:"none"}}>Speaker: Dr. ABC</p> */}
                  </div>
                  <div className="detail_icons">
                    <Skeleton variant="rectangular" width={30} height={30} />
                    <Skeleton variant="rectangular" width={30} height={30} />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span className="bottom_black_line"></span>
            </div>
          </div>
        </div>
        <div className="offset-lg-2 col-lg-4">
          <div className="speaker_video_list_container custom_speaker_video_pageHeight">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>
            <div className="preview_HeadingParaTringle">
              {/* img */}
              {/* <h1>Speaker videos 1</h1> */}
              <h1>{values?.title}</h1>
            </div>
            <div className="speaker_video_details_container">
              <img
                className="custom_thumbnain_img"
                // src={preview_video_thumbnail}
                src={values?.imageValues?.url}
                alt=""
              />
            </div>
            <hr />

            <div className="listen_ready_containers">
              {values?.audioValues?.loaded == true ? (
                <button type="button">Listen</button>
              ) : null}
              <button type="button">Read</button>
              <button type="button">Start a discussion</button>
            </div>

            <div className="SpeakerVideo_DescPara">
              <h4>Description</h4>
              <p>{values?.description}</p>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p> */}
            </div>

            <div className="speaker_video_footer">
              <div className="Video_action_icon_container">
                <img src={thumbsUp} alt="" />
                <img src={bookmarkIcon} alt="" />
                <img src={shareIcon} alt="" />
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bottom_black_line"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="publish_close_button_container">
          <button
            onClick={() => {
              onPublish();
            }}
          >
            {edit ? "Save" : "Add"}
          </button>
          <button
            onClick={() => {
              setShowPreview(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewSpeakerVideoModel;
