import React, { useRef, useState, useEffect } from "react";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import deleteIcon from "../../../Asset/images/icons/deleteIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import "../casestudy.scss";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import PracticeAreaDropDown from "../../../UICommon/DropDowns/PracticeAreaDropDown";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";
const AddResearchPaper = (
  props,
  { setOpenPreviewCaseStudy, setOpenCaseStudy }
) => {
  const [values, setValues] = useState({
    title: "",
    interests: [],
    authors: [],
    authorImageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    researchPaper: {},
    minsToRead: "",
    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    audioValues: {},
    industries: [],
    practiseArea: [],
    // contentLevel: ["free", "non-certified"],
    contentLevel: [],
    isPremium: false,
    isCertified: false,
    isActive: false,
    isGcc: false,
    isBec: false,
    createdAt: new Date(),
  });
  const [errors, setErrors] = useState({
    title: "",
    video: "",
    description: "",
    interests: "",
    imageValues: "",
    // audioValues:{},
    speakers: "",
    audioFileError: ""
  });

  useEffect(() => {
    return () => {
      props.setAudioFileDeleted(false);
    }
  }, []);

  const audioRef = useRef(null);
  const audioInputRef = useRef(null);

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };
  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    console.log('Author Image value');
    console.log(valuesX?.authorImageValues);
    if (valuesX?.imageValues?.loaded == false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.authorImageValues?.loaded == false) {
      isValidForm = false;
      errorsX.authorImageValues = "Author Images is required.";
    }


    if (valuesX?.title == "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.authors?.length === 0) {
      isValidForm = false;
      errorsX.authors = "Please add atleast one author.";
    }
    if (valuesX?.slogan == "") {
      isValidForm = false;
      errorsX.slogan = "Slogan is required.";
    }
    if (valuesX?.practiseName == "") {
      isValidForm = false;
      errorsX.practiseName = "Practise Name is required.";
    }
    if (valuesX?.minsToRead == "") {
      isValidForm = false;
      errorsX.minsToRead = "Mins to Read is required.";
    }
    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }
    if (valuesX?.scheduledTime
      &&
      new Date(valuesX?.scheduledTime) < new Date()) {
      isValidForm = false;
      errorsX.scheduledTime = "Please select valid date and time.";
      setPublishOption((publishOption) => true);
    }

    if (valuesX?.interests?.length == 0) {
      isValidForm = false;
      errorsX.interests = "Please select atleast one interest.";
    }
    if (valuesX?.industries?.length == 0) {
      isValidForm = false;
      errorsX.industries = "Please select atleast one industry.";
    }

    console.log('asnksnbflkhbslkfhsdkfhksdhfikh-----------', valuesX?.researchPaper?.source)
    if (!valuesX?.researchPaper?.source) {
      isValidForm = false;

      errorsX.researchPaper = "Please select document / document should be a pdf";
    }
    // if (valuesX?.practiseArea?.length == 0) {
    //   isValidForm = false;
    //   errorsX.practiseArea = "Please select atleast one practise area.";
    // }
    console.log(`isValidForm : ${isValidForm}`);
    console.log(errorsX);

    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {
      values.contentLevel = [];
      if (values.isPremium && !values.contentLevel.includes("premium")) values.contentLevel.push("premium");
      if (!values.isPremium && !values.contentLevel.includes("free")) values.contentLevel.push("free");
      if (values.isCertified && !values.contentLevel.includes("certified")) values.contentLevel.push("certified");
      if (!values.isCertified && !values.contentLevel.includes("non-certified")) values.contentLevel.push("non-certified");
      if (values.isGcc && !values.contentLevel.includes("GCC")) values.contentLevel.push("GCC");
      if (values.isBec && !values.contentLevel.includes("BEC")) values.contentLevel.push("BEC");
      // values.authors = categories.map(author => ({name: author}));
      console.log("data from preview", values);

      props?.setShowPreview(valuesX);
    }
  };
  const onImageChange = (e) => {
    console.log("Inside onImageChange")
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onAudioChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let audio = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      console.log("audio  -----------------------> type", type);

      if (!type.includes('audio')) {
        setErrors({ ...errors, audioFileError: 'Please select only valid audio files' })
        return;
      }
      // if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
      if (1) {
        setValues({
          ...values,
          audioValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(audio),
            name: e?.target?.files[0]?.name,
          },
        });
        props.setAudioFileDeleted(false);
        validateForm(
          {
            ...values,
            audioValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(audio),
              name: e?.target?.files[0]?.name,
            },
          },
          false
        );
        if (!values?.audioValues?.loaded) {

        }
        else {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
    audioInputRef.current.value = null;
  };

  const onAuthorImageChange = (e) => {
    console.log("inside onAuthorImageChange--------------");
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          authorImageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            authorImageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };
  const onPaperChange = (e) => {
    setValues({
      ...values,
      researchPaper: {},
    });
    console.log("inside onPaperChange");
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "application/pdf") {
        setValues({
          ...values,
          researchPaper: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });
        validateForm(
          {
            ...values,
            researchPaper: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        validateForm(
          {
            ...values,
            researchPaper: {},
          },
          false
        );
      }
    }
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const openPreviewHandler = (event) => {
    event.preventDefault();
    setOpenPreviewCaseStudy(true);
  };
  const [publishOption, setPublishOption] = useState(false);
  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
    console.log("yooo");
  };

  const [dob, setDob] = useState(null);
  const onKeyDown = (e) => {
    e.preventDefault();
  };



  ///////////////////////////////////////////////////////////////////////////////
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };
  /////////////////////////////////////////////////////////////////////////////////
  const textInput = useRef(null);
  const AddCategory = (event) => {
    event.preventDefault();
    const _newCategory = textInput.current.value;
    // console.log(_newCategory);
    setCategories([...new Set([...categories, _newCategory])]);
    setText("");
  };

  const removeCategory = (category) => {
    const _newCategories = [];
    categories.map((existingcategory) => {
      if (existingcategory != category) {
        _newCategories.push(existingcategory);
      }
    });
    setCategories(_newCategories);
  };
  /////////////////////////////////////////////////////////////////////////////////

  const addAuthor = () => {
    let authorsCp = [...values.authors];
    if (text && text.length >= 3) {
      authorsCp.push({ name: text });
      setValues({ ...values, authors: authorsCp });
      setText("");
    } else {
      setErrors({ ...errors, authors: 'Please enter a valid author name.' })
    }
  };

  const removeAuthor = (authorName) => {
    let authorsCp = [...values.authors];
    authorsCp = authorsCp.filter((el) => el.name !== authorName);
    setValues({ ...values, authors: authorsCp });
  };
  const _onDeleteAudio = () => {
    setValues({
      ...values,
      audioValues: {},
    });
    props.setAudioFileDeleted(true);
  }
  // console.log(createdAt,"pushpak")
  return (
    // <form onSubmit={AddCategory}>
    <div className="add_case_study_main">
      <div
        style={{ position: "relative" }}
        className="heading_publish_container"
      >
        <h1>Add Research Paper</h1>
        <button className="custom_publish_button" onClick={publishOptionHandler}>
          Publish <img src={BlueDropdownIcon} alt="BlueDropdownIcon" />
        </button>
        {publishOption ? (
          <div className="publish_option_container">
            <CommonDatePicker values={values} setValues={setValues} validateForm={validateForm} errors={errors} />

          </div>
        ) : null}
      </div>
      <div className="form_add_case_study" action="">
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Title</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Title"
                name="title"
                value={values?.title}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>

            {/* <div class="form-group">
              <div class="form-group">
                <label htmlFor="">Practice Area</label>
                <PracticeAreaDropDown
                  selectedData={values?.practiseArea}
                  onSelectChange={(data) => {
                    // console.log(data, "data");
                    setValues({
                      ...values,
                      practiseArea: [...data],
                    });
                    validateForm({ ...values, practiseArea: [...data] }, false);
                  }}
                />
                <div className="error-text">{errors?.practiseArea}</div>
              </div>
            </div> */}

            <div class="form-group">
              <label htmlFor="">Interest area</label>
              <InterestAreaDropDown
                selectedData={values?.interests}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    interests: [...data],
                  });
                  validateForm({ ...values, interests: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.interests}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Industry type</label>
              <IndustryTypeDropDown
                selectedData={values?.industries}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    industries: [...data],
                  });
                  validateForm({ ...values, industries: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.industries}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Document</label>
              <br /><span style={{ fontSize: "10px", color: "#002566" }}> (Accepted format is .pdf and size upto 10MB)</span>
              {/* <label className="custom_audio " htmlFor="Audio">
                {" "}
                <img src={importPhotoIcon} alt="" />
              </label> */}
              <input
                className="form-control "
                type="file"
                name="researchPaper"
                placeholder=""
                onChange={onPaperChange}
              />

              <div className="error-text">{errors?.researchPaper}</div>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Author Photo/Company Icon</label>
              <input
                className="form-control"
                type="file"
                id="authorImage"
                placeholder="Author Photo/Company Icon"
                onChange={onAuthorImageChange}
              />
            </div> */}

            <div class="form-group">
              <label>Author Photo/Company Icon (1080x1080px)</label>
              <br /><span style={{ fontSize: "10px", color: "#002566" }}> (Accepted format is .jpg, .png, .jpeg and size 300-350kb)</span>
              {values?.authorImageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={values?.authorImageValues?.url}
                  />
                  <br></br>
                  <button
                    onClick={() => {
                      setValues({
                        ...values,
                        authorImageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                      validateForm(
                        {
                          ...values,
                          authorImageValues: {
                            loaded: false,
                            url: "",
                          },
                        },
                        false
                      );
                    }}
                    style={{ marginTop: '1rem' }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="authorImage"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
              )}
              <div className="error-text">{errors?.authorImageValues}</div>
              <input
                type="file"
                class="form-control"
                id="authorImage"
                placeholder="Author Photo/Company Icon"
                onChange={onAuthorImageChange}
                style={{ display: "none" }}
              />
            </div>
            <div class="form-group">
              <label htmlFor="">Author Name</label>
              <div className="inline_label_add">
                <input
                  className="ADD_category_input"
                  type="text"
                  placeholder="Add Author Name"
                  ref={textInput}
                  name="categoryInput"
                  value={text}
                  onChange={(event) => setText(event?.target?.value)}
                />
                <button className="submitModalButton" onClick={addAuthor}>
                  Add
                </button>
              </div>
              <div className="error-text">{errors?.authors}</div>
              <div className="category_container">
                {values?.authors?.map((category) => (
                  <div className="category_tile">
                    {" "}
                    <p className="CategoryName"> {category?.name} </p>
                    <button
                      type="button"
                      className="remove_category"
                      onClick={() => removeAuthor(category?.name)}
                    >
                      {" "}
                      <img src={closeIcon} alt="" />{" "}
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Author Name</label>
              <div className="inline_label_add">
                <input
                  className="ADD_category_input"
                  type="text"
                  placeholder="Add Author Name"
                  ref={textInput}
                  name="categoryInput"
                  value={text}
                  onChange={handleChange}
                />
                <button className="submitModalButton" onClick={AddCategory}>
                  Add
                </button>
              </div>
              <div className="error-text">{errors?.authors}</div>
              <div className="category_container">
                {categories.map((category) => (
                  <div className="category_tile">
                    {" "}
                    <p className="CategoryName"> {category} </p>
                    <button
                      type="button"
                      className="remove_category"
                      onClick={() => removeCategory(category)}
                    >
                      {" "}
                      <img src={closeIcon} alt="" />{" "}
                    </button>
                  </div>
                ))}
              </div>
            </div> */}


            <div class="form-group">
              <label htmlFor="">Mins to Read</label>
              <input
                className="form-control"
                type="text"
                name="minsToRead"
                placeholder="Add Mins to Read"
                value={values?.minsToRead}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.minsToRead}</div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Content</label>
              <textarea
                class="form-control"
                style={{
                  height: 500,
                  width: "100%",
                }}
                name="description"
                value={values?.description}
                onChange={handleChangeValues}
              ></textarea>
              <div className="error-text">{errors?.description}</div>
            </div> */}
          </div>
          <div className="col-lg-6" style={{}}>
            <h6 className="setting_heading">Settings</h6>

            <div className="member_free_button_container">
              <p className="member_type">Member type</p>
              <p className="free_premium_container">
                <span className="free_Font">Free</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isPremium}
                        onChange={() => {
                          setValues({
                            ...values,
                            isPremium: !values?.isPremium,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Premium</span>
              </p>
            </div>
            <div className="member_free_button_container">
              <p className="member_type">Certification</p>
              <p className="free_premium_container">
                <span className="free_Font">Non-Certified</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isCertified}
                        onChange={() => {
                          setValues({
                            ...values,
                            isCertified: !values?.isCertified,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Certified</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">GCC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isGcc}
                        onChange={() => {
                          setValues({
                            ...values,
                            isGcc: !values?.isGcc,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div className="form-group member_free_button_container">
              <p className="member_type">BEC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isBec}
                        onChange={() => {
                          setValues({
                            ...values,
                            isBec: !values?.isBec,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div class="form-group">
              <label>Thumbnail Image Upload (400x320px)</label>
              <br /><span style={{ fontSize: "10px", color: "#002566" }}> (Accepted format is .jpg, .png, .jpeg and size 50-100kb)</span>
              {values?.imageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={values?.imageValues?.url}
                  />
                  <br></br>
                  <button
                    onClick={() => {
                      setValues({
                        ...values,
                        imageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                      validateForm(
                        {
                          ...values,
                          imageValues: {
                            loaded: false,
                            url: "",
                          },
                        },
                        false
                      );
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="AddPhoto"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
              )}
              <div className="error-text">{errors?.imageValues}</div>
              <input
                type="file"
                class="form-control"
                id="AddPhoto"
                placeholder="Name"
                style={{ display: "none" }}
                onChange={onImageChange}
              />
            </div>

            <div class="form-group">
              <label htmlFor="">Audio</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className={values?.audioValues?.loaded ? "custom_audio1" : "custom_audio"} htmlFor="Audio">
                  {values?.audioValues?.loaded ? (
                    <audio ref={audioRef} controls>
                      <source key={values?.audioValues?.url} src={values?.audioValues?.url} />
                    </audio>
                  ) : " Add audio file   "}
                  <img src={importPhotoIcon} alt="" />
                </label>
                {
                  values?.audioValues?.loaded ?
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={_onDeleteAudio}>
                      <img src={deleteIcon} alt="" width={22} className="m-2" />
                    </label>
                    : null
                }

              </div>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder="Audio file"
                onChange={onAudioChange}
                ref={audioInputRef}
              />
              <div className="error-text">{errors?.audioFileError}</div>
            </div>

            <div
              class="form-group"
              style={{ flexDirection: "column", display: "flex" }}
            >
              <label
                style={{
                  marginBottom: "10px",
                }}
                htmlFor=""
              >
                Date <span style={{ color: "red" }}>(Note: This field is strictly for past dated content upload for Admins)</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dob}
                  onChange={(newDob) => {
                    setDob(newDob);
                    if (newDob && dayjs.isDayjs(newDob)) {
                      console.log("dayjs string", newDob.toISOString());
                      setValues({
                        ...values,
                        createdAt: newDob.toISOString(),
                      });
                    }
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                />
              </LocalizationProvider>
              <div className="error-text">{errors?.dob}</div>
            </div>

            {/* <div class="form-group">
            <label htmlFor="">Tags</label>
            <ChipComponent />
          </div> */}

            {/* <div class="form-group">
            <label htmlFor="">Organization Type</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>All</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div> */}
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Next
            </button>
            <button onClick={() => props?.setOpenAddVideo(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    // </form>
  );
};
export default AddResearchPaper;