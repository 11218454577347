import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Button } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const Tryal2 = () => {
  const [visibleNames, setVisibleNames] = React.useState([]);
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value == "" || value == undefined) {
      setPersonName([]);
      // console.log("pushpak");
      return;
    } else {
      setPersonName(
        typeof value === "string"
          ? value.split(",")
          : value.includes("all")
          ? personName.length === names.length
            ? []
            : names
          : value
      );
    }
  };
console.log(personName,"push")
console.log(visibleNames,"nono")
console.log(names,"names")
  React.useEffect(() => {
    setVisibleNames(names.slice(0, 3));
    
  }, []);

  const allSelected = personName.length === names.length;
  const handleDelete = (value) => {
    setPersonName(personName.filter((name) => name !== value));
  };
  const handleSeeMore = () => {
    const nextIndex = visibleNames.length;
    const nextNames = names.slice(nextIndex, nextIndex + 3);
    setVisibleNames((prevVisibleNames) => [...prevVisibleNames, ...nextNames]);
  };
  return (
    <div style={{ backgroundColor: "white", height: "50vh", width: "100%" }}>
      <FormControl sx={{ m: 1, width: "20%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map(
                (value) =>
                  // console.log(value),
                  value && (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => {
                        handleDelete(value);
                      }}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  )
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem
            key="all"
            value="all"
            onClick={() => setPersonName(allSelected ? [] : names)}
            // disabled={allSelected}
          >
            <Checkbox checked={allSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {visibleNames.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
          {visibleNames.length < names.length && (
            <MenuItem onClick={handleSeeMore}>See more</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
export default Tryal2;
