import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import Apilib from "../../Api";
import ModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddPracticeAreaModal from "../../UICommon/Modal/Practice-Area-Modal/AddPracticeAreaModal";
import EditPracticeArea from "../../UICommon/Modal/Practice-Area-Modal/EditPracticeArea";
import { getDateString, notify, notifyTypes } from "../../Utils";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import EditInterestAreaModal from "../../UICommon/Modal/Interest-Area-modal/EditInterestAreaModal";
import DeleteConfirmation from "../InterestArea/DeleteConfirmation/DeleteConfirmation";
const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Disabled </button>
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  // console.log("array ", array)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "createdBy",
    numeric: true,
    disablePadding: false,
    label: "created by",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "isActive",
    numeric: true,
    disablePadding: false,
    label: "status",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box className="SortArrow" component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const PracticeArea = () => {
  const API = new Apilib();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState({});

  const [deleteID, setDeleteID] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  const needLength = Math.ceil(rows.length / rowsPerPage);
  // console.log(page, "page");
  // console.log(emptyRows,"emptyRow");
  // console.log(page,"page");

  // const [selected, setSelected] = React.useState([]);
  const moreAction = selected;

  const [showMoreAction, setShowMoreAction] = useState(false);

  const openMoreMenuHandler = (name) => {
    // if (name==name) {
    console.log(name);
    setShowMoreAction((showMoreAction) => !showMoreAction);
    // }
  };

  useEffect(() => {
    fetchPractiseArea();
    setOrder("desc");
    setOrderBy('createdAt');
  }, []);

  const fetchPractiseArea = (val) => {
    API.getPracticeArea(val)
      .then((res) => {
        setRows(res?.data?.practiseAreas);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {});
  };

  const deletePractiseArea = (id) => {
    API.deletePractiseArea(id)
      .then((res) => {
        console.log("delete Practice area", res?.data);
        fetchPractiseArea();
        notify(notifyTypes?.success, "Deleted Practice Area Successfully.");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const changeActiveStatus = (status, id) => {
    console.log('inside chage status-----------');
    var data = {
      isActive: status,
    };
    console.log('data', data);
    API.editPractise(id, data)
      .then((res) => {
        console.log("get Practise areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchPractiseArea(searchValue);
      });
  };
  const classShort = "classShort"
  return (
    <>
      <TableHeadingContainer
        {...{ isModalOpen, setIsModalOpen }}
        Heading="Practice Area"
      />
      {openEditModel ? (
        <AddSpeakerVideoModalcontainer   {...{classShort}}>
          <EditPracticeArea
            onEdited={() => {
              setOpenEditModel(false);
              fetchPractiseArea(searchValue);
            }}
            data={selectedRow}
            {...{ setOpenEditModel }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deletePractiseArea(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {isModalOpen ? (
            <ModalContainer {...{ isModalOpen, setIsModalOpen }}>
              <AddPracticeAreaModal
                onAdded={() => {
                  setIsModalOpen(false);
                }}
              />
              {/* <EditInterestAreaModal /> */}
            </ModalContainer>
          ) : null}

          <SearchBarNActionButtonContainer
            onTextChange={(text) => {
              console.log(text);
              setPage(1);
              setSearchValue(text);
              fetchPractiseArea(text);
            }}
            {...{ setOpenDeleteConfirmation }}
            className="codered"
          />
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRowComponent
                        key={row?._id}
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setSelectedRow,
                          setDeleteID,
                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
        {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  deletePractiseArea,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      // setShowMoreActionForID();
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID) {
      setShowMoreActionForID(null);
      // console.log("hiiiiii");
    } else {
      setShowMoreActionForID(row._id);
      // console.log("nooooooooooooo");
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell
        component="th"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        id={labelId}
        scope="row"
        padding="none"
      >
        {row?.name}
      </TableCell>
      <TableCell align="left">{row?.createdBy?.name}</TableCell>
      <TableCell align="left">{getDateString(row.createdAt)}</TableCell>
      <TableCell align="left">
        {" "}
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell>

      <TableCell id={labelId} align="left">
        {" "}
        <div ref={ref}>
          <button className="more_option_manu" onClick={openMoreMenuHandler}>
            <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
          </button>
          {/* {showMoreActionForID === row._id ? ( */}
          <ThreeDotOption
            show={showMoreActionForID === row._id}
            isActive={row?.isActive}
            changeActiveStatus={(status) => {
              console.log('rowwwwwwwwwwwwww', row);
              changeActiveStatus(status, row?._id);
            }}
            setOpenEditModel={() => {
              setShowMoreActionForID(null);
              setOpenEditModel(true);
              setSelectedRow(row);
            }}
            setDeleteID={() => {
              setDeleteID(row?._id);
            }}
            onDelete={() => {
              console.log("delete");
              deletePractiseArea(showMoreActionForID);
            }}
            {...{ setOpenDeleteConfirmation }}
          />
        </div>
        {/* ) : null} */}
      </TableCell>
    </TableRow>
  );
};
export default PracticeArea;
