import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import "../AddSpeakers/AddSpeakers.scss";
import "../Users/users.scss";

import ModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddPracticeAreaModal from "../../UICommon/Modal/Practice-Area-Modal/AddPracticeAreaModal";
import EditPracticeArea from "../../UICommon/Modal/Practice-Area-Modal/EditPracticeArea";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";

import svg1 from "../../Asset/images/avatars/1.svg";
import svg2 from "../../Asset/images/avatars/2.svg";
import svg3 from "../../Asset/images/avatars/3.svg";
import svg4 from "../../Asset/images/avatars/4.svg";
import svg5 from "../../Asset/images/avatars/5.svg";
import svg6 from "../../Asset/images/avatars/6.svg";
import svg7 from "../../Asset/images/avatars/7.svg";
import svg8 from "../../Asset/images/avatars/8.svg";
import importPhotoIcon from "../../Asset/images/icons/importPhotoIcon.svg";
import dropdownArrow from "../../Asset/images/icons/dropdownArrow.svg";
import AddModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddUserModalcontainer from "../../UICommon/Modal/Add-Speakers-modal/AddUser-Modalcontainer";

import { Editor } from "@tinymce/tinymce-react";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import EditAdminModal from "./Modals/EditAdmin-modal";
import { FormGroup } from "@mui/material";
import { blue, pink } from "@mui/material/colors";
import Apilib from "../../Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDateString, notifyTypes, notify } from "../../Utils";
import DeleteConfirmation from "../InterestArea/DeleteConfirmation/DeleteConfirmation";


const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Blocked </button>
);
function createData(
  id,
  profileImg,
  name,
  email,
  phoneNo,
  role,
  postType,
  createdOn,
  status
) {
  return {
    id,
    profileImg,
    name,
    email,
    phoneNo,
    role,
    postType,
    createdOn,
    status,
  };
}
// CREATE 
// const rows = [
//   createData(
//     1,
//     svg1,
//     "Regina Cooper",
//     "cooper@example.com",
//     "+1 (070) 123–4567",
//     "Super Admin",
//     "13/13",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     2,
//     svg2,
//     "Judith Black",
//     "black@example.com",
//     "+1 (070) 123–4567",
//     "Admin",
//     "12/13",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     3,
//     svg3,
//     "Ronald Robertson",
//     "robe@example.com",
//     "+1 (070) 123–4567",
//     "Editor",
//     "9/13",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     4,
//     svg4,
//     "Dustin Williamson",
//     "williams@example.com",
//     "+1 (070) 123–4567",
//     "Quality Control",
//     "4/13",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     5,
//     svg5,
//     "Calvin Flores",
//     "flores@example.com",
//     "+1 (070) 123–4567",
//     "Quality Control",
//     "1/13",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     6,
//     svg6,
//     "Robert Edwards",
//     "edwards@example.com",
//     "+1 (070) 123–4567",
//     "Admin",
//     "12/13",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     7,
//     svg7,
//     "Colleen Warren",
//     "warren@example.com",
//     "+1 (070) 123–4567",
//     "Admin",
//     "12/13",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     8,
//     svg8,
//     "Nathan Fox",
//     "fox@example.com",
//     "+1 (070) 123–4567",
//     "Editor",
//     "6/13",
//     "12.09.20",
//     activeButton
//   ),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    enableSorting: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    enableSorting: false,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created On ",
    enableSorting: true,
  },
  {
    id: "isActive",
    numeric: true,
    disablePadding: false,
    label: "Status ",
    enableSorting: false,
  },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
          className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <Box
                    className="SortArrow"
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Admins = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [currentUserId, setCurrentUserId] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");

  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "super"
  });

  const [imageValues, setImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    title: "",
    organization: "",
    description: "",
  });

  const [editImageValues, setEditImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const API = new Apilib();


  useEffect(() => {
    const id = localStorage.getItem("userId");
    setCurrentUserId(id);
    fetchAdmins();
    setOrder("desc");
    setOrderBy('createdAt');
  }, [])

  const fetchAdmins = (text) => {
    API.getAdmins(text)
      .then((res) => {
        setRows(res?.data?.data);
        setOrder("desc");
        setOrderBy('createdAt');
      })
      .catch((e) => {
        console.log(e);
      })
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);



  const [openAddUsers, setOpenAddUsers] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const openAddUserModelHandler = () => {
    setOpenAddUsers(true);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const onDoneHandler = (e) => {
    const payload = {
      email: values.email,
      password: values.password,
      name: values.name,
      role: values.role,
      phone: values.mobile,
    }
    console.log(payload);
    console.log("values before submit : ", payload);
    API.addAdmin(payload)
      .then((res) => {
        setOpenAddUsers(false);
        notify(notifyTypes?.success, "Admin Added Successfully.");
        fetchAdmins();
      })
      .catch((e) => {
        console.log(e);
        e.response.data.error?.includes('duplicate key error collection') ? notify(notifyTypes?.error, `Email already exists.`)
          : notify(notifyTypes?.error, `${e.response.data.error}`);

      })
      .finally((e) => {
        setValues({
          name: "",
          mobile: "",
          email: "",
          password: "",
          role: "super"
        })
        setErrors({})
      });
  };

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  const validateForm = (valuesX, shouldeSubmitForm) => {
    const isValidEmail = validateEmail(valuesX?.email)
    console.log(valuesX);
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Name is required.";
    } else if (valuesX.name.length < 8) {
      isValidForm = false;
      errorsX.name = "Name length must be atleast 8 characters long";
    }

    if (valuesX?.mobile == "") {
      isValidForm = false;
      errorsX.mobile = "Phone Number is required.";
    }
    if (valuesX?.email == "") {
      isValidForm = false;
      errorsX.email = "Email is required.";
    } else if (!isValidEmail) {
      isValidForm = false;
      errorsX.email = "Please enter valid email";
    }



    if (valuesX?.role == "") {
      isValidForm = false;
      errorsX.role = "Role is required.";
    }

    if (valuesX?.password == "") {
      isValidForm = false;
      errorsX.password = "Password is required.";
    } else if (!checkPassword(valuesX?.password)) {
      isValidForm = false;
      errorsX.password = 'Password should have minimum length of 8, one special symbol , upper and lowercase characters'
    }

    setErrors({ ...errorsX });

    if (isValidForm && shouldeSubmitForm) {
      onDoneHandler(valuesX);
    }
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const onImageChange = (e) => {
    console.log(e.target);
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      let name = e?.target?.files[0].name;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setImageValues({
          loaded: true,
          url: URL.createObjectURL(img),
          type: type,
          name: name,
          source: e?.target?.files[0],
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onCancelModal = () => {
    setValues({
      name: "",
      mobile: "",
      email: "",
      password: "",
      role: "super"
    })
    setErrors({})
    setOpenAddUsers(false)
  }

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editAdmin(id, data)
      .then((res) => {
        notify(notifyTypes?.success, "Updated the status successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchAdmins(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const deleteUser = (id) => {
    console.log("Inside deleteUser --------------------------------------", id)

    API.deleteAdmin(id)
      .then((res) => {
        console.log("delete User", res?.data);
        notify(notifyTypes?.success, "Admin Deleted Successfully.");
        fetchAdmins();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Admin.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };


  return (
    <>
      <div className="Page_heading_container" style={{ marginBottom: "2rem" }}>
        <h1>Admins</h1>
        <button onClick={openAddUserModelHandler} className="Add_Head_Button">
          <img style={{ marginRight: "0px" }} src={AddIcon} alt="" />
        </button>
        {openEditModel ? (
          <AddSpeakerVideoModalcontainer>
            <EditAdminModal values={selectedRow} fetchAdmins={fetchAdmins} searchValue={searchValue} {...{ setOpenEditModel }} />
            {/* <EditSpeakerVideoModal
              id={selectedRow?._id}
              interests={selectedRow?.interests}
              industries={selectedRow?.industries}
              speakers={selectedRow?.speakers}
              setShowPreview={(values) => {
                // console.log("abhishelk");
                setPreviewValues(values);
                setShowPreview(true);
                setEditID(selectedRow?._id);
              }}
              {...{
                showPreview,
                // setShowPreview,
                setOpenEditModel,
              }}
            /> */}
          </AddSpeakerVideoModalcontainer>
        ) : null}
      </div>

      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deleteUser(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}
      {openAddUsers ? (
        <AddSpeakerVideoModalcontainer {...{ openAddUsers, setOpenAddUsers }}>
          <div className="All_speaker_form_container">
            <div className="model_head_container">
              <div className="underLine_section">
                <h6>Admins</h6>
              </div>
            </div>
            <h1 className="Dpecker_heading">Add Admin</h1>

            <form className="add_specker_form">
              <div className="row">
                {/* <div className="col-lg-6">
                  <div class="form-group">
                    <label htmlFor="" style={{ marginBottom: "0px" }}>
                      Photo ( 1080x1080px)
                    </label>
                    <br />
                    <span style={{ fontSize: "10px", color: "#002566" }}>
                      {" "}
                      (Accepted format is .jpg, .png, .jpeg and size 300-350kb)
                    </span>
                    {imageValues.loaded == true ? (
                      <div>
                        <img
                          style={{
                            height: "25vh",
                            width: "70%",
                          }}
                          src={imageValues.url}
                        />
                        <br></br>
                        <button
                          onClick={() => {
                            setImageValues({
                              loaded: false,
                              url: "",
                              type: "",
                              name: "",
                              source: "",
                            });
                          }}
                        >
                          Remove Image
                        </button>
                      </div>
                    ) : (
                      <div className="upload_photo_container" style={{ width: "50%" }}>
                        <img src={importPhotoIcon} alt="" />
                        <p>
                          Drag and Drop or{" "}
                          <label htmlFor="AddPhoto"> Browse </label> to
                          upload
                        </p>
                      </div>
                    )}
                    <input
                      type="file"
                      class="form-control"
                      id="AddPhoto"
                      placeholder="Name"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 custom_text_editor mt-4">
                  <div class="form-group">
                    <label htmlFor="">Role</label>
                    <select
                      name="role"
                      value={values?.role}
                      class="form-select"
                      aria-label="Default select example"
                      onChange={handleChangeValues}
                    >
                      <option disabled >-select role-</option>
                      <option value="super" >Super Admin</option>
                      <option value="quality">Quality Admin</option>
                      <option value="content">Content Admin</option>
                    </select>
                    <div className="error-text">{errors?.role}</div>
                  </div>
                  {/* <div class="form-group">
                    <label htmlFor="">Post Type</label>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Interest Area"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Industry Type"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Banner Ads"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Add Speakers"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Speaker Videos"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Case Study"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Research Paper"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Blog"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Events"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Practice Area"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Best People Practices"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Users"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Admins"
                      />
                    </FormGroup>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label htmlFor="">Name</label>
                    <input
                      value={values?.name}
                      name="name"
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Name"
                      onChange={handleChangeValues}
                    />
                    <div className="error-text">{errors?.name}</div>
                  </div>
                  <div class="form-group">
                    <label htmlFor="">Phone Number</label>
                    <div className="mobile_no_main_container">
                      <select
                        class="form-select phone_no_start_select"
                        aria-label="Default select example"
                      >
                        <option selected>+91</option>
                        <option value="2">+92</option>
                        <option value="3">+93</option>
                      </select>
                      {/* <CountrySelect className="form-control" /> */}
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        name="mobile"
                        value={values?.mobile}
                        onChange={handleChangeValues}
                        placeholder="99 9999 9999"
                      />
                    </div>
                    {/* Uncomment when integrating API */}
                    <div className="error-text">{errors?.mobile}</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      value={values?.email}
                      name="email"
                      type="email"
                      class="form-control"
                      id=""
                      placeholder="email"
                      onChange={handleChangeValues}
                    />
                    <div className="error-text">{errors?.email}</div>
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      value={values?.password}
                      name="password"
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Password"
                      onChange={handleChangeValues}
                    />
                    <div className="error-text">{errors?.password}</div>
                  </div>
                </div>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {/* Same as */}
              <ToastContainer />
              <div className="done_cancel_container">
                <button
                  onClick={() => validateForm(values, true)}
                  className="btn done_button"
                  type="button"
                >
                  Done
                </button>
                <button
                  onClick={onCancelModal}
                  className=" btn cancel_button"
                  type=""
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </AddSpeakerVideoModalcontainer>
      ) : null}

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <SearchBarNActionButtonContainer className="codered"
            onTextChange={(text) => {
              console.log('This is my seratch text');
              console.log(text);
              setPage(1);
              setSearchValue(text);
              fetchAdmins(text);
            }}
            {...{ setOpenDeleteConfirmation }}
          />

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    // console.log(labelId,"labelId");

                    return (
                      <TableRowComponent
                        key={row?._id}
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setSelectedRow,
                          setDeleteID,
                          currentUserId,

                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
  setOpenDeleteConfirmation,
  currentUserId
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      console.log("only outside")
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        // console.log(ref.current.contains(e.target))
        return setShowMoreActionForID(null);
      }
    };
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showMoreActionForID) {
        console.log("only inside")
        e.stopPropagation();
        document
          .querySelector("*")
          .addEventListener("mousedown", checkIfClickedOutside);
        // return   setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);


    return () => {
      // Cleanup the event listener
      console.log("only cleanup")
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row._id);
    }
  };


  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell
        className="profileImg_name_container  "
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <div className="users_profileImg_name_email_container">
          <img src={row.profileImg} alt="" />
          <div className="users_name_email_container">
            <div>{row.name}</div>
            <div>{row.email}</div>
          </div>
        </div>
      </TableCell>

      <TableCell align="left">{row.phone ? `+91 ${row.phone}` : 'N/A'}</TableCell>
      <TableCell align="left">{row.role ? row.role : 'N/A'}</TableCell>
      {/* <TableCell align="left">{row?.postType?.length > 0 ? row.postType : '-'}</TableCell> */}



      {
        row.createdAt ? (
          <TableCell className="custom_column_grey_color" align="left">
            {getDateString(row.createdAt)}
          </TableCell>
        ) : <TableCell align="left">
          {'N/A'}
        </TableCell>
      }




      {/* <TableCell className="Active_statusButton" align="left">{row.isActive ? 'Active' : 'Not Active'}</TableCell> */}
      <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell>
      <TableCell id={labelId} align="left" ref={ref}>
        {" "}
        <button className="more_option_manu" onClick={openMoreMenuHandler}>
          <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
        </button>
        {/* {showMoreActionForID === row.id ? */}
        <ThreeDotOption
          show={showMoreActionForID === row._id}
          isActive={row?.isActive}
          changeActiveStatus={(status) => {
            console.log("status", status);
            changeActiveStatus(status, row?._id);
          }}
          setOpenEditModel={() => {
            setShowMoreActionForID(null);
            setOpenEditModel(true);
            setSelectedRow(row);
          }}
          setDeleteID={() => {
            setDeleteID(row?._id);
          }}
          cannotDeleteSelf={currentUserId === row?._id}
          hideEditStatus={true}
          {...{ setOpenDeleteConfirmation }}
        />
        {/* : null} */}
      </TableCell>
    </TableRow>
  );
};
export default Admins;
