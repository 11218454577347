import { combineReducers } from "redux";
import { productsReducer, selectedProductsReducer } from "./ProductsReducer";
import { UserReducer } from "./userReducer";
import { loginReducer } from "./LoginReducer";

const reducers = combineReducers({
  allProducts: productsReducer,
  userDetails: UserReducer,
  loginReducer: loginReducer
});
export default reducers;
