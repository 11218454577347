import React, { Fragment, useEffect, useState } from "react";

import "../../../Asset/scss/login/login.scss";
import Loginsvg from "../../../Asset/images/LoginImage.svg";
import { Link } from "react-router-dom";
import Apilib from "../../../Api";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getDateString, notify, notifyTypes } from "../../../Utils";

import eyeIconHide from "../../../Asset/images/hide.svg";
import eyeIcon from "../../../Asset/images/showPass.svg";
import { useDispatch } from "react-redux";
import { setLoginData } from "../../../redux/actions/LoginActions";
import RefreshIcon from "@mui/icons-material/Refresh";

const Login = () => {
  const API = new Apilib();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("a5c2w3");

  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  
  const refreshCaptcha = () => {
    let result = '';;
    const len = characters.length;
    for(let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * len));
    }

    setCaptcha(result);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: rememberMe ? localStorage.getItem("email") : "",
      password: rememberMe ? localStorage.getItem("pass") : "",
      captcha:""
    },
    validationSchema: Yup.object({
      email: Yup.string().label("Email"),
      password: Yup.string().label("Password"),
      // captcha:Yup.string().required('Required')
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("form submitted",values);
      LoginFun();
    },
  });
  console.log("DataRecieved");

  useEffect(() => {
    console.log(localStorage, "Value");
    refreshCaptcha();
  }, []);

  useEffect(() => {
    let status = localStorage.getItem("rememberMe");
    if (+status === 1) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
    return () => {};
  }, []);

  function LoginFun() {
    console.log("Function Working", formik);
    if (!formik.isValid) return;
    const UserInfo = { email: formik.values.email, password: formik.values.password };
    console.log(UserInfo, "UserIfo");
    if(formik.values.captcha===''){
      notify(notifyTypes?.error, `Please enter captcha`);
      refreshCaptcha()
      return;
    }
    if(captcha!= formik.values.captcha){
      notify(notifyTypes?.error, `Enter correct captcha`);
      refreshCaptcha()
      return;
    }
    if (rememberMe) {
      localStorage.setItem("email", formik.values.email);
      localStorage.setItem("pass", formik.values.password);
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("pass", "");
    }
    API.Login(UserInfo)
      .then((res) => {
        console.log(res.data, "LoginDataRes");

        if (res.data.accessToken) {
          console.log("This is my user id");
          console.log(res?.data?.admin?._id);
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("adminDetail", JSON.stringify(res?.data?.admin));
          localStorage.setItem("userId", res?.data?.admin?._id);
          localStorage.setItem("loginResponse", res?.data?.admin?.role);
          // dispatch(setLoginData(res.data));
          navigate("/dashboard", { replace: true });
          notify(notifyTypes?.success, "User Logged in Successfully");
        } else {
          notify(notifyTypes?.error, "Invalid username/password");
        }
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Login");
      });
  }
  const [toggleType, setToggleType] = useState(false);

  const ToggleMAnuHandler = () => {
    setToggleType((toggleType) => !toggleType);
  };

  return (
    <Fragment>
      <div className="custom">
        <div className="MainContainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 login_img_container displayFlexCenter">
                <img src={Loginsvg} alt="" />
              </div>
              <div className="offset-lg-1 col-lg-5 col-md-5 displayFlexCenter">
                <div className="container FormContainer">
                  <h4 className="Login_heading text-center">
                    Login To Your Account
                  </h4>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="Lable_input_container">
                      <label
                        htmlFor="exampleInputEmail1 "
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email "
                        className="form-control LogingEmail "
                        id="email"
                        name="email"
                        aria-describedby="emailHelp"
                        onChange={formik.handleChange}
                        value={formik.values.email || ""}
                        onBlur={formik.handleBlur}
                        focused={true}
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="Lable_input_container">
                      <label
                        htmlFor="exampleInputPassword1 "
                        className="form-label"
                      >
                        Password
                      </label>
                      <div className="afterEye">
                        {/* ///////////////////////////////////////////////////////////// */}
                        <input
                          type={toggleType ? "text" : "password"}
                          className="form-control LoginPasswoard"
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password || ""}
                          onBlur={formik.handleBlur}
                          focused={true}
                          placeholder="Enter Password"
                        />
                        <div onClick={ToggleMAnuHandler}>
                          {toggleType ? (
                            <img
                              className="showPAssword"
                              src={eyeIcon}
                              alt=""
                            />
                          ) : (
                            <img src={eyeIconHide} alt="" />
                          )}
                        </div>

                        {/* ///////////////////////////////////////////////////////////// */}
                      </div>
                    </div>
                    <div className="Lable_input_container">
                      <label
                        htmlFor="exampleInputPassword1 "
                        className="form-label"
                      >
                        Enter Captcha
                      </label>
                      <div className="afterEye">
                        {/* ///////////////////////////////////////////////////////////// */}
                        <input
                          type={"text"}
                          className="form-control LoginPasswoard"
                          id="captcha"
                          name="captcha"
                          onChange={formik.handleChange}
                          value={formik.values.captcha }
                          onBlur={formik.handleBlur}
                          
                          placeholder="Enter captcha"
                        />
                        {/* <div onClick={ToggleMAnuHandler}>
                          {toggleType ? (
                            <img
                              className="showPAssword"
                              src={eyeIcon}
                              alt=""
                            />
                          ) : (
                            <img src={eyeIconHide} alt="" />
                          )}
                        </div> */}

                        {/* ///////////////////////////////////////////////////////////// */}
                      </div>
                    </div>
                    <div className="captcha-form d-flex justify-content-beteen align-items-center mb-3">
                      <div className="captcha-bg p-2 px-3 ">{captcha}</div>
                      <div className="captcha-refresh" onClick={refreshCaptcha}>
                        <RefreshIcon />{" "}
                      </div>
                      {/* <div>
                        {" "}
                        <input
                          type="text "
                          className="form-control"
                          // style={{width:"150px"}}
                          style={{width:"10rem"}}
                          // id="captcha"
                          name="captcha"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          focused={true}
                          value={formik.values.captcha}
                          placeholder="Enter Captcha"
                        />
                      </div> */}
                    </div>
                    <div className="Lable_input_container form-check">
                      <div className="Rember_forgot_flex">
                        <div>
                          <input
                            type="checkbox"
                            className="form-check-input rembercheckBox_input"
                            id="exampleCheck1"
                            checked={rememberMe}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setRememberMe(true);
                                localStorage.setItem("rememberMe", 1);
                              } else {
                                setRememberMe(false);
                                localStorage.setItem("rememberMe", 0);
                                localStorage.setItem("email", "");
                                localStorage.setItem("pass", "");
                              }
                            }}
                          />
                          <label
                            className="form-check-label rembercheckBox "
                            htmlFor="exampleCheck1"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div className="Forgot_Password_container">
                          <Link to="RecoverPassword">
                            <h6>Forgot Password?</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <Link to="/RecoverPassword"> */}
                    <button
                      type="submit"
                      className="btn btn-primary Login_button"
                    >
                      Log In
                    </button>
                    {/* </Link> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Login;
