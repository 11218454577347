import React from 'react'
import "./Add-ModalContainer.scss"
import closeIcon from "../../../Asset/images/icons/closeIcon.svg"
const AddModalContainer = ({ closeMod, children, isModalOpen, setIsModalOpen, setShowSelectSpeaker }) => {

    const closeModel = () => {
        setShowSelectSpeaker(false)
        // props?.setIsModalOpen(false)
        console.log("close model")
    }
    return (
        <div className="Main_Modal_container" style={{ zIndex: "99999" }}>
            <div className="modal_container">
                <button className='CloseButton' onClick={closeModel}><img src={closeIcon} alt="" /></button>
                {children}
            </div>
        </div>
    )
}

export default AddModalContainer;
