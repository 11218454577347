import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// preview header
import MobileSignal from "../../../Asset/images/icons/MobileSignal.svg";
import Wifi from "../../../Asset/images/icons/Wifi.svg";
import Battery from "../../../Asset/images/icons/Battery.svg";

import preview_video_thumbnail from "../../../Asset/images/speakerVideo/preview_video_thumbnail.png";
import bookmarkIcon from "../../../Asset/images/icons/bookmarkIcon.svg";
import shareIcon from "../../../Asset/images/icons/shareIcon.svg";
import thumbsUp from "../../../Asset/images/icons/thumbsUp.svg";
import studyListImg from "../../../Asset/images/casestudy/studyListImg.png";
import profilephoto from "../../../Asset/images/casestudy/profile photo.png";
import backArrow from "../../../Asset/images/icons/backArrow.svg";
import ssLogoBPP from "../../../Asset/images/ssLogoBPP.png";
// import backArrow from "../../../Asset/images/icons/backArrow.svg";
import "../bestPeople.scss";
const PreviewAddBestPeoplePractices = ({
  data,
  onPublish,
  setOpenPreviewCaseStudy,
}) => {
  const [values, setValues] = useState(data || {});
  return (
    <div className="preview_case_study_main">
      <h6 className="heading_preview_mode">Preview Mode</h6>
      <div className="row">
        <div className=" col-lg-5">
          <div className="speaker_video_list_container custom_for_all_heights">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <Stack className="skeleton_square_container1" spacing={1}>
              <Skeleton variant="rectangular" width={30} height={30} />
              <Skeleton variant="rectangular" width={30} height={30} />
            </Stack>

            <div className="preview_HeadingParaTringle">
              <h1>Best People Pratices</h1>
              <p>Simply dummy text of the printing</p>
              <Stack className="skeleton_rectangular_container">
                <Skeleton variant="rectangular" width={90} height={30} />
                <Skeleton variant="rectangular" width={90} height={30} />
                <Skeleton variant="rectangular" width={90} height={30} />
              </Stack>
            </div>
            <hr />

            <div className="bestPeople_preview_tile">
              <div className="logo_thumbsup_container">
                <img
                  style={{
                    height: 50,
                    width: 50,
                  }}
                  src={values?.imageValues?.url}
                  alt=""
                />
                <img src={thumbsUp} alt="" />
              </div>
              <h4>{values?.title}</h4>
              {/* <span>Slogan:</span>
              <p>{values?.slogan}</p> */}
            </div>
            <Stack className="bestPeople_preview_tile_skeleton">
              <Skeleton variant="rectangular" width={"100%"} height={170} />
              <br />
              <Skeleton variant="rectangular" width={"100%"} height={170} />
              <br />
            </Stack>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span className="bottom_black_line"></span>
            </div>
          </div>
        </div>

        <div className="offset-lg-2 col-lg-5">
          <div className="speaker_video_list_container custom_speaker_video_pageHeight case_study_detail_preview custom_for_all_heights">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>
            <img src={backArrow} alt="" />
            <div className="logo_name_type_container">
              <div>
                {/* <img src={ssLogoBPP} alt="" /> */}
                <img src={values?.imageValues?.url} alt="" />
                <h4>{values?.title}</h4>
              </div>
              <div className="industry_type_area_container">
                <div>
                  <span>Industry Type</span>
                  <h5>{values?.industries[0]?.name}</h5>
                </div>
                <div className="for_border_right"></div>
                <div>
                  <span>Practice Areas</span>
                  <h5>{values?.practiseArea[0]?.name}</h5>
                </div>
              </div>
            </div>
            <div className="BPP_preview_title_para">
              <h6>{values?.practiseName}</h6>
              <p>{values?.description}</p>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum{" "}
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum{" "}
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum{" "}
              </p> */}
            </div>

            <div className="speaker_video_footer">
              <div className="Video_action_icon_container">
                <img src={thumbsUp} alt="" />
                <img src={bookmarkIcon} alt="" />
                <img src={shareIcon} alt="" />
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bottom_black_line"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="publish_close_button_container">
          <button
            onClick={() => {
              onPublish();
            }}
          >
            Publish
          </button>
          <button onClick={() => setOpenPreviewCaseStudy(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};
export default PreviewAddBestPeoplePractices;
