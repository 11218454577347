import React, { useRef } from "react";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import "../casestudy.scss";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import deleteIcon from "../../../Asset/images/icons/deleteIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";

// avatar
import svg1 from "../../../Asset/images/avatars/1.svg";
import svg2 from "../../../Asset/images/avatars/2.svg";
import svg3 from "../../../Asset/images/avatars/3.svg";
import svg4 from "../../../Asset/images/avatars/4.svg";
import moreThubnailSlides from "../../../Asset/images/moreThubnailSlides.svg";
import blankThubnailSlides from "../../../Asset/images/blankThubnailSlides.svg";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDebugValue } from "react";
import { useEffect } from "react";
import Apilib from "../../../Api";

import SelectSpeaker from "../../../UICommon/DropDowns/SelectSpeaker";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

const TextEditorHeight = "30vh";
const EditEvent = ({
  setOpenEditEvent,
  setOpenEventPreview,
  setPreviewValues,
  currentEvent,
  changeActiveStatus,
  setOpenDeleteConfirmation,
  setCurrEventId,
}) => {
  const API = new Apilib();
  
  const [startDate, setStartDate] = React.useState(dayjs(new Date()));
  const [endDate, setEndDate] = React.useState(dayjs(new Date()));
  const [events, setEvents] = React.useState({
    title: currentEvent.title,
    description: currentEvent.description,
    contentLevel: [],
    completed: false,
    location: currentEvent?.location,
    type: currentEvent?.type,
    knowMoreLink: currentEvent?.knowMoreLink,
    registrationLink: currentEvent?.registrationLink,
    startDateOfEvent: currentEvent?.startDateOfEvent,
    endDateOfEvent: currentEvent?.endDateOfEvent,
    isPremium: currentEvent?.contentLevel.includes('premium'),
    isCertified: currentEvent?.contentLevel.includes('certified'),
    isGcc: currentEvent?.contentLevel.includes('GCC'),
    isBec: currentEvent?.contentLevel.includes('BEC'),
    imageValues: currentEvent?.thumbnail ? {
      loaded: true,
      url: currentEvent?.thumbnail,
      type: "",
      name: "",
      source: "",
    }
      : {
        loaded: false,
        url: "",
        type: "",
        name: "",
        source: "",
      },
    mediaValues: [],
    modalType: "edit",
    speakers: [...currentEvent?.speakers],
    isActive: currentEvent?.isActive,
  });

  const [showSelectSpeaker, setShowSelectSpeaker] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [selectedSpeakers, setSelectedSpeakers] = React.useState([]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent(),"pushhhhhhhhhhed");
    }
  };
  const openPreviewHandler = (event) => {
    event.preventDefault();
    validateForm(events, true);
  };

  const handleChangeValues = (event) => {
    setEvents({
      ...events,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...events,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };

  const onMediaChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let mediaArr = events.mediaValues;
      e.target.files.map(file => {
        let img = e?.target?.file;
        let type = e?.target?.file?.type;
        if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
          mediaArr.push({
            loaded: true,
            source: e?.target?.file,
            url: URL.createObjectURL(img),
          })
        } else {
          // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
        }
      })
    }
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    if (valuesX?.imageValues?.loaded === false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.title === "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.description === "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }
    if (valuesX?.startDateOfEvent === "") {
      isValidForm = false;
      errorsX.startDateOfEvent = "Start Date is required.";
    }
    if (valuesX?.endDateOfEvent === "") {
      isValidForm = false;
      errorsX.endDateOfEvent = "End Date is required";
    }

    setErrors({ ...errorsX });
    // console.log(errorsX);
    if (isValidForm && shouldeSubmitForm) {
      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium"))
        valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free"))
        valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified"))
        valuesX.contentLevel.push("certified");
      if (
        !valuesX.isCertified &&
        !valuesX.contentLevel.includes("non-certified")
      )
        valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC"))
        valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC"))
        valuesX.contentLevel.push("BEC");

      setOpenEditEvent(false);
      setPreviewValues(events);
      setOpenEventPreview(true);
    }
  };

  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setEvents({
          ...events,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };


  // useEffect(() => {
  //   console.log("inside use effect edit", events);
  // }, [events]);

  useEffect(() => {
    API.getSpeakers()
      .then((res) => {
        console.log("get speakers", res?.data?.speakers);
        let newSpeakers = [];
        res.data.speakers.map(speaker => {
          if(currentEvent?.speakers.includes(speaker._id)) newSpeakers.push(speaker);
        })
        console.log("new speakers  ----> ", newSpeakers);
        setSelectedSpeakers(newSpeakers);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
      });
  }, []);

  const inputRef = React.useRef(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };
console.log("currentEvent",currentEvent)
  return (
    <div className="add_case_study_main">
      {showSelectSpeaker ? (
        <ModalContainer {...{ setShowSelectSpeaker }}>
          <SelectSpeaker
            selectedData={selectedSpeakers}
            onSelectChange={(data) => {
              setEvents({
                ...events,
                speakers: data,
              });
              validateForm(
                {
                  ...events,
                  speakers: data,
                },
                false
              );
            }}
            {...{ setShowSelectSpeaker }}
          />
        </ModalContainer>
      ) : null}
      <div className="heading_publish_container custom_add_event_container" >
        <h1>Edit event</h1>

        <div onClick={(e) => {
          e.preventDefault()
          setOpenDeleteConfirmation(true);
        }} style={{ border: "2px solid black", cursor: 'pointer', padding: '5px 7px', borderRadius: '8px' }}>
          <img src={deleteIcon} alt="" sx={{ width: '100px', height: '100px' }} />
        </div>
      </div>

      {/* <h1 className="Add_event_heading_title">Edit Event</h1> */}
      <form className="form_add_case_study" action="">
        <div className="row">
          <div className="col-lg-6">
            {/* <div class="form-group">
              <label>Photo/Video Upload</label>
              <div className="upload_photo_container">
                <img src={importPhotoIcon} alt="" />
                <p>
                  Drag and Drop or <label htmlFor="AddPhoto"> Browse </label> to
                  upload
                </p>
              </div>
              <input
                type="file"
                class="form-control"
                id="AddPhoto"
                placeholder="Name"
                style={{ display: "none" }}
              />
              <div className="more_thumb_images_container">
                <img src={moreThubnailSlides} alt="" />
                <img src={moreThubnailSlides} alt="" />
                <img src={moreThubnailSlides} alt="" />
                <img src={blankThubnailSlides} alt="" />
              </div>
            </div> */}
            <div class="form-group">
              <label htmlFor="">Title</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Add title"
                name="title"
                value={events?.title}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Type of Event</label>
              <div className="mobile_no_main_container">
                <select
                  class="form-select phone_no_start_select"
                  aria-label="Default select example"
                  name="type"
                  value={events?.type}
                  onChange={handleChangeValues}
                >
                  <option selected value="Virtual">Virtual</option>
                  <option value="Live">Live</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>
              <div className="error-text">{errors?.mobile}</div>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Description</label>
              <Editor
                apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={events?.description}
                onEditorChange={
                  (value) => {
                    setEvents({
                      ...events,
                      description: value,
                    });
                  }
                }
                name="description"
                initialValue={events?.description}
                init={{
                  height: TextEditorHeight,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                    "emoticons",
                  ],
                  toolbar:
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div> */}

            <div class="form-group">
              <label htmlFor="">Description</label>
              <textarea
                class="form-control"
                style={{
                  height: 500,
                  width: "100%",
                }}
                name="description"
                value={events?.description}
                onChange={handleChangeValues}
              ></textarea>
              <div className="error-text">{errors?.description}</div>
            </div>
                
            <div class="form-group">
              <label htmlFor="">Event Registration Link</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Add Event Registration Link"
                name="registrationLink"
                value={events?.registrationLink}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.registrationLink}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Know More Link</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Add Know More Link"
                name="knowMoreLink"
                value={events?.knowMoreLink}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.knowMoreLink}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Location</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Add Location"
                name="location"
                value={events?.location}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.location}</div>

            </div>
          </div>
          <div className="col-lg-6">

            <div className="member_free_button_container">
              <p className="member_type">Event Status</p>
              <p className="free_premium_container">
                <span className="free_Font">Inactive</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={events?.isActive}
                        onChange={() => {
                          // changeActiveStatus(currentEvent?._id, !events?.isActive)
                          setEvents({
                            ...events,
                            isActive: !events?.isActive,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Active</span>
              </p>
            </div>

            {/* <div className="next_cancel_button_container" style={{justifyContent: "space-between"}}>
              <p className="member_type">Delete Event</p>
              <p className="free_premium_container" style={{marginRight: '95px'}}>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDeleteConfirmation(true);
                  }}
                >
                  Delete
                </button>
              </p>
            </div> */}

            <h6 className="setting_heading">Settings</h6>

            <div className="member_free_button_container">
              <p className="member_type">Member type</p>
              <p className="free_premium_container">
                <span className="free_Font">Free</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={events?.isPremium}
                        onChange={() => {
                          setEvents({
                            ...events,
                            isPremium: !events?.isPremium,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Premium</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">Certification</p>
              <p className="free_premium_container">
                <span className="free_Font">Non-Certified</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={events?.isCertified}
                        onChange={() => {
                          setEvents({
                            ...events,
                            isCertified: !events?.isCertified,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Certified</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">GCC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={events?.isGcc}
                        onChange={() => {
                          setEvents({
                            ...events,
                            isGcc: !events?.isGcc,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div className="form-group member_free_button_container">
              <p className="member_type">BEC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={events?.isBec}
                        onChange={() => {
                          setEvents({
                            ...events,
                            isBec: !events?.isBec,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>
            <div class="form-group">
              <div className="custom_time_date_container">
                <div className="time_addTime_container">
                  <div>
                    <label htmlFor="">Event Start Time and Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="custom_time_date"
                        // label="Responsive"
                        renderInput={(params) => <TextField {...params} />}
                        value={startDate}
                        onChange={(newValue) => {
                          const newDate = newValue.format('YYYY-MM-DDTHH:mm:ss[Z]');;
                          // console.log('newDate -> ', newDate.toString());
                          setStartDate(newValue);
                          setEvents({
                            ...events,
                            startDateOfEvent: newDate?.toString(),
                          });
                        }}
                      />
                      <div className="error-text">{errors?.startDateOfEvent}</div>
                    </LocalizationProvider>
                  </div>
                  <div>
                    <label htmlFor="">Event End Time and Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="custom_time_date"
                        // label="Responsive"
                        renderInput={(params) => <TextField {...params} />}
                        value={endDate}
                        onChange={(newValue) => {
                          const newDate = newValue.format('YYYY-MM-DDTHH:mm:ss[Z]');;
                          // console.log('newDate -> ', newDate.toString());
                          setEndDate(newValue);
                          setEvents({
                            ...events,
                            endDateOfEvent: newDate?.toString(),
                          });
                        }}
                      />
                      <div className="error-text">{errors?.endDateOfEvent}</div>
                    </LocalizationProvider>
                  </div>
                  {/* <span>Add more Date and time+</span> */}
                </div>
              </div>
            </div>

            <div className="list_of_speakers_container">
              <ul>
                {events?.speakers?.map((speaker, id) => {
                  return (
                    <li>
                      <img src={speaker?.profilePic} alt="" />{" "}
                      <p>{speaker?.name}</p>
                    </li>
                  );
                })}
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowSelectSpeaker(true);
                  }}
                >
                  <img src={svg4} alt="" /> <p>Add Speaker +</p>
                </li>
              </ul>
              <div className="error-text">{errors?.speakers}</div>
            </div>


            <div class="form-group">
              <label>Thumbnail Image Upload (1920 x 1080px)</label>
              {events?.imageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={events?.imageValues?.url}
                  />
                  <br></br>
                  <button
                    style={{
                      margin: "10px 0px"
                    }}
                    onClick={() => {
                      setEvents({
                        ...events,
                        imageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="AddPhoto1"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
                // <></>
              )}
              <div className="error-text">{errors?.imageValues}</div>
              <input
                type="file"
                class="form-control"
                id="AddPhoto1"
                placeholder="Name"
                style={{ display: "none" }}
                onChange={onImageChange}
              />
            </div>

            <div class="form-group">
              <label htmlFor="">Event Deep Link</label>
              <div className="custom_copy_container">
                <input
                  className="form-control"
                  type="text"
                  ref={inputRef}
                  disabled
                  value={`https://forallcommunity.com/event/${currentEvent?._id}`}
                />
                <button onClick={handleCopy}>
                  {isCopied ? (
                    <DoneIcon color="success" />
                  ) : (
                    <ContentCopyIcon />
                  )}
                </button>
              </div>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Tags</label>
              <ChipComponent />
            </div> */}
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={openPreviewHandler}
            >
              Next
            </button>
            <button
              onClick={() => {
                setOpenEditEvent(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditEvent;
