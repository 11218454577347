import React, { useEffect } from "react";
import { useState } from "react";
import svg1 from "../../../Asset/images/avatars/1.svg";
import Apilib from "../../../Api";
import { notify, notifyTypes } from "../../../Utils";

const EditAdminModal = (props) => {
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const API = new Apilib();

  React.useEffect(() => {
    _getDetails();
  }, []);

  const [errors, setErrors] = useState({});

  const [imageValues, setImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const getRoles = (roles) => {
    if (roles == "super") return 1;
    if (roles == "quality") return 2;
    if (roles == "content") return 3;
    return "";
  };

  const submitRoles = (roles) => {
    if (roles == 1) return "super";
    if (roles == 2) return "quality";
    if (roles == 3) return "content";
    return "";
  };

  const _getDetails = () => {
    const roles = props?.values?.role;
    const obj = {
      name: props?.values?.name,
      email: props?.values?.email,
      role: getRoles(roles),
      password: "",
      mobile: props?.values?.phone,
    };
    setValues({
      ...obj,
    });
  };

  const [openEditModal, setOpenEditModal] = useState();

  const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  };

  const onDoneHandler = (valuesX) => {
    const role = submitRoles(valuesX.role);
    //Payload is extracted from valuesX since sum params are yet to be fixed like mobile numbers
    const payload = {
      email: valuesX.email,
      // password: valuesX.password,
      name: valuesX.name,
      role: role,
      phone: valuesX.mobile,
    };

    API.editAdmin(props?.values?._id, payload)
      .then((res) => {
        props.setOpenEditModel(false);
        notify(notifyTypes?.success, "Admin Edited Successfully.");
        props.fetchAdmins();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, `${e.response.data.error}`);
      })
      .finally((e) => {});
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Name is required.";
    } else if (valuesX.name.length < 8) {
      isValidForm = false;
      errorsX.name = "Name length must be atleast 8 characters long";
    }

    if (valuesX?.mobile == "") {
      isValidForm = false;
      errorsX.mobile = "Phone Number is required.";
    }
    if (valuesX?.email == "") {
      isValidForm = false;
      errorsX.email = "Email is required.";
    }

    if (valuesX?.role == "") {
      isValidForm = false;
      errorsX.role = "Role is required.";
    }

    // if (valuesX?.password == "") {
    //   isValidForm = false;
    //   errorsX.password = "Password is required.";
    // } else if (!checkPassword(valuesX?.password)) {
    //   isValidForm = false;
    //   errorsX.password = 'Password should have minimum length of 8, one special symbol , upper and lowercase characters'
    // }

    setErrors({ ...errorsX });

    if (isValidForm && shouldeSubmitForm) {
      onDoneHandler(valuesX);
    }
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  return (
    <div className="All_speaker_form_container">
      <div className="model_head_container">
        <div className="underLine_section">
          <h6>Admins</h6>
        </div>
      </div>
      <h1 className="Dpecker_heading">Edit Admin</h1>

      <form className="add_specker_form">
        <div className="row">
          <div className="col-lg-6 custom_text_editor">
            <div class="form-group">
              <label htmlFor="">Role</label>
              <select
                id="role"
                class="form-select"
                aria-label="Default select example"
                value={values?.role}
                onChange={handleChangeValues}
                name="role"
              >
                <option disabled>-select role-</option>
                <option value="1">Super Admin</option>
                <option value="2">Quality Admin</option>
                <option value="3">Content Admin</option>
              </select>
              <div className="error-text">{errors?.role}</div>
            </div>
            {/* <div class="form-group">
                    <label htmlFor="">Post Type</label>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Interest Area"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Industry Type"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Banner Ads"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Add Speakers"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Speaker Videos"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Case Study"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Research Paper"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Blog"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Events"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Practice Area"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Best People Practices"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Users"
                      />
                      <FormControlLabel
                        control={<Checkbox className="custom_table_checkbox" />}
                        label="Admins"
                      />
                    </FormGroup>
                  </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Name</label>
              <input
                type="text"
                name="name"
                class="form-control"
                id=""
                placeholder="Name"
                value={values?.name}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.name}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Phone Number</label>
              <div className="mobile_no_main_container">
                <select
                  class="form-select phone_no_start_select"
                  aria-label="Default select example"
                >
                  <option selected>+91</option>
                  <option value="2">+92</option>
                  <option value="3">+93</option>
                </select>
                {/* <CountrySelect className="form-control" /> */}
                <input
                  type="text"
                  class="form-control"
                  id=""
                  name="mobile"
                  value={values?.mobile}
                  onChange={handleChangeValues}
                  placeholder="99 9999 9999"
                />
              </div>
              {/* Uncomment when integrating API */}
              <div className="error-text">{errors?.mobile}</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div class="form-group">
              <label>Email</label>
              <input
                disabled
                type="email"
                name="email"
                class="form-control"
                id=""
                placeholder="email"
                value={values?.email}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.email}</div>
            </div>
            {/* <div class="form-group">
              <label>Password</label>
              <input
                name="password"
                type="text"
                class="form-control"
                id=""
                placeholder="Password"
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.password}</div>
            </div> */}
          </div>
        </div>
        {/* <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        /> */}
        {/* Same as */}
        {/* <ToastContainer /> */}
        <div className="done_cancel_container">
          <button
            onClick={() => validateForm(values, true)}
            className="btn done_button"
            type="button"
          >
            Done
          </button>
          <button
            onClick={() => props.setOpenEditModel(false)}
            className="btn cancel_button"
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAdminModal;
