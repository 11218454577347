const routes = {
  // authenticated routes

  dashboard: {
    //@ params
    url: "dashboard",
    label: "Dashboard",
    icon: "Calendar.svg",
  },
  interest_area: {
    //@ params
    url: "InterestArea",
    label: "Interest Area",
    icon: "Calendar.svg",
  },

  industry_type: {
    //@ params
    url: "IndustryType",
    label: "Industry Type",
    icon: "Calendar.svg",
  },
  banner_ads: {
    //@ params
    url: "banner_ads",
    label: "Banner Ads",
    icon: "Calendar.svg",
  },
  add_speakers: {
    //@ params
    url: "add_speakers",
    label: "Add Speakers",
    icon: "Calendar.svg",
  },
  speaker_videos: {
    //@ params
    url: "speaker_videos",
    label: "Speaker Videos",
    icon: "Folder.svg",
  },
  case_study: {
    //@ params
    url: "case_study",
    label: "Case Study",
    icon: "Folder.svg",
  },
  research_paper: {
    //@ params
    url: "research_paper",
    label: "Research Paper",
    icon: "contacts.svg",
  },
  blog: {
    //@ params
    url: "Blog",
    label: "Blog",
    icon: "contacts.svg",
  },
  events: {
    //@ params
    url: "events",
    label: "Events",
    icon: "contacts.svg",
  },
  practice_area: {
    //@ params
    url: "practice_area",
    label: "Practice Area",
    icon: "contacts.svg",
  },
  best_people_practices: {
    //@ params
    url: "best_people_practices",
    label: "Best People Practices",
    icon: "contacts.svg",
  },
  users: {
    //@ params
    url: "users",
    label: "Users",
    icon: "contacts.svg",
  },
  admins: {
    //@ params
    url: "admins",
    label: "Admins",
    icon: "contacts.svg",
  },
  community_groups: {
    //@ params
    url: "community_groups",
    label: "Community Groups",
    icon: "contacts.svg",
  },
  polls: {
    //@ params
    url: "polls",
    label: "Polls",
    icon: "contacts.svg",
  },

  // AddNewBlogs: {
  //     //@ params
  //     url: "AddNewBlogs", label: "AddNewBlogs", icon: "contacts.svg"
  // },
  // DeleteConfirmation: {
  //     //@ params
  //     url: "DeleteConfirmation", label: "DeleteConfirmation", icon: "contacts.svg"
  // },
};
// inside array maping on routes objects
const dashboardListingSuper = [
  "dashboard",
  "interest_area",
  "industry_type",
  "banner_ads",
  "add_speakers",
  "speaker_videos",
  "case_study",
  "research_paper",
  "blog",
  "events",
  "practice_area",
  "best_people_practices",
  "users",
  "admins",
  "community_groups",
  // "polls",
  //   "AddNewBlogs",
  //   "DeleteConfirmation",
];

const dashboardListingQuality = [
  "dashboard",
  "users",
  "community_groups",
  // "polls",
];

const dashboardListingContent = [
  "dashboard",
  "interest_area",
  "industry_type",
  "banner_ads",
  "add_speakers",
  "speaker_videos",
  "case_study",
  "research_paper",
  "blog",
  "events",
  "practice_area",
  "best_people_practices",
  "community_groups",
];

export {
  routes,
  dashboardListingContent,
  dashboardListingSuper,
  dashboardListingQuality,
};
