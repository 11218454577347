import React, { useRef, useState } from "react";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import "../casestudy.scss";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";

import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import PracticeAreaDropDown from "../../../UICommon/DropDowns/PracticeAreaDropDown";
import { useEffect } from "react";
import Apilib from "../../../Api";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";


const TextEditorHeight = "100vh";
const AddBestPeoplePractices = (
  props,
  { setOpenPreviewCaseStudy, setOpenCaseStudy }
) => {
  const API = new Apilib();
  const [values, setValues] = useState({
    title: "",
    practiseName: "",
    slogan: "",
    description: "",
    interests: [],
    organizationType: "",
    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    // audioValues:{},
    industries: [],
    practiseArea: [],
    // contentLevel: ["free", "non-certified"],
    contentLevel: [],
    isPremium: false,
    isCertified: false,
    isActive: false,
    isGcc: false,
    isBec: false,
  });
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const [errors, setErrors] = useState({
    title: "",
    video: "",
    description: "",
    interests: "",
    imageValues: "",
    // audioValues:{},
    speakers: "",
  });

  useEffect(() => {
    getBestPeoplePratice();
  }, []);

  const getBestPeoplePratice = () => {
    API.getBestPeoplePratice(props.bppID)
      .then((res) => {
        var data = res?.data?.data;
        setValues({
          ...values,
          title: data?.title,
          slogan: data?.slogan,
          description: data?.content,
          practiseName: data?.practiseName,
          interests: data?.interests,
          imageValues: data?.logo
            ? {
              loaded: true,
              url: data?.logo,
            }
            : {
              loaded: false,
              url: "",
              type: "",
              name: "",
              source: "",
            },
          industries: [...data?.industries],
          practiseArea: [data?.practiseArea],
          // contentLevel: ["free", "non-certified"],
          isPremium: data?.contentLevel.includes('premium'),
          isCertified: data?.contentLevel.includes('certified'),
          isActive: data?.isActive,
          isGcc: data?.contentLevel.includes('GCC'),
          isBec: data?.contentLevel.includes('BEC'),
          _id: data?._id,
          organizationType: data?.organizationType,
          scheduledTime: data?.scheduledTime,
          isPublished: data?.isPublished
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  console.log('Valuesssssss:', values)

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };
  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    console.log(valuesX, "valuesX");
    if (valuesX?.imageValues?.loaded == false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.title == "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.practiseName == "") {
      isValidForm = false;
      errorsX.practiseName = "Practise Name is required.";
    }
    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }
    if (valuesX?.practiseArea?.length == 0) {
      isValidForm = false;
      errorsX.practiseArea = "Please select atleast one practise area.";
    }
    if (valuesX?.scheduledTime
      &&
      new Date(valuesX?.scheduledTime) < new Date()
      &&
      !valuesX?.isPublished
      &&
      valuesX?.isPublished != undefined) {
      isValidForm = false;
      errorsX.scheduledTime = "Please select valid date and time.";
      setPublishOption((publishOption) => true);
    }

    if (valuesX?.industries?.length == 0) {
      isValidForm = false;
      errorsX.industries = "Please select atleast one industry.";
    }

    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {

      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium")) valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free")) valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified")) valuesX.contentLevel.push("certified");
      if (!valuesX.isCertified && !valuesX.contentLevel.includes("non-certified")) valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC")) valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC")) valuesX.contentLevel.push("BEC");

      console.log("data from preview", valuesX);

      props?.setShowPreview(valuesX);
    }
  };
  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const openPreviewHandler = (event) => {
    event.preventDefault();
    setOpenPreviewCaseStudy(true);
  };
  const [publishOption, setPublishOption] = useState(false);
  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
    console.log("yooo");
  };


  const inputRef = React.useRef(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };


  console.log(values?.isPublished, "values?.isPublished")
  return (
    <div className="add_case_study_main">
      <div
        style={{ position: "relative" }}
        className="heading_publish_container"
      >
        <h1>Edit Best People Practices</h1>
        {!values?.isPublished && values?.isPublished != undefined ? <button className="custom_publish_button" onClick={publishOptionHandler}>
          Publish <img src={BlueDropdownIcon} alt="BlueDropdownIcon" />
        </button> : null}

        {publishOption ? (
          <div className="publish_option_container">
            <CommonDatePicker values={values} setValues={setValues} validateForm={validateForm} errors={errors} />

          </div>
        ) : null}
      </div>
      <div className="form_add_case_study" action="">
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Company Name</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Title"
                name="title"
                value={values?.title}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Slogan</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="slogan"
                name="slogan"
                value={values?.slogan}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.slogan}</div>
            </div> */}
            {/* <div class="form-group">
              <label htmlFor="">Practice Name</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="practise Name"
                name="practiseName"
                value={values?.practiseName}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.practiseName}</div>
            </div> */}

            <div class="form-group">
              <label htmlFor="">Content</label>
              <textarea
                class="form-control"
                style={{
                  height: 500,
                  width: "100%",
                }}
                name="description"
                value={values?.description}
                onChange={handleChangeValues}
              ></textarea>
              <div className="error-text">{errors?.description}</div>

              {/* <Editor
                apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Type here...</p>"
                init={{
                  height: TextEditorHeight,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                    "emoticons",
                  ],
                  toolbar:
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | link image media mergetags emoticons ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              /> */}
            </div>
          </div>
          <div className="col-lg-6" style={{}}>
            <h6 className="setting_heading">Settings</h6>

            <div className="member_free_button_container">
              <p className="member_type">Member type</p>
              <p className="free_premium_container">
                <span className="free_Font">Free</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isPremium}
                        onChange={() => {
                          setValues({
                            ...values,
                            isPremium: !values?.isPremium,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Premium</span>
              </p>
            </div>
            <div className="member_free_button_container">
              <p className="member_type">Certification</p>
              <p className="free_premium_container">
                <span className="free_Font">Non-Certified</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isCertified}
                        onChange={() => {
                          setValues({
                            ...values,
                            isCertified: !values?.isCertified,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Certified</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">GCC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isGcc}
                        onChange={() => {
                          setValues({
                            ...values,
                            isGcc: !values?.isGcc,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div className="form-group member_free_button_container">
              <p className="member_type">BEC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isBec}
                        onChange={() => {
                          setValues({
                            ...values,
                            isBec: !values?.isBec,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div class="form-group">
              <label>Logo Upload</label>
              {values?.imageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={values?.imageValues?.url}
                  />
                  <br></br>
                  <button
                    onClick={() => {
                      setValues({
                        ...values,
                        imageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                      validateForm(
                        {
                          ...values,
                          imageValues: {
                            loaded: false,
                            url: "",
                          },
                        },
                        false
                      );
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="AddPhoto"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
              )}
              <div className="error-text">{errors?.imageValues}</div>
              <input
                type="file"
                class="form-control"
                id="AddPhoto"
                placeholder="Name"
                style={{ display: "none" }}
                onChange={onImageChange}
              />
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Tags</label>
              <ChipComponent />
            </div> */}
            <div class="form-group">
              <div class="form-group">
                <label htmlFor="">Practice Area</label>
                <PracticeAreaDropDown
                  selectedData={values?.practiseArea}
                  onSelectChange={(data) => {
                    setValues((prev) => ({
                      ...prev,
                      practiseArea: [...data],
                    }));
                    validateForm({ ...values, practiseArea: [...data] }, false);
                  }}
                />
                <div className="error-text">{errors?.practiseArea}</div>
              </div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Interest area*</label>
              <InterestAreaDropDown
                selectedData={values?.interests}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    interests: [...data],
                  });
                  validateForm({ ...values, interests: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.interests}</div>
            </div> */}
            <div class="form-group">
              <label htmlFor="">Industry type</label>
              <IndustryTypeDropDown
                selectedData={values?.industries}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    industries: [...data],
                  });
                  validateForm({ ...values, industries: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.industries}</div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Organization Type</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}
          </div>
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Practice Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Practice Name"
                name="practiseName"
                value={values?.practiseName}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Best People Practices Deep Link</label>
              <div className="custom_copy_container">
                <input
                  className="form-control"
                  type="text"
                  ref={inputRef}
                  disabled
                  value={`https://forallcommunity.com/best-people-practices/${values?._id}`}
                />
                <button onClick={handleCopy}>
                  {isCopied ? (
                    <DoneIcon color="success" />
                  ) : (
                    <ContentCopyIcon />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Organization Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Organization Name"
                name="organizationType"
                value={values?.organizationType}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Next
            </button>
            <button onClick={() => props?.setOpenAddVideo(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddBestPeoplePractices;
