import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";

import ModalContainer from "../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";
import AddIndustryTypeModal from "../../UICommon/Modal/Industry-Type-modal/AddIndustryTypeModal";
import EditIndustryTypeModal from "../../UICommon/Modal/Industry-Type-modal/EditIndustryTypeModal";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import CertifiedNonCertified from "../../UICommon/Certified-NonCertified/CertifiedNonCertified";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";
import "../CaseStudy/casestudy.scss";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
// import AddCaseStudy from "./Modals/AddResearchPaper";
// import PreviewCaseStudy from "./Modals/PreviewResearchPaper";
// import AddResearchPaper from "./Modals/AddResearchPaper";
// import PreviewResearchPaper from "./Modals/PreviewResearchPaper";

import "../ResearchPaper/researchPaper.scss";
import "./Drawer/cropFunction/cropFunction.scss";
import AddBannerPromotion from "./Drawer/AddBannerPromotion";
import PreviewResearchPaper from "../ResearchPaper/Modals/PreviewResearchPaper";
import PreviewAdsBanner from "./Drawer/PreviewAdsBanner";
import CropDemo from "./Drawer/cropFunction/CropDemo";

import Apilib from "../../Api";
import { getDateString, notify, notifyTypes } from "../../Utils";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";
import EditBannerPromotion from "./Drawer/EditBannerPromotion";

function createData(
  id,
  title,
  eyebrowText,
  linkRoute,
  OrgType,
  createdOn,
  status
) {
  return {
    id,
    title,
    eyebrowText,
    linkRoute,
    OrgType,
    createdOn,
    status,
  };
}

const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Disabled </button>
);
// const rows = [
//   createData(
//     1,
//     "Ankita P",
//     "Individual",
//     "https://bit.ly/3dHRvH",
//     "All",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     2,
//     "PDPU",
//     "Organisation",
//     "https://bit.ly/3dHvH",
//     "All",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     3,
//     "Sammy",
//     "Individual",
//     "https://bit.ly/31dHhRvH",
//     "All",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     4,
//     "Joyse",
//     "Individual",
//     "root/speaker/abc",
//     "Certified",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     5,
//     "Vishal",
//     "Individual",
//     "root/plans",
//     "Certified",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     6,
//     "NEFT",
//     "Organisation",
//     "https://bit.ly/dHhRvH",
//     "NonCertified",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     7,
//     "IMMS",
//     "Organisation",
//     "https://bit.ly/3dHhRv",
//     "NonCertified",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     8,
//     "Oriental College ",
//     "Organisation",
//     "https://bit.ly/3dvH",
//     "Certified",
//     "12.09.20",
//     activeButton
//   ),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
    enableSorting: true,
  },
  {
    id: "eyeBrowText",
    numeric: false,
    disablePadding: false,
    label: "Eyebrow text",
    enableSorting: true,
  },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Link/Route ",
    enableSorting: false,
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image ",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created on ",
    enableSorting: true,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "status",
    enableSorting: true,
  },
  {
    id: "protein",
    numeric: false,
    disablePadding: false,
    label: " ",
    enableSorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >

                {orderBy === headCell.id ? (
                  <Box className="SortArrow" component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AdsBanner = () => {
  const API = new Apilib();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openBannerPreview, setOpenBannerPreview] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [openEditModel, setOpenEditModel] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [previewValues, setPreviewValues] = useState({});
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  const needLength = Math.ceil(rows.length / rowsPerPage);
  const [showMoreAction, setShowMoreAction] = useState(false);


  ///////////////////////////////////////////////////////////
  const [state, setState] = React.useState({
    right: false,
  });
  ////////////////////////////////////////////////////////////
  const [state2, setState2] = React.useState({
    right: false,
  });

  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  useEffect(() => {
    console.log('STATE2: ', state2)
  }, [state2])
  //////////////////////////////////////////////////////////////
  const toggleDrawer = (anchor, open) => (event) => {
    console.log("Inside toggleDrawer, anchor : ", anchor);
    console.log("open : ", open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  ///////////////////////////////////////////////////////////////////
  const toggleDrawer2 = (anchor, open) => (event) => {
    console.log("Inside toggleDrawer2, open : ", open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState2({ ...state2, [anchor]: open });
  };
  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    fetchBanners();
    setOrder("desc");
    setOrderBy('createdAt');
  }, []);

  const fetchBanners = (text) => {
    API.getBanners(text)
      .then((res) => {
        console.log("get banners ", res?.data);
        setRows(res?.data?.banners);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const deleteBanner = (id) => {
    API.deleteBanner(id)
      .then((res) => {
        console.log("delete Banner ", res?.data);
        notify(notifyTypes?.success, "Deleted Interest Successfully.");
        fetchBanners();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Banner.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editBanner(id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchBanners(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };
  const addBanner = () => {
    var data = {
      title: previewValues?.title,
      eyeBrowText: previewValues?.eyeBrowText,
      backgroundColor: previewValues?.backgroundColor,
      link: previewValues?.link,
      interests: previewValues?.interests,
      industries: previewValues?.industries,
      description: previewValues?.details,
      contentLevel: previewValues?.contentLevel,
      isPremium: previewValues?.isPremium,
      isCertified: previewValues?.isCertified,
    };
    console.log('data before adding banner : ', data);
    API.addBanner(data)
      .then((res) => {
        // console.log("add speaker video response", res?.data?.speakerVideo?._id);
        notify(notifyTypes?.success, "Banner Added Successfully");
        uploadMedia(res?.data?.banner?._id);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Banner");
      })
      .finally((e) => {
        fetchBanners();
      });

    const uploadMedia = (id) => {
      var mediaData = new FormData();
      mediaData.append("image", previewValues?.image?.source);
      API.uploadBannerImage(mediaData, id)
        .then((res) => {
          console.log("add speaker video response", res?.data);
          // notify(notifyTypes?.success, "Industry Added Successfully");
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        })
        .finally((e) => {
          fetchBanners();
        });
    };
  };
  const editBanner = () => {
    console.log("selectedRow id -------------", selectedRow?._id);
    var data = {
      title: previewValues?.title,
      eyeBrowText: previewValues?.eyeBrowText,
      backgroundColor: previewValues?.backgroundColor,
      link: previewValues?.link,
      interests: previewValues?.interests,
      description: previewValues?.details,
      contentLevel: previewValues?.contentLevel,
    };
    console.log('data before adding banner : ', data);
    API.editBanner(selectedRow?._id, data)
      .then((res) => {
        // console.log("add speaker video response", res?.data?.speakerVideo?._id);
        notify(notifyTypes?.success, "Banner Edited Successfully");
        if (previewValues?.image?.source) {
          uploadMedia(res?.data?.banner?._id);
        }
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Banner");
      })
      .finally((e) => {
        fetchBanners();
      });
    const uploadMedia = (id) => {
      var mediaData = new FormData();
      mediaData.append("image", previewValues?.image?.source);
      API.uploadBannerImage(mediaData, id)
        .then((res) => {
          console.log("add speaker video response", res?.data);
          // notify(notifyTypes?.success, "Industry Added Successfully");
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        })
        .finally((e) => {
          fetchBanners();
        });
    };
  };
  return (
    <>
      <div className="Page_heading_container Page_heading_SpeakerVideo">
        <h1>Banner Ads</h1>

        <button
          onClick={toggleDrawer("right", true)}
          className="Add_Head_Button"
        >
          <img src={AddIcon} alt="" />
        </button>

        {/* <button
          onClick={toggleDrawer2("right", true)}
          className="Add_Head_Button"
        >
          bossss
        </button> */}
      </div>
      {/* <CertifiedNonCertified /> */}

      {openBannerPreview ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewAdsBanner
            values={previewValues}
            submit={() => {
              setOpenBannerPreview(false);
              previewValues.type == "add" ? addBanner() : editBanner();
            }}
            {...{ setOpenBannerPreview, setOpenEditDrawer,  toggleDrawer, setState}}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      <AddBannerPromotion
        setOpenBannerPreview={(values) => {
          // console.log("values", values);
          setPreviewValues({ ...values, type: "add" });
          setOpenBannerPreview(true);
          toggleDrawer("right", false);
          setState({ ...state, right: false });
        }}
        toggleDrawer={toggleDrawer}
        {...{ toggleDrawer, state, setState }}
      />

      <EditBannerPromotion
        setOpenBannerPreview={(values) => {
          // console.log("values", values);
          setPreviewValues({ ...values, type: "edit" });
          setOpenBannerPreview(true);
          // toggleDrawer2("right", false);
          // setState2({ right: false });
          setOpenEditDrawer(false);
          // setOpenEditModel(false);
        }}
        openEditDrawer={openEditDrawer}
        setOpenEditDrawer={setOpenEditDrawer}
        {...{ toggleDrawer2, state2, setState2, setOpenEditModel, selectedRow }}
      />



      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {isModalOpen ? (
            <ModalContainer {...{ isModalOpen, setIsModalOpen }}>
              <AddIndustryTypeModal />
              {/* <EditInterestAreaModal /> */}
            </ModalContainer>
          ) : null}
          {openDeleteConfirmation ? (
            <DeleteConfirmation
              onDelete={() => {
                setOpenDeleteConfirmation(false);
                deleteBanner(showMoreActionForID);
              }}
              {...{ setOpenDeleteConfirmation }}
            />
          ) : null}

          <SearchBarNActionButtonContainer
            onTextChange={(text) => {
              console.log(text);
              setPage(1);
              setSearchValue(text);
              fetchBanners(text);
            }}
            {...{ setOpenDeleteConfirmation }}
            className="codered"
          />

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRowComponent
                        key={row?._id}
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setSelectedRow,
                          toggleDrawer2,
                          state,
                          setState2,
                          state2,
                          setDeleteID,
                        }}
                        setOpenEditDrawer={setOpenEditDrawer}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
        {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  setSelectedRow,
  changeActiveStatus,
  toggleDrawer2,
  state,
  setState2,
  state2,
  setDeleteID,
  setOpenEditDrawer
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        // setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row?._id);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        onClick={() => {
          setShowMoreActionForID(null);
          // setState2({ ...state2, ["right"]: true })
          setOpenEditDrawer(true);
          // setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        {row.title}
      </TableCell>
      <TableCell align="left">{row.eyeBrowText}</TableCell>
      <TableCell align="left">
        <div
          style={{
            width: 190,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {row?.link}
        </div>
      </TableCell>
      <TableCell align="left">
        {row?.image ? (
          <img
            style={{
              height: 100,
              width: 140,
            }}
            src={row?.image}
            alt=""
          />
        ) : (
          "NA"
        )}
      </TableCell>
      <TableCell className="custom_column_grey_color" align="left">
        {getDateString(row?.createdAt)}
      </TableCell>
      <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell>

      <TableCell id={labelId} align="left">
        <button className="more_option_manu" onClick={openMoreMenuHandler}>
          <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
        </button>
        {/* {console.log(
          showMoreActionForID,
          row?._id,
          showMoreActionForID == row?._id
        )} */}
        <ThreeDotOption

          showEditSliderHandler={toggleDrawer2("right", true)}
          show={showMoreActionForID === row._id}
          isActive={row?.isActive}
          changeActiveStatus={(status) => {
            console.log("status", status);
            changeActiveStatus(status, row?._id);
          }}
          setOpenEditModel={() => {
            setShowMoreActionForID(null);
            // setState2({ ...state2, ["right"]: true })
            // setOpenEditModel(true);
            setOpenEditDrawer(true);
            setSelectedRow(row);
          }}
          setDeleteID={() => {
            setDeleteID(row?._id);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      </TableCell>
    </TableRow>
  );
};
export default AdsBanner;
