import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

import Apilib from "../../Api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ChipComponent = (props) => {
  const API = new Apilib();
  const theme = useTheme();

  const [allIndustries, setAllIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = React.useState([]);
  const [selectedIndustry, setSelectedIndustry] = React.useState([]);
  const [MainData, setMainData] = useState([]);
  const names = MainData;
  const disabled = props?.disabled;

  const allSelected = selectedIndustry.length === names.length;

  const fetchIndustries = () => {
    API.getIndustriesActiveOnly()
      .then((res) => {
        // console.log("get industry s", res?.data?.industrys);
        setAllIndustries(res?.data?.industries);
        setMainData(res?.data?.industries);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes(undefined)) {
      return;
    }
    console.log("value ^^^^^^", value);
    if (value == "" || value == undefined) {
      setSelectedIndustry([]);
      return;
    } else {
      if (typeof value === "string" && value !== null) {
        setSelectedIndustry(value.split(","));
        props?.onSelectChange(value.split(","));
      } else if (value.includes("all")) {
        setSelectedIndustry(
          selectedIndustry.length === names.length ? [] : names
        );
        props?.onSelectChange(
          selectedIndustry.length === names.length ? [] : names
        );
      }
      // NOTE: THIS ELSE BLOCK IS FOR WHEN USER DE-SELECTS THE INTEREST
      // THE ELEMENT WHICH USER SELECTS, IS ALWAYS THE LAST ELEMENT IN THE ARRAY
      // SO WE CAN CHECK IF THE LAST ELEMENT IS CHECKED OR NOT AND  THEN WE FILTER IT OUT
      else {
        let newValue = value;
        if (value.length < setSelectedIndustry.length) {
          setSelectedIndustry(value);
        } else {
          if (checkIndustryChecked(newValue[newValue.length - 1])) {
            newValue = newValue.filter(
              (_existing) =>
                _existing?._id != newValue[newValue.length - 1]?._id
            );
          }
          setSelectedIndustry(newValue);
          props?.onSelectChange(newValue);
        }
      }

      // setSelectedIndustry(
      //   typeof value === "string" && value !== null
      //     ? value.split(",")
      //     : value.includes("all")
      //     ? selectedIndustry.length === names.length
      //       ? []
      //       : names
      //     : value
      // );
      // props?.onSelectChange(
      //   typeof value === "string" && value !== null
      //     ? value.split(",")
      //     : value.includes("all")
      //     ? selectedIndustry.length === names.length
      //       ? []
      //       : names
      //     : value
      // );
    }
  };

  const handleDelete = (IndustryId) => {
    const updatedSelectedInterest = selectedIndustries.filter(
      (industry) => industry._id !== IndustryId
    );
    setSelectedIndustries(updatedSelectedInterest);
    props?.onSelectChange(updatedSelectedInterest);

    console.log("delete", updatedSelectedInterest);
  };

  const handleSeeMore = (e) => {
    e.preventDefault();
    const nextIndex = allIndustries.length;
    const nextNames = names.slice(nextIndex, nextIndex + 5);
    setAllIndustries((prevVisibleNames) => [...prevVisibleNames, ...nextNames]);
  };

  useEffect(() => {
    fetchIndustries();
  }, []);

  useEffect(() => {
    setSelectedIndustries(props?.selectedData);
  }, [props]);

  useEffect(() => {
    setAllIndustries(names.slice(0, 5));
  }, [MainData]);

  const checkIndustryChecked = (industry) => {
    for (let i = 0; i < selectedIndustries.length; i++) {
      const selectedInd = selectedIndustries[i];
      if (selectedInd && industry) {
        if (selectedInd._id == industry._id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div>
      <FormControl className="For_chip_section" sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label"></InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedIndustries}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((industry) => {
                if (industry) {
                  return (
                    <Chip
                      disabled={disabled}
                      key={industry?._id}
                      label={industry?.name}
                      onDelete={() => {
                        handleDelete(industry._id);
                      }}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  );
                }
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem
            key="all"
            value="all"
            onClick={() => setSelectedIndustry(allSelected ? [] : names)}
            // disabled={allSelected}
          >
            <Checkbox checked={allSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {allIndustries?.map((industry) => (
            <MenuItem
              key={industry?._id}
              value={industry}
              style={getStyles(industry?.name, selectedIndustries, theme)}
            >
              <Checkbox checked={checkIndustryChecked(industry)} />
              <ListItemText primary={industry?.name} />
            </MenuItem>
          ))}
          {allIndustries.length < names.length && (
            <MenuItem onClick={handleSeeMore}>See more</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
export default ChipComponent;
