import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import "../Users/users.scss";
import svg1 from "../../Asset/images/avatars/1.svg";
import "../AddSpeakers/AddSpeakers.scss";

import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";

import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import Apilib from "../../Api";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import { getDateString, notifyTypes, notify } from "../../Utils";
import DeleteConfirmation from "../InterestArea/DeleteConfirmation/DeleteConfirmation";
import PreviewSpeakerVideoModel from "../AddSpeakerVideo/Modals/PreviewSpeakerVideo-model";
import EditCommunityGroupModal from "../CommunityGroups/Modals/EditCommunityGroup-modal";
import PollContainerModal from "./Modals/PollContainerModal";
import ResultQuestions from "./ResultQuestions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "username",
    numeric: true,
    disablePadding: false,
    label: "NAME",
    enableSorting: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "PHONE",
    enableSorting: false,
  },
  {
    id: "voteDate",
    numeric: false,
    disablePadding: false,
    label: "VOTED ON (DATE)",
    enableSorting: false,
  },
  {
    id: "voteTime",
    numeric: true,
    disablePadding: false,
    label: "VOTED ON (TIME)",
    enableSorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
                        className="custom_table_checkbox"
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <Box
                    className="SortArrow"
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const PollDetailModal = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  // const [previewValues, setPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [editID, setEditID] = useState(null);
  const [openDetailPage, setOpenDetailPage] = useState(false);
  const [previousImage, setPreviousImage] = useState(false);
  const [votersData, setVotersData] = useState([]);

  const poll_id = props.id;
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
  });

  const [imageValues, setImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const [currentTab, setCurrentTab] = useState(0);

  const [errors, setErrors] = useState({
    name: "",
    title: "",
    organization: "",
    description: "",
  });

  const [editImageValues, setEditImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const API = new Apilib();

  useEffect(() => {
    fetchAllPolls();
    setOrder("desc");
    setOrderBy("createdAt");
  }, []);

  const fetchAllPolls = (id) => {
    API.getSpecificPolls(poll_id)
      .then((res) => {
        console.log(res?.data?.allVoters);
        setRows(res?.data?.allVoters);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSpecificVoters = () => {
    API.getSpecificVoters(poll_id, currentTab)
      .then((res) => {
        console.log(res?.data?.voters);
        const voters = res?.data?.voters;
        setVotersData(voters);
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    fetchSpecificVoters();
    setOrder("desc");
    setOrderBy("createdAt");
  }, [rows, currentTab]);

  useEffect(() => {
    setCurrentTab(rows[0]?._id);
  }, [rows]);

  const handleRequestSort = (event, property) => {
    debugger;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows?.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);

  const [openAddCommunity, setOpenAddCommunity] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const openAddUserModelHandler = () => {
    setOpenAddCommunity(true);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  };

  const onDoneHandler = (e) => {
    const payload = {
      email: values.email,
      password: values.password,
      name: values.name,
      role: values.role,
    };
    console.log(payload);
    console.log("values before submit : ", payload);
    API.addAdmin(payload)
      .then((res) => {
        setOpenAddCommunity(false);
        notify(notifyTypes?.success, "User Added Successfully.");
        // fetchAdmins();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, `${e.response.data.error}`);
      })
      .finally((e) => {});
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    console.log(valuesX);
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Name is required.";
    } else if (valuesX.name.length < 8) {
      isValidForm = false;
      errorsX.name = "Name length must be atleast 8 characters long";
    }

    if (valuesX?.mobile == "") {
      isValidForm = false;
      errorsX.mobile = "Phone Number is required.";
    }
    if (valuesX?.email == "") {
      isValidForm = false;
      errorsX.email = "Email is required.";
    }

    if (valuesX?.role == "") {
      isValidForm = false;
      errorsX.role = "Role is required.";
    }

    if (valuesX?.password == "") {
      isValidForm = false;
      errorsX.password = "Password is required.";
    } else if (!checkPassword(valuesX?.password)) {
      isValidForm = false;
      errorsX.password =
        "Password should have minimum length of 8, one special symbol , upper and lowercase characters";
    }

    setErrors({ ...errorsX });

    if (isValidForm && shouldeSubmitForm) {
      onDoneHandler(valuesX);
    }
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const onImageChange = (e) => {
    console.log(e.target);
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      let name = e?.target?.files[0].name;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setImageValues({
          loaded: true,
          url: URL.createObjectURL(img),
          type: type,
          name: name,
          source: e?.target?.files[0],
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onCancelModal = () => {
    setValues({
      name: "",
      mobile: "",
      email: "",
      password: "",
      role: "",
    });
    setOpenAddCommunity(false);
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editAdmin(id, data)
      .then((res) => {
        notify(notifyTypes?.success, "Updated the status successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        // fetchAdmins(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  // const deleteUser = (id) => {
  //   console.log(
  //     "Inside delete community --------------------------------------",
  //     id
  //   );

  //   API.deleteCommunity(id)
  //     .then((res) => {
  //       console.log("delete User", res?.data);
  //       notify(notifyTypes?.success, "Community Deleted Successfully.");
  //       fetchAllCommunity();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       notify(notifyTypes?.error, "Failed to delete Community.");
  //     });
  // };

  const uploadMedia = (id, previewValues, alreadyNotified = false) => {
    if (!alreadyNotified)
      notify(notifyTypes?.success, "Community Updated Successfully");
    if (
      previewValues?.imageValues?.loaded &&
      previewValues?.imageValues?.source
    ) {
      var mediaData = new FormData();
      mediaData.append("image", previewValues?.imageValues?.source);
      console.log(mediaData, id, "mediaData, id");
      API.addCommunityImage(mediaData, id)
        .then((res) => {
          console.log("Community Image response", res?.data?.data?.path);
          const pathToImage = res?.data?.data?.path;
          var data = {
            ...previewValues,
            profilePic: pathToImage,
          };
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        });
    }
    // (previousImage && !previewValues?.imageValues?.loaded) - Earlier delete logic
    if (!previewValues?.imageValues?.loaded) {
      var data = {
        ...previewValues,
        profilePic:
          "https://gptw-test.s3.ap-south-1.amazonaws.com/communities/640079d51c067607b11be0c9/imagedk/fihI4pF6xpzsL", // dummmy image for now as gptw logo, if profile pic is empty.
      };
    }
  };

  // const createCommunity = (previewValues) => {
  //   console.log("This is community");
  //   console.log(previewValues);
  //   var id = "";
  //   var data = {
  //     ...previewValues,
  //   };
  //   delete data.imageValues;

  //   API.addCommunity(data)
  //     .then((res) => {
  //       console.log("response", res?.data);
  //       notify(notifyTypes?.success, "Community  Added Successfully");
  //       id = res?.data?.data?.community._id;
  //       uploadMedia(id, previewValues, true);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       notify(notifyTypes?.error, "Failed to Add Community");
  //     })
  //     .finally((e) => {
  //       // props.onAdded();
  //       // setLoading(false);
  //       fetchAllCommunity();
  //       // setPreviewValues({});
  //       setShowPreview(false);
  //       setOpenAddCommunity(false);
  //     });
  // };

  return (
    <>
      <ResultQuestions id={props.id} />
      <div
        style={{
          borderBottom: "1px solid #ced4da",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {rows?.map((_data) => (
          <div
            key={_data?._id}
            style={{
              borderBottom:
                currentTab == _data?._id ? "2px solid #002566" : null,
              flexDirection: "row",
            }}
          >
            <p
              onClick={() => setCurrentTab(_data?._id)}
              style={{
                cursor: "pointer",
                padding: 10,
                marginRight: 8,
                color: currentTab == _data?._id ? "#002566" : "gray",
              }}
            >
              {_data?.optionName}
            </p>
          </div>
        ))}
      </div>
      {/* {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deleteUser(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null} */}

      {/* {openAddCommunity ? (
        <AddSpeakerVideoModalcontainer
          {...{ openAddCommunity, setOpenAddCommunity }}
        >
          <AddCommunityGroupModal
            createCommunity={createCommunity}
            setShowPreview={(values) => {
              // setPreviewValues(values);
              setShowPreview(true);
            }}
            {...{
              showPreview,
              setOpenAddCommunity,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null} */}

      {showPreview ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewSpeakerVideoModel
            // data={previewValues}
            edit={editID ? true : false}
            // onPublish={() => {
            //     if (editID) {
            //         editSpeakerVideo(editID);
            //     } else {
            //         createSpeakerVideo();
            //     }
            // }}
            {...{ showPreview, setShowPreview }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
      <TableContainer>
        <Table
          className="custom_table_css"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={votersData?.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
            {stableSort(votersData, getComparator(order, orderBy))
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                // console.log(labelId,"labelId");

                return (
                  <TableRowComponent
                    key={Math.random(2)}
                    currentTab={currentTab}
                    changeActiveStatus={(status, id) => {
                      changeActiveStatus(status, id);
                    }}
                    {...{
                      row,
                      index,
                      selected,
                      handleSelection,
                      showMoreActionForID,
                      setShowMoreActionForID,
                      setOpenDetailPage,
                      setOpenDeleteConfirmation,
                      setSelectedRow,
                      setDeleteID,
                    }}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="table_pageination">
        <TablePagination
          className="rowNo_pageCounting"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={votersData?.length}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Pagination
          className="Table_Pagination_number"
          shape="rounded"
          count={needLength}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        {/* <button
          onClick={() => props.setOpenDetailPage(false)}
          className="btn cancel_button"
          type="button"
        >
          Close
        </button> */}
        <form className="add_specker_form">
          <div className="done_cancel_container">
            <button
              onClick={() => props.setOpenDetailPage(false)}
              className="btn cancel_button"
              type="button"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const TableRowComponent = ({
  row,
  index,
  currentTab,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenDetailPage,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
  setOpenDeleteConfirmation,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        return setShowMoreActionForID(null);
      }
    };

    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row._id);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
                    className="custom_table_checkbox"
                    onClick={(event) => handleSelection(event, row.name)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                /> */}
      </TableCell>
      <TableCell
        className="profileImg_name_container"
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          // setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <div className="users_profileImg_name_email_container">
          <img
            style={{
              height: 40,
              width: 40,
            }}
            src={svg1}
            alt=""
          />
          <div className="users_name_email_container">
            <div>{row?.username}</div>
            <div>{row?.email}</div>
          </div>
        </div>
      </TableCell>

      <TableCell
        align="left"
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenDetailPage(true);
          setSelectedRow(row);
        }}
      >
        {`${row?.mobile?.countryCode + " " + row?.mobile?.number}`}
      </TableCell>
      <TableCell
        className="custom_column_grey_color"
        align="left"
        style={{ cursor: "alias" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenDetailPage(true);
          setSelectedRow(row);
        }}
      >
        {moment(row?.voteDate).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell className="custom_column_grey_color" align="left">
        {moment(row?.voteTime, "hh:mm:ss A").format("HH:mm")}
      </TableCell>
    </TableRow>
  );
};
export default PollDetailModal;
