import { toast } from "react-toastify";

export const getDateString = (date) => {
  date = new Date(date);
  let month = "01";
  let dd = "01";
  let hr = "01";
  let mins = "01";
  if (date.getMonth() + 1 <= 9) {
    month = `0${date.getMonth() + 1}`;
  } else {
    month = date.getMonth() + 1;
  }

  if (date.getDate() <= 9) {
    dd = `0${date.getDate()}`;
  } else {
    dd = date.getDate();
  }

  if (date.getHours() <= 9) {
    hr = `0${date.getHours()}`;
  } else {
    hr = date.getHours();
  }

  if (date.getMinutes() <= 9) {
    mins = `0${date.getMinutes()}`;
  } else {
    mins = date.getMinutes();
  }

  return `${dd}-${month}-${date.getFullYear()} ${hr}:${mins}`;
};

export const getDateString_IST = (date) => {
  date = new Date(date);
  const istOffset = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
  const istTime = date.getTime() - istOffset * 60 * 1000;
  date = new Date(istTime);


  let month = "01";
  let dd = "01";
  let hr = "01";
  let mins = "01";
  if (date.getMonth() + 1 <= 9) {
    month = `0${date.getMonth() + 1}`;
  } else {
    month = date.getMonth() + 1;
  }

  if (date.getDate() <= 9) {
    dd = `0${date.getDate()}`;
  } else {
    dd = date.getDate();
  }

  if (date.getHours() <= 9) {
    hr = `0${date.getHours()}`;
  } else {
    hr = date.getHours();
  }

  if (date.getMinutes() <= 9) {
    mins = `0${date.getMinutes()}`;
  } else {
    mins = date.getMinutes();
  }

  return `${dd}-${month}-${date.getFullYear()} ${hr}:${mins}`;
};

export const notify = (type, message) => {
  console.log("abhishek reached notify function");
  const event = new CustomEvent("notify", {
    detail: {
      type: type,
      message: message,
    },
  });
  document?.dispatchEvent(event);
};

export const notifyTypes = {
  success: toast.TYPE.SUCCESS,
  error: toast.TYPE.ERROR,
  warning: toast.TYPE.WARNING,
  info: toast.TYPE.INFO,
  default: toast.TYPE.DEFAULT,
};
