import React, { Fragment } from "react";

import "../../../Asset/scss/login/login.scss";
import "../../../Asset/scss/ForgotPassword/RecoverPassword.scss";
import "../../../Asset/scss/ForgotPassword/ResetPassword.scss";

import Loginsvg from "../../../Asset/images/LoginImage.svg";
import ResetPasswordLock from "../../../Asset/images/ResetPasswordLock.png";
import { Link } from "react-router-dom";

const ResetPassword = () => {

    return (
        <Fragment>
            <div className="custom">
                <div className="MainContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 login_img_container displayFlexCenter">
                                <img src={Loginsvg} alt="" />
                            </div>
                            <div className="offset-lg-1 col-lg-5 col-md-5 displayFlexCenter">
                                <div className="container FormContainer">
                                    <img className="ResetLock Lock_img_margin" src={ResetPasswordLock} alt="" />
                                    <h4 className="Login_heading text-center RecoverPasswordHeading">Reset Your Password</h4>
                                    <form>
                                        <div className="Lable_input_container">
                                            <label htmlFor="exampleInputEmail1 " className="form-label">
                                                Email
                                            </label>
                                            <input
                                                type="email "
                                                className="form-control LogingEmail "
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                            />
                                        </div>
                                        <div className="Lable_input_container">
                                            <label htmlFor="exampleInputPassword1 " className="form-label">
                                                Password
                                            </label>
                                            <div className="afterEye">
                                            <input
                                                type="password"
                                                className="form-control LoginPasswoard"
                                                id="exampleInputPassword1"
                                            />
                                            </div>
                                        </div>
                                        <div className="Lable_input_container">
                                            <label htmlFor="exampleInputPassword1 " className="form-label">
                                                Confirm Password
                                            </label>
                                            <div className="afterEye">
                                            <input
                                                type="password"
                                                className="form-control LoginPasswoard"
                                                id="exampleInputPassword1"
                                            />
                                            </div>
                                        </div>
                                        <Link to="/interest_area">
                                        <button type="submit" className="btn btn-primary Login_button ">
                                            Reset Password
                                        </button>
                                        </Link>
                                    </form>
                                    <h6 className="GoBackLogin">Go back to <span>Login</span> </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default ResetPassword;
