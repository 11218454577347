import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import "../AddSpeakers/AddSpeakers.scss";
import "./users.scss";

import ModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddPracticeAreaModal from "../../UICommon/Modal/Practice-Area-Modal/AddPracticeAreaModal";
import EditPracticeArea from "../../UICommon/Modal/Practice-Area-Modal/EditPracticeArea";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";

import svg1 from "../../Asset/images/avatars/1.svg";
import svg2 from "../../Asset/images/avatars/2.svg";
import svg3 from "../../Asset/images/avatars/3.svg";
import svg4 from "../../Asset/images/avatars/4.svg";
import svg5 from "../../Asset/images/avatars/5.svg";
import svg6 from "../../Asset/images/avatars/6.svg";
import svg7 from "../../Asset/images/avatars/7.svg";
import svg8 from "../../Asset/images/avatars/8.svg";
import importPhotoIcon from "../../Asset/images/icons/importPhotoIcon.svg";
import dropdownArrow from "../../Asset/images/icons/dropdownArrow.svg";
import AddModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddUserModalcontainer from "../../UICommon/Modal/Add-Speakers-modal/AddUser-Modalcontainer";
import Apilib from "../../Api";
import { getDateString, notify, notifyTypes } from "../../Utils";

import { Editor } from "@tinymce/tinymce-react";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import AddUsers from "./models/AddUsers";
import EditUser from "./models/EditUser";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Blocked </button>
);
function createData(
  id,
  profileImg,
  name,
  email,
  phoneNo,
  organization,
  certified,
  membership,
  status
) {
  return {
    id,
    profileImg,
    name,
    email,
    phoneNo,
    organization,
    certified,
    membership,
    status,
  };
}

// const rows = [
//   createData(
//     1,
//     svg1,
//     "Regina Cooper",
//     "cooper@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     2,
//     svg2,
//     "Judith Black",
//     "black@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     3,
//     svg3,
//     "Ronald Robertson",
//     "robe@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     4,
//     svg4,
//     "Dustin Williamson",
//     "williams@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     5,
//     svg5,
//     "Calvin Flores",
//     "flores@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     DisabledButton
//   ),
//   createData(
//     6,
//     svg6,
//     "Robert Edwards",
//     "edwards@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     7,
//     svg7,
//     "Colleen Warren",
//     "warren@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "no",
//     "12.09.20",
//     activeButton
//   ),
//   createData(
//     8,
//     svg8,
//     "Nathan Fox",
//     "fox@example.com",
//     "+1 (070) 123–4567",
//     "Hedweeg Inn.",
//     "yes",
//     "12.09.20",
//     activeButton
//   ),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "Name",
    enableSorting: true,
  },
  {
    id: "Phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    enableSorting: false,
  },
  {
    id: "organization",
    numeric: false,
    disablePadding: false,
    label: "organization",
    enableSorting: false,

  },
  {
    id: "isCertified",
    numeric: false,
    disablePadding: false,
    label: "Certified",
    enableSorting: true,
  },
  {
    id: "Membership",
    numeric: true,
    disablePadding: false,
    label: "Membership ",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created On ",
    enableSorting: true,
  },
  // {
  //   id: "isActive",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status ",
  //   enableSorting: true,
  // },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >

                {orderBy === headCell.id ? (
                  <Box className="SortArrow" component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Users = () => {
  const API = new Apilib();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteID, setDeleteID] = useState(null);

  const [selectedRow, setSelectedRow] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);

  const [openEditModel, setOpenEditModel] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);

  const [openAddUsers, setOpenAddUsers] = useState(false);
  const openAddUserModelHandler = () => {
    setOpenAddUsers(true);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const [openAddUSers, setOpenAddUSers] = useState(false);
  const [openEditUSers, setOpenEditUSers] = useState(false);

  useEffect(() => {
    fetchUsers();
    setOrder("desc");
    setOrderBy('createdAt');
  }, []);

  const fetchUsers = (text) => {
    API.getUsers(text)
      .then((res) => {
        // console.log("get users", res?.data?.data);
        setRows(res?.data?.data);
        debugger;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const deleteUser = (id) => {
    // console.log("Inside deleteUser --------------------------------------", id)
    API.deleteUserHard(id)
      .then((res) => {
        console.log("delete User", res?.data);
        notify(notifyTypes?.success, "Deleted User Successfully.");
        fetchUsers();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete User.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editUser(id, data)
      .then((res) => {
        notify(notifyTypes?.success, "Updated the status successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchUsers(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const exportUsers = (status, id) => {

    API.exportUsers()
      .then((res) => {
        notify(notifyTypes?.success, "Generating csv file, will be mailed to you shortly");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to export the users");
      })
      .finally((e) => {
        fetchUsers(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };



  return (
    <>
      <div
      // onClick={closeThree_dot}
      >
        {openAddUSers ? (
          <div className="addUserModel_container">
            <AddUsers {...{ setOpenAddUSers }} />
          </div>
        ) : null}
        {openEditModel ? (
          <div className="addUserModel_container">
            <EditUser openEditModel={openEditModel} fetchUsers={fetchUsers} searchValue={searchValue} values={selectedRow} {...{ setOpenEditModel }} />
          </div>
        ) : null}

        <div
          className="Page_heading_container"
          style={{ marginBottom: "2rem" }}
        >
          <h1>Users</h1>
          <div>
            <button
              onClick={() => {
                exportUsers();
              }}
              className="Add_Head_Button"
            >
              Export All Users
            </button>

            <button
              onClick={() => {
                setOpenAddUSers(true);
              }}
              style={{ marginLeft: "4px" }}
              className="Add_Head_Button"
            >
              <img src={AddIcon} alt="" /> Add User
            </button>

          </div>

        </div>

        {openDeleteConfirmation ? (
          <DeleteConfirmation
            onDelete={() => {
              setOpenDeleteConfirmation(false);
              deleteUser(deleteID);
            }}
            {...{ setOpenDeleteConfirmation }}
          />
        ) : null}
        {/* {openEditModel ? (
          <AddUserModalcontainer {...{ openAddUsers, setOpenAddUsers }}>
            <div className="All_speaker_form_container">
              <div className="model_head_container">
                <div className="underLine_section">
                  <h6>Basic Info</h6>
                </div>
              </div>

              <div className="add_specker_form">
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label htmlFor="">Photo</label>
                      <div className="upload_photo_container">
                        <img src={importPhotoIcon} alt="" />
                        <p>
                          Drag and Drop or{" "}
                          <label htmlFor="AddPhoto"> Browse </label> to upload
                        </p>
                      </div>
                      <input
                        type="file"
                        class="form-control"
                        id="AddPhoto"
                        placeholder="Name"
                      />
                    </div>
                    <div class="form-group">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="First Name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Last Name"
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="">Phone Number</label>
                      <div className="mobile_no_main_container">
                        <select
                          class="form-select phone_no_start_select"
                          aria-label="Default select example"
                        >
                          <option selected>+91</option>
                          <option value="1">+91</option>
                          <option value="2">+92</option>
                          <option value="3">+93</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          id=""
                          placeholder="99 9999 9999"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group">
                      <label>Organization Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Organization Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 custom_text_editor">
                    <div class="form-group">
                      <label>Designation</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Designation"
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="">Tell us about yourself</label>
                      <textarea
                        class="form-control"
                        style={{
                          height: 200,
                          width: "100%",
                        }}
                        name="Tell us about yourself"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label>Country of residence</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Country of residence"
                      />
                    </div>
                    <div class="form-group">
                      <label>State</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="State"
                      />
                    </div>
                    <div class="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="City"
                      />
                    </div>
                    <div class="form-group">
                      <label>Industry</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Industry"
                      />
                    </div>
                  </div>
                </div>

                <div className="done_cancel_container">
                  <button
                    onClick={() => setOpenEditModel(false)}
                    className="btn done_button"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => setOpenEditModel(false)}
                    className=" btn cancel_button"
                    type=""
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </AddUserModalcontainer>
        ) : null} */}

        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <SearchBarNActionButtonContainer
              onTextChange={(text) => {
                console.log('User text');
                console.log(text);
                setPage(1);
                setSearchValue(text);
                fetchUsers(text);
              }}
              {...{ setOpenDeleteConfirmation }}
              className="codered"
            />

            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className="custom_table_css"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      // console.log(labelId,"labelId");

                      return (
                        <TableRowComponent
                          key={row?._id}
                          changeActiveStatus={(status, id) => {
                            changeActiveStatus(status, id);
                          }}
                          {...{
                            row,
                            index,
                            selected,
                            handleSelection,
                            showMoreActionForID,
                            setShowMoreActionForID,
                            setOpenEditUSers,
                            setOpenEditModel,
                            setOpenDeleteConfirmation,
                            setSelectedRow,
                            setDeleteID,
                          }}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="table_pageination">
              <TablePagination
                className="rowNo_pageCounting"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              <Pagination
                className="Table_Pagination_number"
                shape="rounded"
                count={needLength}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </Paper>
          {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
        </Box>
      </div>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;

  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      console.log("only outside")
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        // console.log(ref.current.contains(e.target))
        return setShowMoreActionForID(null);
      }
    };
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showMoreActionForID) {
        console.log("only inside")
        e.stopPropagation();
        document
          .querySelector("*")
          .addEventListener("mousedown", checkIfClickedOutside);
        // return   setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);


    return () => {
      // Cleanup the event listener
      console.log("only cleanup")
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////

  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row?._id);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/*  {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell
        className="profileImg_name_container  "
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <div className="users_profileImg_name_email_container">
          <img
            style={{
              height: 40,
              width: 40,
            }}
            src={row.profilePic ? row.profilePic : svg1}
            alt=""
          />
          <div className="users_name_email_container">
            <div>
              {row.firstname} {row?.lastname}
            </div>
            <div>{row.email}</div>
          </div>
        </div>
      </TableCell>

      <TableCell
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        align="left"
      >
        {row?.mobile?.countryCode} {row?.mobile?.number}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        align="left"
      >
        {row?.organization?.client_name}
      </TableCell>
      <TableCell align="left">
        {row?.isCertified ? (
          <button className="Active_statusButton"> Yes </button>
        ) : (
          <button className="Disabled_statusButton"> No </button>
        )}
      </TableCell>
      <TableCell align="left">{row.membership}</TableCell>
      <TableCell align="left">{getDateString(row.createdAt)}</TableCell>
      {/* <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell> */}
      <TableCell id={labelId} align="left" ref={ref}>
        {" "}
        <button className="more_option_manu" onClick={openMoreMenuHandler}>
          <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
        </button>
        <ThreeDotOption
          show={showMoreActionForID === row._id}
          isActive={row?.isActive}
          changeActiveStatus={(status) => {
            console.log("status", status);
            changeActiveStatus(status, row?._id);
          }}
          setOpenEditModel={() => {
            setShowMoreActionForID(null);
            setOpenEditModel(true);
            setSelectedRow(row);
          }}
          setDeleteID={() => {
            setDeleteID(row?._id);
          }}
          hideEditStatus={true}
          {...{ setOpenDeleteConfirmation }}
        />
      </TableCell>
    </TableRow>
  );
};
export default Users;
