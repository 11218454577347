import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// preview header
import MobileSignal from "../../../Asset/images/icons/MobileSignal.svg";
import Wifi from "../../../Asset/images/icons/Wifi.svg";
import Battery from "../../../Asset/images/icons/Battery.svg";

import preview_video_thumbnail from "../../../Asset/images/speakerVideo/preview_video_thumbnail.png";
import bookmarkIcon from "../../../Asset/images/icons/bookmarkIcon.svg";
import shareIcon from "../../../Asset/images/icons/shareIcon.svg";
import thumbsUp from "../../../Asset/images/icons/thumbsUp.svg";
import backArrow from "../../../Asset/images/icons/backArrow.svg";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PreviewCaseStudy = ({
  setOpenPreviewCaseStudy,
  previewValues,
  addCaseStudy,
}) => {
  const date = new Date();
  const dateString =
    monthNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear();
  return (
    <div className="preview_case_study_main">
      <h6 className="heading_preview_mode">Preview Mode</h6>
      <div className="row">
        <div className=" col-lg-5" style={{ position: "relative" }}>
          <div className="speaker_video_list_container custom_for_all_heights ">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <Stack className="skeleton_square_container1" spacing={1}>
              <Skeleton variant="rectangular" width={30} height={30} />
              <Skeleton variant="rectangular" width={30} height={30} />
            </Stack>

            <div className="preview_HeadingParaTringle">
              <h1>Case Studies</h1>
              <p>Simply dummy text of the printing</p>
              <Stack className="skeleton_rectangular_container">
                <Skeleton variant="rounded" width={90} height={30} />
                <Skeleton variant="rounded" width={90} height={30} />
                <Skeleton variant="rounded" width={90} height={30} />
              </Stack>
            </div>
            <hr />

            <div className="case_study_list_container">
              <div className="case_study_list_text">
                <div className="study_name_photo_container">
                  <img src={previewValues?.authorImageValues?.url} alt="" /> 
                  <p>
                    Authors:{" "}
                    {previewValues?.authors?.length > 1
                      ? `${previewValues?.authors[0]?.name}, +${
                          previewValues?.authors?.length - 1
                        } more`
                      : previewValues?.authors?.length == 1
                      ? previewValues?.authors[0]?.name
                      : "N/A"}
                  </p>
                </div>
                <div className="study_title_date_container">
                  <h4>{previewValues?.title}</h4>
                  <p>
                    {dateString} | {previewValues?.minsToRead} mins read
                  </p>
                  {/* <span>Based on your interest</span> */}
                </div>
              </div>
              <div className="case_study_img_share_container">
                <img
                  className="list_main_img"
                  src={previewValues?.imageValues?.url}
                  alt=""
                />
                <div className="detail_icons">
                  <img src={bookmarkIcon} alt="" />
                  <img src={shareIcon} alt="" />
                </div>
              </div>
            </div>
            <hr />
            <div className="case_study_list_container">
              <div className="case_study_list_text">
                <div className="study_name_photo_container">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={80} height={20} />
                </div>
                <div className="study_title_date_container skelaton_title_date_container">
                  <Skeleton variant="rectangular" width={150} height={20} />
                  <Skeleton variant="rectangular" width={170} height={16} />
                  <Skeleton variant="rectangular" width={120} height={16} />
                </div>
              </div>
              <div className="case_study_img_share_container">
                <Skeleton variant="rounded" width={100} height={80} />
                <div className="detail_icons">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={20} height={20} />
                </div>
              </div>
            </div>
            <hr />
            <div className="case_study_list_container">
              <div className="case_study_list_text">
                <div className="study_name_photo_container">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={80} height={20} />
                </div>
                <div className="study_title_date_container skelaton_title_date_container">
                  <Skeleton variant="rectangular" width={150} height={20} />
                  <Skeleton variant="rectangular" width={170} height={16} />
                  <Skeleton variant="rectangular" width={120} height={16} />
                </div>
              </div>
              <div className="case_study_img_share_container">
                <Skeleton variant="rounded" width={100} height={80} />
                <div className="detail_icons">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={20} height={20} />
                </div>
              </div>
            </div>
            <hr />
            <div className="case_study_list_container">
              <div className="case_study_list_text">
                <div className="study_name_photo_container">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={80} height={20} />
                </div>
                <div className="study_title_date_container skelaton_title_date_container">
                  <Skeleton variant="rectangular" width={150} height={20} />
                  <Skeleton variant="rectangular" width={170} height={16} />
                  <Skeleton variant="rectangular" width={120} height={16} />
                </div>
              </div>
              <div className="case_study_img_share_container">
                <Skeleton variant="rounded" width={100} height={80} />
                <div className="detail_icons">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={20} height={20} />
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "5%",
                left: "0",
              }}
            >
              <span className="bottom_black_line"></span>
            </div>
          </div>
        </div>

        <div className="offset-lg-2 col-lg-5">
          <div className="speaker_video_list_container custom_speaker_video_pageHeight case_study_detail_preview custom_for_all_heights ">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>
            <div style={{ margin: "20px 0" }}>
              <img src={backArrow} alt="" />
            </div>
            <div className="preview_HeadingParaTringle">
              <h1>{previewValues?.title}</h1>
            </div>

            <div className="case_author_name_time_container">
              <div className="image_author_container">
                <img src={previewValues?.authorImageValues?.url} alt="" />
                <p>
                  Authors:{" "}
                  {previewValues?.authors?.length > 1
                    ? `${previewValues?.authors[0]?.name}, +${
                        previewValues?.authors?.length - 1
                      } more`
                    : previewValues?.authors?.length == 1
                    ? previewValues?.authors[0]?.name
                    : "N/A"}
                </p>
              </div>
              <p>
                {previewValues?.minsToRead} mins read, {dateString}{" "}
              </p>
            </div>

            <div className="listen_ready_containers">
              <button type="button">Listen</button>
              <button type="button">Read</button>
              <button type="button">Start a discussion</button>
            </div>
            <p className="pre_mainImg_text">{previewValues?.description}</p>
            <div className="speaker_video_details_container">
              <img
                className="custom_thumbnain_img"
                src={previewValues?.imageValues?.url}
                alt=""
              />
            </div>

            {/* <div className="SpeakerVideo_DescPara">
              <p>
                {previewValues?.description}
              </p>
            </div> */}

              <div className="speaker_video_footer">
                <div className="Video_action_icon_container">
                  <img src={thumbsUp} alt="" />
                  <img src={bookmarkIcon} alt="" />
                  <img src={shareIcon} alt="" />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span className="bottom_black_line"></span>
                </div>
              </div>
          </div>
        </div>

        <div className="publish_close_button_container">
          <button
            onClick={() => {
              addCaseStudy();
              setOpenPreviewCaseStudy(false);
            }}
          >
            Publish
          </button>
          <button
            onClick={() => {
              setOpenPreviewCaseStudy(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewCaseStudy;
