import axios from "axios";

//production
// const url = "http://15.206.199.220:3000/api/v1";
// const url = "https://api.forallcommunity.com/";
// development
// const url = "http://15.206.115.220:5000/api/v1";
// local
// const url = "http://localhost:5000/api/v1";

const url = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_ENV, url);

const instance = axios.create({
  baseURL: url,
});

export default instance;
