import React, { useState, useEffect } from "react";
import "./AddInterestArea-Modal.scss";
import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import "./EditInterestArea-Modal.scss";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import ApiLib from "../../../Api";
import { notify, notifyTypes } from "../../../Utils";
const EditInterestAreaModal = (props, { setOpenEditModel }) => {
  const API = new ApiLib();
  const [selectedInterest, setSelectedInterest] = useState(props?.data);
  const [text, setText] = useState(props?.data?.name || "");
  const [isActive, setIsActive] = useState(props?.data?.isActive);
  const handleChange = (event) => {
    setText(event.target.value);
  };

  const editInterest = () => {
    var id = selectedInterest?._id;
    var data = {
      isActive: isActive,
      name: text,
    };
    API.editInterest(id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Interest Edited Successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Edit Interest");
      })
      .finally((e) => {
        props?.onEdited();
        // setLoading(false);
      });
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <div>
      <div className="heading_and_close_container">
        <h1 className="modal_heading">Edit Interest Area</h1>
        <button
          onClick={() => {
            props?.setOpenEditModel(false);
          }}
        >
          <img src={closeIcon} alt="" />
        </button>
      </div>

      <div>
        {/* <p className="ModalPara">Edit Interest Area and press enter</p> */}
        {/* <form> */}
        <input
          className="ADD_category_input"
          type="text"
          placeholder="Add a Interest Area"
          name="categoryInput"
          value={text}
          onChange={handleChange}
        />
        <FormGroup className="radio_lable_inline">
          <label htmlFor="">Status</label>
          <FormControlLabel
            className="status_radio_button"
            control={
              <IOSSwitch
                className="radio_button_background"
                sx={{ m: 1 }}
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
                // defaultChecked
              />
            }
          />
        </FormGroup>

        <button
          onClick={editInterest}
          className="submitModalButton"
          type="submit"
        >
          Save
        </button>
        {/* </form> */}
      </div>
    </div>
  );
};

export default EditInterestAreaModal;
