import React, { useState } from "react";
import "./searchbarNactionbuttoncontainer.scss";
import searchIcon from "../../Asset/images/icons/searchIcon.svg";
import filterIcon from "../../Asset/images/icons/filterIcon.svg";
import dropdownArrow from "../../Asset/images/icons/dropdownArrow.svg";
import ActionButtonDropdown from "./ActionButtonDropdown";


const SearchBarNActionButtonContainer = (props, { className, setOpenDeleteConfirmation }) => {



  const [openActionDropdown, setOpenActionDropdown] = useState(false);

  const openActionDropdownHandler = () => {
    setOpenActionDropdown((openActionDropdown) => !openActionDropdown);
  };

  return (
    <div className={`actionButton_searchBar_container ${props?.className}`}>
      <div className="input-group search_bar_container">
        <div className="input-group-prepend">
          <span className="input-group-text search_container">
            <img src={searchIcon} alt="" />
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          aria-label="Amount (to the nearest dollar)"
          placeholder="Search...."
          onChange={(event) => {
            // console.log("abhishek", event?.target?.value);
            props?.onTextChange(event?.target?.value);
          }}
        />
        <div className="input-group-append">
          <span className="input-group-text filter_container">
            {/* <img src={filterIcon} alt="" /> */}
          </span>
        </div>
      </div>

      {/* <div style={{ position: "relative" }}>
        <button onClick={openActionDropdownHandler} className="action_button">
          {" "}
          Actions <img src={dropdownArrow} alt="" />
        </button>

        {openActionDropdown ? < ActionButtonDropdown {...{setOpenDeleteConfirmation}} /> : null}

      </div> */}
    </div>
  );
};
export default SearchBarNActionButtonContainer;
