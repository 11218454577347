import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";

import CalanderIcon from "../../Asset/images/sidebar/icons/Calendar.svg";
import GPTWLogo from "../../Asset/images/sidebar/GPTW_logo/gptwlogo.png";
import MenuIcon from "../../Asset/images/Header/MenuIcon.svg";
import CloseMenuIcon from "../../Asset/images/Header/CloseMenuIcon.svg";


import "../../Asset/scss/MainComponents/sidebar.scss";
import Header from "../Header/Header";
// import { Login } from "@mui/icons-material";
import { useLocation, Link } from "react-router-dom";
import { dashboardListingSuper, routes, dashboardListingContent, dashboardListingQuality } from "../../routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";

const drawerWidth = 240;

const IconBaseUrl = "./Asset/images/sidebar/icons/"

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Sidebar = ({ children }) => {

  // const loginResponse = useSelector(state => state.loginReducer.loginData)
  const theme = useTheme();

  const param = useLocation();

  const { pathname } = param;
  // console.log(pathname, "hiiiii")
  const path = pathname.split('/')[1];
  // console.log(path, "hiiiii")


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {

    setOpen(open => !open);
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  console.log('This is the  side bar ');
  // console.log(loginResponse);

  const loginResponse = localStorage.getItem('loginResponse');
  const role = loginResponse ? loginResponse : ''
  console.log(loginResponse);
  console.log(role);



  return (
    <>
      <Box className="sidebarContainer" sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar className="MainHeader">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "block" }) }}
            >
              {/* <MenuIcon /> */}
              {open ?
                <img style={{ height: "25px", width: "auto" }} className="close_menu_icon" src={MenuIcon} alt="" />
                // <div className="custom_background">
                //   <FontAwesomeIcon icon={faAngleLeft} />
                // </div>
                :
                <img src={MenuIcon} alt="" />
              }
            </IconButton>


            <Header />



          </Toolbar>
        </AppBar>
        <Drawer
          className="drawer_background"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img className="MainGptwLogo" src={GPTWLogo} />
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
          </DrawerHeader>

          <div className="sidebarList">
            <ul>
              {
                role && role === 'quality' &&
                dashboardListingQuality.map(route => {
                  return (
                    <Link key={route} to={`/${route}`}>
                      <li onClick={() => { }} className={path == route ? 'active' : ""}>
                        <img src={`${IconBaseUrl}${routes[route]?.icon}`} alt={routes[route].label} /> <h5> {routes[route].label} </h5>{" "}
                      </li>
                    </Link>
                  )
                })
              }

              {
                role && role === 'content' &&
                dashboardListingContent.map(route => {
                  return (
                    <Link key={route} to={`/${route}`}>
                      <li onClick={() => { }} className={path == route ? 'active' : ""}>
                        <img src={`${IconBaseUrl}${routes[route]?.icon}`} alt={routes[route].label} /> <h5> {routes[route].label} </h5>{" "}
                      </li>
                    </Link>
                  )
                })
              }

              {
                role && role === 'super' &&
                dashboardListingSuper.map(route => {
                  return (
                    <Link key={route} to={`/${route}`}>
                      <li onClick={() => { }} className={path == route ? 'active' : ""}>
                        <img src={`${IconBaseUrl}${routes[route]?.icon}`} alt={routes[route].label} /> <h5> {routes[route].label} </h5>{" "}
                      </li>
                    </Link>
                  )
                })
              }



            </ul>
          </div>
        </Drawer>
        <Main open={open} style={{ maxWidth: "100%", minWidth: "70%" }}>
          <DrawerHeader />

          {children}

        </Main>
      </Box>
    </>
  );
};

export default Sidebar;
