import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import "./AddSpeakerVideo.scss";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";

// avatar
import svg1 from "../../Asset/images/avatars/1.svg";
import svg2 from "../../Asset/images/avatars/2.svg";
import svg3 from "../../Asset/images/avatars/3.svg";
import svg4 from "../../Asset/images/avatars/4.svg";
//

// thumbnail
import thumb1 from "../../Asset/images/speakerVideo/thumbnail/1.png";
import thumb2 from "../../Asset/images/speakerVideo/thumbnail/2.png";
import thumb3 from "../../Asset/images/speakerVideo/thumbnail/3.png";
import thumb4 from "../../Asset/images/speakerVideo/thumbnail/4.png";
import thumb5 from "../../Asset/images/speakerVideo/thumbnail/5.png";

import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";

import { styled } from "@mui/material/styles";
import AddSpeakerVideoModal from "./Modals/AddSpeakerVideo-modal";
import EditSpeakerVideoModal from "./Modals/EditSpeakerVideo-modal";
import PreviewSpeakerVideoModel from "./Modals/PreviewSpeakerVideo-model";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import Apilib from "../../Api";
import { notify, notifyTypes } from "../../Utils";
import SelectSpeaker from "./Modals/SelectSpeaker";
import ModalContainer from "../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";
import { getDateString, getDateString_IST } from "../../Utils";
const StatusButton = (
  <button className="Published_custom_button">Published</button>
);
const speckersAvatar = [svg1, svg2, svg3, svg4];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
    enableSorting: true,
  },
  {
    id: "speakerName",
    numeric: false,
    disablePadding: true,
    label: "Speaker Name",
    enableSorting: false,
  },
  {
    id: "industries",
    numeric: false,
    disablePadding: true,
    label: "Industry",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On",
    enableSorting: true,
  },
  {
    id: "scheduledTime",
    numeric: true,
    disablePadding: false,
    label: "Publish Date",
    sortingEnabled: false,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "status",
    enableSorting: true,
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: " ",
    enableSorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <Box
                    className="SortArrow"
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AddSpeakerVideo = () => {
  const API = new Apilib();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);

  const [showPreview, setShowPreview] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [previewValues, setPreviewValues] = useState({});
  const [selectedRow, setSelectedRow] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [showSelectSpeaker, setShowSelectSpeaker] = useState(false);
  const [audioFileDeleted, setAudioFileDeleted] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const [deleteID, setDeleteID] = useState(null);
  const [editID, setEditID] = useState(null);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);

  const openMoreMenuHandler = (name) => {
    // if (name==name) {
    console.log(name);
    setShowMoreAction((showMoreAction) => !showMoreAction);
    // }
  };

  const [openAddVideo, setOpenAddVideo] = useState(false);
  const openAddUserModelHandler = () => {
    setOpenAddVideo(true);
  };

  useEffect(() => {
    fetchSpeakerVideos();
    setOrder("desc");
    setOrderBy('createdAt');
  }, []);

  const fetchSpeakerVideos = (text) => {
    API.getSpeakerVideos(text)
      .then((res) => {
        setRows(res?.data?.videos);
        // setPage(1);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editSpeakerVideo(id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchSpeakerVideos(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const deleteSpeakerVideo = (id) => {
    API.deleteSpeakerVideo(id)
      .then((res) => {
        console.log("delete speaker", res?.data);
        notify(notifyTypes?.success, "Deleted Speaker Video Successfully.");
        fetchSpeakerVideos(searchValue);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Speaker Video.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const createSpeakerVideo = () => {
    console.log(previewValues);
    var id = "";
    var data = {
      title: previewValues?.title,
      video: previewValues?.video,
      description: previewValues?.description,
      interests: previewValues?.interests,
      industries: previewValues?.industries,
      speakers: previewValues?.speakers,
      contentLevel: ["free", "non-certified"],
      isPremium: previewValues?.isPremium,
      isCertified: previewValues?.isCertified,
      isActive: false,
      createdAt: previewValues?.createdAt,
      scheduledTime: previewValues?.scheduledTime,

    };
    if (audioFileDeleted)
      data.audio = "";
    API.addSpeakerVideo(data)
      .then((res) => {
        console.log("add speaker video response", res?.data?.speakerVideo?._id);
        notify(notifyTypes?.success, "Speaker Video Added Successfully");
        id = res?.data?.speakerVideo?._id;
        uploadMedia(res?.data?.speakerVideo?._id);
        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Industry");
      })
      .finally((e) => {
        // props.onAdded();
        // setLoading(false);
        fetchSpeakerVideos();
        setPreviewValues({});
        setShowPreview(false);
        setOpenAddVideo(false);
      });
  };

  const editSpeakerVideo = (id) => {
    var data = {
      title: previewValues?.title,
      video: previewValues?.video,
      description: previewValues?.description,
      interests: previewValues?.interests,
      industries: previewValues?.industries,
      speakers: previewValues?.speakers,
      contentLevel: previewValues?.contentLevel,
      isPremium: previewValues?.isPremium,
      isCertified: previewValues?.isCertified,
      isActive: previewValues?.isActive,
      scheduledTime: previewValues?.scheduledTime,
    };
    console.log("preview value ------> ", previewValues);
    if (audioFileDeleted)
      data.audio = "";
    console.log("data before editing ----> ", data);
    API.editSpeakerVideo(id, data)
      .then((res) => {
        console.log(
          "edit speaker video response",
          res?.data?.speakerVideo?._id
        );
        notify(notifyTypes?.success, "Speaker Video Edited Successfully");
        // id = res?.data?.speakerVideo?._id;
        uploadMedia(id);
        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Edit Speaker Video");
      })
      .finally((e) => {
        // props.onAdded();
        // setLoading(false);
        fetchSpeakerVideos();
        setPreviewValues({});
        setShowPreview(false);
        setOpenEditModel(false);
        setEditID(null);
      });
  };

  const uploadMedia = (id) => {
    var mediaData = new FormData();
    console.log(previewValues);
    mediaData.append("coverImage", previewValues?.imageValues?.source);
    if (previewValues?.audioValues?.loaded == true) {
      mediaData.append("audio", previewValues?.audioValues?.source);
    }
    console.log(mediaData, id, "mediaData, id");
    API.uploadSpeakerVideoMedia(mediaData, id)
      .then((res) => {
        console.log("add speaker video response", res?.data);
        // notify(notifyTypes?.success, "Industry Added Successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Image");
      })
      .finally((e) => {
        fetchSpeakerVideos();
      });
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .<MuiSwitch-thumb>": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <div
        style={{ marginBottom: "2rem" }}
        className="Page_heading_container Page_heading_SpeakerVideo"
      >
        <h1>Speaker Video</h1>
        <button onClick={openAddUserModelHandler} className="Add_Head_Button">
          <img src={AddIcon} alt="" />
        </button>
      </div>
      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            console.log(deleteID);
            setOpenDeleteConfirmation(false);
            deleteSpeakerVideo(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}
      {showSelectSpeaker ? (
        <ModalContainer {...{ setShowSelectSpeaker }}>
          <SelectSpeaker {...{ setShowSelectSpeaker }} />
        </ModalContainer>
      ) : null}

      {openAddVideo ? (
        <AddSpeakerVideoModalcontainer {...{ openAddVideo, setOpenAddVideo }}>
          <AddSpeakerVideoModal
            setAudioFileDeleted={setAudioFileDeleted}
            setShowPreview={(values) => {
              // console.log("abhishelk");
              setPreviewValues(values);
              setShowPreview(true);
            }}
            {...{
              showPreview,
              // setShowPreview,
              setOpenAddVideo,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openEditModel ? (
        <AddSpeakerVideoModalcontainer>
          <EditSpeakerVideoModal
            id={selectedRow?._id}
            setAudioFileDeleted={setAudioFileDeleted}
            interests={selectedRow?.interests}
            industries={selectedRow?.industries}
            speakers={selectedRow?.speakers}
            setShowPreview={(values) => {
              // console.log("abhishelk");
              setPreviewValues(values);
              setShowPreview(true);
              setEditID(selectedRow?._id);
            }}
            {...{
              showPreview,
              // setShowPreview,
              setOpenEditModel,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {showPreview ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewSpeakerVideoModel
            data={previewValues}
            edit={editID ? true : false}
            onPublish={() => {
              if (editID) {
                editSpeakerVideo(editID);
              } else {
                createSpeakerVideo();
              }
            }}
            {...{ showPreview, setShowPreview }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {/* <ChipComponent /> */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <SearchBarNActionButtonContainer
            onTextChange={(text) => {
              // console.log(text);
              setPage(1);
              setSearchValue(text);
              fetchSpeakerVideos(text);
            }}
            {...{ setOpenDeleteConfirmation }}
            className="codered"
          />

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRowComponent
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        setSelectedRow={(row) => {
                          setSelectedRow(row);
                          // setEditImageValues({
                          //   ...editImageValues,
                          //   loaded: true,
                          //   url: row?.profilePic,
                          // });
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setDeleteID,
                          setRows,
                          rows,
                          page,
                          setPage
                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
        {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
  setRows,
  rows,
  page,
  setPage,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);


  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {

    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row?._id);
    }
    setRows(rows);
  };
  // console.log(row.speakers[0].name, "temp");
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      {/* <TableCell
        className="profileImg_name_container Thumbnail_Img_name_container"
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        <img src={row.thumbnail} alt="" />
      </TableCell> */}
      <TableCell
        className="profileImg_name_container "
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        style={{
          width: 400,
          fontWeight: 900,
          cursor: "pointer",
        }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <div className="Name_discription_container">
          {row?.title}
          {/* <div className="discription_created_by"> {row?.description}</div> */}
        </div>
      </TableCell>

      <TableCell>
        {row?.speakers.map((speakerData) => (
          <div className="ellipse-text">{speakerData.name}</div>
        ))}
      </TableCell>

      <TableCell align="left">
        {row?.industries?.length > 1
          ? `${row?.industries[0]?.name}, +${row?.industries?.length - 1} more`
          : row?.industries?.length == 1
            ? row?.industries[0]?.name
            : "N/A"}
      </TableCell>

      {/* <TableCell
        className="profileImg_name_container "
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        style={{
          width: 350,
          cursor: "pointer",
        }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <div className="ellipse-text">{row?.description}</div>
      </TableCell> */}

      {/* <TableCell align="left">{row?.creadtedBy?.name}</TableCell> */}
      {/* <TableCell className="speaker_avatar_img_overlay" align="center">
        {" "}
        {row?.speakers?.map((speaker) => (
          <img
            src={speaker?.profilePic}
            style={{
              borderRadius: "50%",
              height: 40,
              width: 40,
            }}
            alt="speakers images"
          />
        ))}{" "}
      </TableCell> */}
      {/* <TableCell align="left">{row.OrgType}</TableCell> */}
      <TableCell>
        <div className="ellipse-text">{getDateString(row?.createdAt)}</div>
      </TableCell>
      <TableCell>
        <div className="ellipse-text">{row?.scheduledTime ? getDateString_IST(row?.scheduledTime) : 'N/A'}</div>
      </TableCell>
      <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell>

      <TableCell id={labelId} align="left">
        {" "}
        <div ref={ref}>
          <button className="more_option_manu" onClick={openMoreMenuHandler}>
            <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
          </button>
          {/* {showMoreActionForID === row?._id ? ( */}
          <ThreeDotOption
            show={showMoreActionForID === row._id}
            isActive={row?.isActive}
            changeActiveStatus={(status) => {
              changeActiveStatus(status, row?._id);
            }}
            setOpenEditModel={() => {
              setShowMoreActionForID(null);
              setOpenEditModel(true);
              setSelectedRow(row);
            }}
            setDeleteID={() => {
              setDeleteID(row?._id);
            }}
            {...{ setOpenDeleteConfirmation, page, row }}
          />
        </div>
        {/* ) : null} */}
      </TableCell>
    </TableRow>
  );
};
export default AddSpeakerVideo;
