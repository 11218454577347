import React, { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// preview header
import MobileSignal from "../../../Asset/images/icons/MobileSignal.svg";
import Wifi from "../../../Asset/images/icons/Wifi.svg";
import Battery from "../../../Asset/images/icons/Battery.svg";

import preview_video_thumbnail from "../../../Asset/images/speakerVideo/preview_video_thumbnail.png";
import bookmarkIcon from "../../../Asset/images/icons/bookmarkIcon.svg";
import shareIcon from "../../../Asset/images/icons/shareIcon.svg";
import thumbsUp from "../../../Asset/images/icons/thumbsUp.svg";
import studyListImg from "../../../Asset/images/casestudy/studyListImg.png";
import profilephoto from "../../../Asset/images/casestudy/profile photo.png";
import backArrow from "../../../Asset/images/icons/backArrow.svg";
import ssLogoBPP from "../../../Asset/images/ssLogoBPP.png";
import searchIcon from "../../../Asset/images/icons/searchIcon.svg";
import eventCalender from "../../../Asset/images/icons/eventCalender.svg";
// import preview_video_thumbnail2 from "../../../Asset/images/speakerVideo/preview_video_thumbnail.svg"
import "../../BestPeoplePractices/bestPeople.scss";
import "../events.scss";

import eventList from "../../../Asset/images/event/eventList.png";
const PreviewAddEvent = ({
  setOpenPreviewCaseStudy,
  addEvent,
  setOpenEventPreview,
  previewValues,
  editEvent,
  setOpenEditEvent,
  formattedDatePreview,
  DateFomat,
  TimeFomat
}) => {
  useEffect(() => {
    console.log("events inside preview", previewValues);
  }, []);

  return (
    <div className="preview_case_study_main ">
      <h6 className="heading_preview_mode">Preview Mode </h6>
      <div className="row">
        <div className="offset-lg-1 col-lg-4">
          <div className="speaker_video_list_container custom_for_all_heights">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <div className="preview_HeadingParaTringle custom_Eventpreview_Heading_container">
              <h1>Events</h1>
              <button>
                <img src={searchIcon} alt="" />
              </button>
            </div>

            <div className="upcoming_completed_container">
              <button>Upcoming</button>
              <button>Completed</button>
            </div>

            <div className="live_virtual_button_container">
              <button>Live</button>
              <button>Virtual</button>
            </div>
            <div className="custom_event_list_container">
              <Skeleton variant="rounded" width={100} height={80} />
              <div className="event_list_detail">
                <Skeleton variant="rectangular" width={50} height={15} />
                <Skeleton variant="rectangular" width={100} height={20} />
                <Skeleton variant="rectangular" width={150} height={15} />
              </div>
            </div>
            <hr />
            <div className="custom_event_list_container">
              <img src={eventList} alt="" />
              <div className="event_list_detail">
                <p>
                  {previewValues?.type}{" "}
                  <span>
                    {" "}
                    {(previewValues?.isBec === true && "BEC") } </span>
                    <span> { (previewValues?.isCertified === true && "Certified") }</span>
                    <span> {(previewValues?.isGcc === true && "GCC") }</span>
                    <span> {(previewValues?.isPremium === true && "Premium")}</span>
                 
                </p>
                <h6>{previewValues?.title}</h6>
                <p>{DateFomat}</p>
                {/* <p>August 01,2022 | 10:00</p> */}
              </div>
            </div>
            <hr />
            <div className="custom_event_list_container">
              <Skeleton variant="rounded" width={100} height={80} />
              <div className="event_list_detail">
                <Skeleton variant="rectangular" width={50} height={15} />
                <Skeleton variant="rectangular" width={100} height={20} />
                <Skeleton variant="rectangular" width={150} height={15} />
              </div>
            </div>
            <hr />
            <div className="custom_event_list_container">
              <Skeleton variant="rounded" width={100} height={80} />
              <div className="event_list_detail">
                <Skeleton variant="rectangular" width={50} height={15} />
                <Skeleton variant="rectangular" width={100} height={20} />
                <Skeleton variant="rectangular" width={150} height={15} />
              </div>
            </div>
            <hr />
            <div className="speaker_video_footer">
              <div className="Video_action_icon_container">
                <img src={thumbsUp} alt="" />
                <img src={bookmarkIcon} alt="" />
                <img src={shareIcon} alt="" />
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bottom_black_line"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="offset-lg-2 col-lg-4 ">
          <div className="speaker_video_list_container custom_speaker_video_pageHeight case_study_detail_preview custom_for_all_heights">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <div className="logo_name_type_container">
              <div className="event_name_calenderIcon_video_container">
                <div className="event_name_calenderIcon_container">
                  <h1>{previewValues?.title}</h1>
                  <img src={eventCalender} alt="" />
                </div>
                <img
                  className="event_preview_video"
                  src={previewValues?.imageValues?.url}
                  alt=""
                />
              </div>

              <div className="industry_type_area_container">
                <div>
                  <span>Date</span>
                  <h5> {formattedDatePreview}</h5>
                </div>
                <div className="for_border_right"></div>
                <div>
                  <span>time</span>
                  <h5>{TimeFomat}</h5>
                </div>
                <div className="for_border_right"></div>
                <div>
                  <span>Location</span>
                  <h5>{previewValues?.location}</h5>
                </div>
              </div>
            </div>
            <div className="BPP_preview_title_para">
              {previewValues?.description}
            </div>

            <div className="speaker_video_footer">
              <div className="Video_action_icon_container custom_book_know_buton_container">
                <button>Book now</button>
                <button>Know more</button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bottom_black_line"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="publish_close_button_container">
          <button
            onClick={() => {
              if (previewValues.modalType == "add") {
                addEvent();
              } else {
                editEvent();
              }
              setOpenEventPreview(false);
            }}
          >
            Publish
          </button>
          <button
            onClick={() => {
              if (previewValues.modalType == "add") {
                setOpenEventPreview(false);
              } else {
                setOpenEventPreview(false);
                setOpenEditEvent(true);
              }
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewAddEvent;
