import React, { useState, useEffect } from "react";
import "../../../UICommon/Modal/Industry-Type-modal/EditIndustryType-Modal.scss";
import Apilib from "../../../Api";
import "../../../pages/CaseStudy/casestudy.scss";

const AddAuthor = (props) => {
  const API = new Apilib();
  console.log(props, "select speaker");

  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className="select_main_container">
      <h1 className="modal_heading">Add Author</h1>
      <div className="Add_Speaker_box_border">
        <div class="form-group">
          <input
            className="ADD_category_input"
            type="text"
            placeholder="Add Author Name"
            name="categoryInput"
            value={text}
            onChange={handleChange}
          />
          {/* <label htmlFor="">Author Photo/Company Icon</label>
          <label className="custom_audio" htmlFor="Audio">
            {" "}
            <img src={importPhotoIcon} alt="" />
          </label>
          <input
            className="form-control"
            type="file"
            id="Audio"
            placeholder=""
          /> */}
        </div>
        <button
          style={{ marginTop: "1rem" }}
          className="submitModalButton"
          onClick={() => {
            props?.addAuthor(text);
            setText("");
            props?.setShowAddAuthor(false);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddAuthor;
