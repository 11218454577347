import React,{Fragment} from "react";
import Lottie from 'react-lottie';
import "../Asset/scss/uiCommon/splashScreen.scss"
import animationData from "../Asset/SplashScreen/SplashScreenAnimation.json"
const SplashScreen = () =>{
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return<Fragment>
      <div className="splashContainer">
    <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
      </div>
  </Fragment>

}
export default SplashScreen;