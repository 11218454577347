import React, { useState, useEffect } from "react";
import "./AddInterestArea-Modal.scss";
import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import ApiLib from "../../../Api";
import { notify, notifyTypes } from "../../../Utils";
const AddInterestAreaModal = (props) => {
  const API = new ApiLib();
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setText(event.target.value);
  };

  const addCategory = () => {
    // event.preventDefault();
    // const _newCategory = event.target["categoryInput"].value;
    setCategories([...new Set([...categories, text])]);
    setText("");
    // console.log(categories);
  };

  const removeCategory = (category) => {
    const _newCategories = [];
    categories.map((existingcategory) => {
      if (existingcategory != category) {
        _newCategories.push(existingcategory);
      }
    });
    setCategories(_newCategories);
  };

  const validateRequest = () => {
    var isValid = true;
    if (categories?.length == 0 ) {
      isValid = false;
      setError("Please add Interest Area");
    } else {
      setError("");
    }

    if (isValid) {
      addInterests();
    }
  };
  const addInterests = () => {
    var interestX = [];
    for (let index = 0; index < categories.length; index++) {
      var obj = {
        name: categories[index],
      };
      interestX.push(obj);
    }
    var data = {
      bulk: true,
      interests: interestX,
    };
    API.addInterest(data)
      .then((res) => {
        console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Interest Added Successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        
        notify(notifyTypes?.error, `${e.response.data.message}`);
        // notify(notifyTypes?.error, "Failed to Add Interest");
      })
      .finally((e) => {
        props.onAdded();
        // setLoading(false);
      });
  };

  return (
    <div>
      <h1 className="modal_heading">Add Interest Area</h1>
      <div>
        <p className="ModalPara">Add a Interest Area and press enter</p>
        {/* <form onSubmit={addCategory}> */}
        <input
          className="ADD_category_input"
          type="text"
          placeholder="Add a Interest Area"
          name="categoryInput"
          value={text}
          onChange={handleChange}
          onKeyPress={(event) => {
            if (event.key === "Enter" && text != "") {
              addCategory();
            }
          }}
        />
        <div
          style={{
            color: "red",
          }}
        >
          {error}
        </div>

        <div className="category_container">
          {categories.map((category) => (
            <div key={category} className="category_tile">
              {" "}
              <p className="CategoryName"> {category} </p>
              <button
                type="button"
                className="remove_category "
                onClick={() => removeCategory(category)}
              >
                {" "}
                <img src={closeIcon} alt="" />{" "}
              </button>
            </div>
          ))}
        </div>

        <button
          onClick={() => {
            validateRequest();
          }}
          className="submitModalButton"
          type="submit"
        >
          Save
        </button>
        {/* </form> */}
      </div>
    </div>
  );
};

export default AddInterestAreaModal;
