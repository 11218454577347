

import React from "react";

const DropDown = ({ contents, setId }) => {
  const handleSelect = (e) => {
    setId(e.target.value);
  };

  return (
    <select onChange={handleSelect}>
      {contents.map((content) => (
        <option value={content._id} key={content._id}>
          {content.title}
        </option>
      ))}
    </select>
  );
};

export default DropDown;