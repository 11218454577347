import React from 'react'
import "./Add-ModalContainer.scss"
import closeIcon from "../../../Asset/images/icons/closeIcon.svg"
const AddModalContainer = ({children,isModalOpen,setIsModalOpen}) => {
    const closeModel = () =>{
        setIsModalOpen(false)
    }
    return (
        <div className="Main_Modal_container">
            <div className="modal_container">
                <button className='CloseButton' onClick={closeModel}><img src={closeIcon} alt="" /></button> 
               {children}
            </div>
        </div>
    )
}

export default AddModalContainer;
