import React, { useState, useRef, useEffect } from "react";
import AddSpeakerVideoModalcontainer from "../../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import ChipComponent from "../ChipComponent";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import SelectSpeaker from "../../../UICommon/DropDowns/SelectSpeaker";

// avatar
import svg4 from "../../../Asset/images/avatars/4.svg";
import Apilib from "../../../Api";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

// import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";

const AddCommunityGroupModal = (
  props,
  { setShowPreview, setOpenAddCommunity }
) => {
  const API = new Apilib();
  const imageref = useRef();
  const [values, setValues] = useState({
    name: "",
    description: "",
    interests: [],
    industries: [],
    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    userLevel: [],
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Community Name is required.";
    }

    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }

    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {
      // valuesX.userLevel = ['none'];

      // ****** KEEPING PREMIUM & CERTIFIED COMMENTTED FOR NOW ****** //
      // valuesX.userLevel = ['free', 'non-certified'];
      // if (valuesX.isPremium && !valuesX.userLevel.includes("premium"))  // If premium and does not incl it.
      //   valuesX.userLevel.push("premium");
      // // if (!valuesX.isPremium && !valuesX.userLevel.includes("free"))
      // //   valuesX.userLevel.push("free");
      // if (valuesX.isCertified && !valuesX.userLevel.includes("certified")) // If certified and does not incl.
      //   valuesX.userLevel.push("certified");
      // ****** KEEPING PREMIUM & CERTIFIED COMMENTTED FOR NOW ****** //

      if (valuesX.isGcc && !valuesX.userLevel.includes("GCC"))
        valuesX.userLevel.push("GCC");
      if (valuesX.isBec && !valuesX.userLevel.includes("BEC"))
        valuesX.userLevel.push("BEC");
      if (
        !valuesX.isGcc &&
        !valuesX.isBec &&
        !valuesX.userLevel.includes("none")
      )
        valuesX.userLevel.push("none");

      console.log(valuesX, "scheduledTime ");
      const finalData = {
        name: valuesX.name,
        description: valuesX.description,
        interests: valuesX.interests,
        industries: valuesX.industries,
        imageValues: valuesX.imageValues,
        userLevel: valuesX.userLevel,
      };

      console.log("Before creating community");
      console.log(finalData);
      finalData?.industries.forEach((ele) => {
        delete ele?.createdBy;
        delete ele?.isActive;
        delete ele?.createdAt;
        delete ele?.updatedAt;
        delete ele?.userMade;
        delete ele?.__v;
      });

      finalData?.interests.forEach((ele) => {
        delete ele?.isActive;
        delete ele?.createdAt;
        delete ele?.updatedAt;
        delete ele?.createdBy;
        delete ele?.__v;
        delete ele?.userMade;
      });

      console.log(finalData);

      props.createCommunity(finalData);
      // props?.setShowPreview(valuesX);

      setValues({
        name: "",
        description: "",
        interests: [],
        industries: [],
        imageValues: {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
        userLevel: [],
      });

      setErrors({
        name: "",
      });
    }
  };

  const onImageChange = (e) => {
    console.log(e?.target?.files);
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        imageref.current.value = null;
      }
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#002566",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <div className="add_speaker_video_main">
      <div className="speaker_video_head">
        <h1 style={styles.headingCommunity}>Add Community Group</h1>
      </div>

      <div className="body_add_speaker">
        <div className="Add_speaker_video_form">
          <div className="row">
            <div className="col-lg-6">
              <div class="form-group">
                <label htmlFor="">Community Name</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Community Name"
                  name="name"
                  value={values?.name}
                  onChange={handleChangeValues}
                />
                <div className="error-text">{errors?.name}</div>
              </div>

              <div class="form-group">
                <label htmlFor="">Community Description</label>
                <textarea
                  class="form-control"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                  placeholder="Type here..."
                  name="description"
                  value={values?.description}
                  onChange={handleChangeValues}
                ></textarea>
                <div className="error-text">{errors?.description}</div>
              </div>
            </div>

            <div className="col-lg-6">
              <h6 className="setting_heading">Settings</h6>

              {/* KEEPING PREMIUM & CERTIFIED COMMENTTED FOR NOW */}
              {/* <div className="member_free_button_container">
                <p className="member_type">Member type</p>
                <p className="free_premium_container">
                  <span className="free_Font">Free</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isPremium}
                          onChange={() => {
                            setValues({
                              ...values,
                              isPremium: !values?.isPremium,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Premium</span>
                </p>
              </div> */}
              {/* <div className="member_free_button_container">
                <p className="member_type">Certification</p>
                <p className="free_premium_container">
                  <span className="free_Font">Non-Certified</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isCertified}
                          onChange={() => {
                            setValues({
                              ...values,
                              isCertified: !values?.isCertified,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Certified</span>
                </p>
              </div> */}
              {/* KEEPING PREMIUM & CERTIFIED COMMENTTED FOR NOW */}

              <div className="member_free_button_container">
                <p className="member_type">GCC</p>
                <p className="free_premium_container">
                  <span className="free_Font">No</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isGcc}
                          onChange={() => {
                            if (values?.isBec && !values?.isGcc) {
                              setValues({
                                ...values,
                                isBec: false,
                                isGcc: !values?.isGcc,
                              });
                            } else if (!values?.isBec && !values?.isGcc) {
                              setValues({
                                ...values,
                                isBec: false,
                                isGcc: !values?.isGcc,
                              });
                            } else {
                              setValues({ ...values, isGcc: !values?.isGcc });
                            }

                            // ***** KEEPING IT COMMENTTED FOR NOW ***** //
                            // setValues({
                            //   ...values,
                            //   isGcc: !values?.isGcc,
                            // });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Yes</span>
                </p>
              </div>

              <div className="form-group member_free_button_container">
                <p className="member_type">BEC</p>
                <p className="free_premium_container">
                  <span className="free_Font">No</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isBec}
                          onChange={() => {
                            // LOGIC HANDLES BEC & GCC are MUTUALLY EXCLUSIVE
                            if (values?.isGcc && !values?.isBec) {
                              setValues({
                                ...values,
                                isGcc: false,
                                isBec: !values?.isBec,
                              });
                            } else if (!values?.isBec && !values?.isGcc) {
                              setValues({
                                ...values,
                                isGcc: false,
                                isBec: !values?.isGcc,
                              });
                            } else {
                              setValues({ ...values, isBec: !values?.isBec });
                            }

                            // *** KEEPING BEC COMMENTTED FOR NOW ***
                            // setValues({
                            //   ...values,
                            //   isBec: !values?.isBec,
                            // });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Yes</span>
                </p>
              </div>

              <div class="form-group">
                <label>Community Logo Upload (1080x1080px)</label>
                <br />
                <span style={{ fontSize: "10px", color: "#002566" }}>
                  {" "}
                  (Accepted format is .jpg, .png, .jpeg and size 350-400kb)
                </span>
                {values?.imageValues?.loaded ? (
                  <div>
                    <img
                      style={{
                        height: 250,
                        width: "100%",
                      }}
                      src={values?.imageValues?.url}
                    />
                    <br></br>
                    <button
                      onClick={() => {
                        setValues({
                          ...values,
                          imageValues: {
                            loaded: false,
                            url: "",
                          },
                        });
                      }}
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div className="upload_photo_container">
                    <img src={importPhotoIcon} alt="" />
                    <p>
                      Drag and Drop or{" "}
                      <label htmlFor="AddPhoto"> Browse </label> to upload
                    </p>
                  </div>
                )}
                <div className="error-text">{errors?.imageValues}</div>
                <input
                  type="file"
                  class="form-control"
                  id="AddPhoto"
                  placeholder="Name"
                  style={{ display: "none" }}
                  ref={imageref}
                  onChange={onImageChange}
                />
              </div>

              <div class="form-group">
                <label htmlFor="">Interest area</label>
                <InterestAreaDropDown
                  selectedData={values?.interests}
                  onSelectChange={(data) => {
                    // console.log(data, "data");
                    setValues({
                      ...values,
                      interests: [...data],
                    });
                  }}
                />
                <div className="error-text">{errors?.interests}</div>
              </div>

              <div class="form-group">
                <label htmlFor="">Industry type</label>
                <IndustryTypeDropDown
                  selectedData={values?.industries}
                  onSelectChange={(data) => {
                    setValues({
                      ...values,
                      industries: [...data],
                    });
                  }}
                />
                <div className="error-text">{errors?.industries}</div>
              </div>
            </div>
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Submit
            </button>
            <button onClick={() => props?.setOpenAddCommunity(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCommunityGroupModal;

const styles = {
  headingCommunity: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#3f434a",
    marginBottom: 0,
  },
};
