import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";

import ModalContainer from "../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";
import AddIndustryTypeModal from "../../UICommon/Modal/Industry-Type-modal/AddIndustryTypeModal";
import EditIndustryTypeModal from "../../UICommon/Modal/Industry-Type-modal/EditIndustryTypeModal";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import CertifiedNonCertified from "../../UICommon/Certified-NonCertified/CertifiedNonCertified";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";
import "../CaseStudy/casestudy.scss";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import AddCaseStudy from "./Modals/AddResearchPaper";
import PreviewCaseStudy from "./Modals/PreviewResearchPaper";
import AddResearchPaper from "./Modals/AddResearchPaper";
import EditResearchPaper from "./Modals/EditResearchPaper";
import PreviewResearchPaper from "./Modals/PreviewResearchPaper";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

import Apilib from "../../Api";
import { getDateString, notify, notifyTypes, getDateString_IST } from "../../Utils";
import "./researchPaper.scss";
const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Disabled </button>
);

function descendingComparator(a, b, orderBy) {
  // console.log('b:', b, '==========', 'a:', a)
  console.log("orderBy", orderBy);

  // console.log('b[orderBy]:', b[orderBy], '==========', 'a[orderBy]:', a[orderBy])
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => {
      // console.log(a, 'first', b)
      return descendingComparator(a, b, orderBy);
    }
    : (a, b) => {
      // console.log(a, 'second', b)
      return -descendingComparator(a, b, orderBy);
    };
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  console.log(stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Thumbnail",
    numeric: false,
    disablePadding: false,
    label: "Thumbnail",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
    enableSorting: true,
  },
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Authors",
  },
  {
    id: "industry",
    numeric: true,
    disablePadding: false,
    label: "Industry",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created on ",
    enableSorting: true,
  },
  {
    id: "scheduledTime",
    numeric: true,
    disablePadding: false,
    label: "Publish Date",
    sortingEnabled: false,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "status",
    enableSorting: true,
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <Box
                    className="SortArrow"
                    component="span"
                    sx={visuallyHidden}
                    style={{ display: "none" }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const ResearchPaper = () => {
  const API = new Apilib();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioFileDeleted, setAudioFileDeleted] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const [deleteID, setDeleteID] = useState(null);

  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [previewValues, setPreviewValues] = useState({});

  useEffect(() => {
    console.log("ORDER:", order);
    console.log("ORDER-BY:", orderBy);
  }, [order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  const needLength = Math.ceil(rows.length / rowsPerPage);
  const [showMoreAction, setShowMoreAction] = useState(false);

  const openMoreMenuHandler = (name) => {
    console.log(name);
    setShowMoreAction((showMoreAction) => !showMoreAction);
  };
  const [openAddCaseStudy, setOpenCaseStudy] = useState(false);
  const openAddCaseStudyModelHandler = (event) => {
    setOpenCaseStudy(true);
  };
  const [openPreviewCaseStudy, setOpenPreviewCaseStudy] = useState(false);
  const openAddUserModelHandler = () => {
    setOpenCaseStudy(true);
  };

  useEffect(() => {
    getResearchPapers();
    setOrder("desc");
    setOrderBy('createdAt');
  }, []);

  const getResearchPapers = (text) => {
    API.getResearchPapers(text)
      .then((res) => {
        // console.log("get interest areas", res?.data?.interests);
        setRows(res?.data?.papers);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const deleteResearchPaper = (id) => {
    console.log("inside delete research paper");
    API.deleteResearchPaper(id)
      .then((res) => {
        console.log("delete Research Paper ", res?.data);
        notify(notifyTypes?.success, "Deleted Research Paper Successfully.");
        getResearchPapers();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Research Paper.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editResearchPaper(id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        getResearchPapers(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };
  const addResearchPaper = () => {
    var data = {
      ...previewValues,
    };
    if (audioFileDeleted)
      data.audio = "";
    API.addResearchPaper(data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        // console.log(res,"pushpak")
        notify(notifyTypes?.success, "Research Paper Added successfully");
        uploadMedia(res?.data?.researchpaper?._id);
        uploadMediaCoverImage(res?.data?.researchpaper?._id);

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to add Research Paper Added");
      })
      .finally((e) => {
        getResearchPapers(searchValue);
        setOpenPreviewCaseStudy(false);
        setOpenCaseStudy(false);
        // props?.onEdited();
        // setLoading(false);
      });
  };
  const editResearchPaper = () => {
    var data = {
      ...previewValues,
    };
    if (audioFileDeleted)
      data.audio = "";
    delete data.authors;
    API.editResearchPaper(selectedRow?._id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");
        uploadMedia(res?.data?.researchpaper?._id);
        uploadMediaCoverImage(res?.data?.researchpaper?._id);

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        getResearchPapers(searchValue);
        setOpenPreviewCaseStudy(false);
        setOpenEditModel(false);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const uploadMedia = (id) => {
    if (previewValues?.authorImageValues?.source) {
      let mediaData = new FormData();
      mediaData.append("authors", JSON.stringify(previewValues?.authors));

      for (let i = 0; i < previewValues?.authors.length; i++) {
        mediaData.append("authorImages", previewValues?.authorImageValues?.source);
      }

      console.log("form data :::::::::::: ", Object.fromEntries(mediaData));

      API.uploadResearchPaperMedia(mediaData, id)
        .then((res) => {
          console.log("add authors research paper response", res?.data);
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        })
        .finally((e) => {
          getResearchPapers(searchValue);
          setOpenPreviewCaseStudy(false);
        });
    }
  };

  const uploadMediaCoverImage = (id) => {
    console.log("inside upload media cover image");
    let mediaData = new FormData();
    let makeRequest = false;
    if (previewValues?.imageValues?.source) {
      mediaData.append("coverImage", previewValues?.imageValues?.source);
      makeRequest = true;
    }

    if (previewValues?.audioValues?.source) {
      console.log("adding audio -----------------> ");
      mediaData.append("audio", previewValues?.audioValues?.source);
      makeRequest = true;
    }

    if (previewValues?.researchPaper?.source) {
      mediaData.append("researchPaper", previewValues?.researchPaper?.source);
      makeRequest = true;
    }

    if (makeRequest == true) {
      API.uploadResearchPaperMedia(mediaData, id)
        .then((res) => {
          console.log("add cover image research paper response", res?.data);
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        })
        .finally((e) => {
          getResearchPapers(searchValue);
          setOpenPreviewCaseStudy(false);
        });
    }
  };

  return (
    <>
      {/* <TableHeadingContainer
                {...{ isModalOpen, setIsModalOpen }}
                Heading="Case Study"
            /> */}
      <div className="Page_heading_container Page_heading_SpeakerVideo">
        <h1>Research Paper</h1>
        <button
          onClick={openAddCaseStudyModelHandler}
          className="Add_Head_Button"
        >
          <img src={AddIcon} alt="" />
        </button>
      </div>
      {/* <CertifiedNonCertified /> */}

      {openAddCaseStudy ? (
        <AddSpeakerVideoModalcontainer>
          <AddResearchPaper
            setAudioFileDeleted={setAudioFileDeleted}
            setShowPreview={(values) => {
              // console.log("abhishelk");
              setPreviewValues({
                ...values,
                type: "add",
              });
              setOpenPreviewCaseStudy(true);
            }}
            setOpenAddVideo={() => {
              setOpenCaseStudy(false);
            }}
            {...{
              openPreviewCaseStudy,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}
      {openEditModel ? (
        <AddSpeakerVideoModalcontainer>
          <EditResearchPaper
            setAudioFileDeleted={setAudioFileDeleted}
            data={selectedRow}
            id={selectedRow?._id}
            setShowPreview={(values) => {
              // console.log("abhishelk");
              setPreviewValues({
                ...values,
                type: "edit",
              });
              setOpenPreviewCaseStudy(true);
            }}
            setOpenAddVideo={() => {
              setOpenEditModel(false);
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}
      {openPreviewCaseStudy ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewResearchPaper
            data={previewValues}
            onPublish={() => {
              if (previewValues?.type == "add") {
                addResearchPaper();
              } else {
                editResearchPaper();
              }
            }}
            {...{ setOpenPreviewCaseStudy }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deleteResearchPaper(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {isModalOpen ? (
            <ModalContainer {...{ isModalOpen, setIsModalOpen }}>
              <AddIndustryTypeModal />
              {/* <EditInterestAreaModal /> */}
            </ModalContainer>
          ) : null}

          <SearchBarNActionButtonContainer
            onTextChange={(text) => {
              console.log(text);
              setPage(1);
              setSearchValue(text);
              getResearchPapers(text);
            }}
            {...{ setOpenDeleteConfirmation }}
            className="codered"
          />

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRowComponent
                        key={row?._id}
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setSelectedRow,
                          setDeleteID,
                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
        {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowMoreActionForID(null);
      }
    };
    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row?._id);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          className="custom_table_checkbox"
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell align="left">
        {row?.coverImage == null ? (
          "N/A"
        ) : (
          <img
            src={row?.coverImage}
            style={{
              height: 100,
              width: 100,
            }}
          />
        )}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        {row.title}
      </TableCell>

      {/* temp remove audio */}
      {/* <TableCell align="left">
        {row.audio == null ? (
          "N/A"
        ) : (
          <audio controls>
            <source src={row?.audio} type="audio/ogg" />
          </audio>
        )}
      </TableCell> */}
      <TableCell align="left">
        {row?.authors?.length > 1
          ? `${row?.authors[0]?.name}, +${row?.authors?.length - 1} more`
          : row?.authors?.length == 1
            ? row?.authors[0]?.name
            : "N/A"}
      </TableCell>
      <TableCell align="left">
        {row?.industries?.length > 1
          ? `${row?.industries[0]?.name}, +${row?.industries?.length - 1} more`
          : row?.industries?.length == 1
            ? row?.industries[0]?.name
            : "N/A"}
      </TableCell>

      <TableCell align="left">{getDateString(row?.createdAt)}</TableCell>
      <TableCell align="left">{row?.scheduledTime ? getDateString_IST(row?.scheduledTime) : 'N/A'}</TableCell>
      <TableCell align="left">
        {row?.isActive ? (
          <button className="Active_statusButton"> Active </button>
        ) : (
          <button className="Disabled_statusButton"> InActive </button>
        )}
      </TableCell>

      <TableCell id={labelId} align="left">
        {" "}
        <div ref={ref}>
          <button className="more_option_manu" onClick={openMoreMenuHandler}>
            <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
          </button>
          {/* {showMoreActionForID === row?._id ? ( */}
          <ThreeDotOption
            show={showMoreActionForID === row._id}
            isActive={row?.isActive}
            changeActiveStatus={(status) => {
              console.log("status", status);
              changeActiveStatus(status, row?._id);
            }}
            setOpenEditModel={() => {
              setShowMoreActionForID(null);
              setOpenEditModel(true);
              setSelectedRow(row);
            }}
            setDeleteID={() => {
              setDeleteID(row?._id);
            }}
            {...{ setOpenDeleteConfirmation }}
          />
        </div>
        {/* ) : null} */}
      </TableCell>
    </TableRow>
  );
};
export default ResearchPaper;
