import React, { useState, useEffect } from "react";

import "../../Asset/scss/MainComponents/Header.scss";

import SearchIcon from "../../Asset/images/Header/SearchIcon.svg";
import BellNotification from "../../Asset/images/Header/BellNotification.svg";
import Avatar from "../../Asset/images/Header/avatar.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
const Header = () => {
  const [adminDetails, setAdminDetails] = useState(null);
  useEffect(() => {
    try {
      const details = JSON.parse(localStorage?.adminDetail);
      setAdminDetails(details);
    } catch (error) { }
  }, []);
  return (
    <div className="MainHeader2">
      <div className="HeaderContainer">
        {/* <div className="SerachNotificationContainer">
          <button className="serchicon">
            <img src={SearchIcon} alt="searchIcon" />
          </button>
          <button className="notification">
            <img src={BellNotification} alt="notification bell" />
          </button>
        </div> */}
        <div className="midblackLine"></div>
        <div className="profile_manage">
          <img src={Avatar} alt="" />
          {/* <h6>{adminDetails && adminDetails?.name}</h6> */}
          {/* <ArrowDropDownIcon /> */}
          <NavDropdown
            title={adminDetails && adminDetails?.name}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("adminDetail");
                localStorage.removeItem("userId");
                localStorage.removeItem("loginResponse");
                console.log('Success');
              }}
              href="/"
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
