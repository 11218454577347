import React from "react";
import "../../../UICommon/Modal/Interest-Area-modal/Add-ModalContainer.scss";
const PollContainerModal = ({
  children,
  openAddVideo,
  setOpenAddVideo,
  classShort,
}) => {
  const closeModel = () => {
    setOpenAddVideo(false);
  };
  return (
    <div className="">
      <div
        className={`modal_container ${classShort}`}
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          // overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};
export default PollContainerModal;
