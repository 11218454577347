import React, { useEffect, useRef, useState } from "react";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import "../casestudy.scss";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";
import { Checkbox } from "@mui/material";
import Apilib from "../../../Api";
import { getDateString, notify, notifyTypes } from "../../../Utils";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const generateRandomNumber = () => {
  const min = 10000;
  const max = 99999;
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const AddResearchPaper = (props) => {
  const API = new Apilib();
  const [organizations, setOrganizations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    organization: {},
    industry: {},
    // imageValues: {
    //   loaded: false,
    //   url: "",
    //   type: "",
    //   name: "",
    //   source: "",
    // },
    isGcc: false,
    isBec: false,
    club: "none",
    isPremium: false,
    designation: "",
    dob: "",
    membershipExpiresAt: "",
  });

  const [otherOrg, setOtherOrg] = useState("");

  const [selectOrg, setSelectOrg] = useState({});

  const [dob, setDob] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);


  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    organization: "",
    imageValues: "",
  });

  useEffect(() => {
    getAllOrganizations();
    getAllIndustries();
    getSpecificUser();
  }, []);

  useEffect(() => {
    var isGcc = false;
    var isBec = false;

    if (props.values?.club == "none") {
    } else if (props.values?.club == "BEC") isBec = true;
    else if (props.values?.club == "GCC") isGcc = true;

    setValues({
      ...props?.values,
      isGcc,
      isBec,
      mobile: props?.values?.mobile?.number,
      organization: props?.values?.organization?.client_id,
      industry: props?.values?.industry?._id,
    });

    setSelectOrg(props?.values?.organization);

    console.log("inside edit modal : ", props.values);
  }, [props?.openEditModel]);

  // useEffect(() => {
  //   setValues({...values});
  // }, [organizations]);

  const getSpecificUser = () => {
    setValues({
      ...props?.values,
      mobile: props?.values?.mobile?.number,
      organization: props?.values?.organization?.client_id,
      industry: props?.values?.industry?._id,
    });
    setOtherOrg(props?.values?.organization?.client_name);
    setDob(dayjs(props.values.dob));
    setExpiryDate(dayjs(props.values.membershipExpiresAt));
  };
  const getAllOrganizations = () => {
    API.getAllOrganizations()
      .then((response) => {
        // console.log("get all orgs", response?.data?.organizations);
        let orgs = response?.data?.organizations;
        orgs.unshift({ client_name: "Other", client_id: "11111" });
        console.log("get all orgs", orgs);
        setOrganizations(orgs);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getAllIndustries = () => {
    API.getIndustries()
      .then((res) => {
        // console.log("get industry s", res?.data?.industrys);
        setIndustries(res?.data?.industries);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const handleChangeValues = (event) => {
    console.log(event?.target?.value);
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    // console.log(valuesX?.imageValues);
    // if (valuesX?.imageValues?.loaded == false) {
    //   isValidForm = false;
    //   errorsX.imageValues = "Thumbnail is required.";
    // }
    console.log(valuesX?.organization);
    if (valuesX?.firstname == "") {
      isValidForm = false;
      errorsX.firstname = "First Name is required.";
    }
    if (valuesX?.lastname == "") {
      isValidForm = false;
      errorsX.lastname = "Last Name is required.";
    }
    if (valuesX?.organization == "") {
      isValidForm = false;
      errorsX.organization = "Organization is required.";
    }
    if (valuesX?.mobile == "") {
      isValidForm = false;
      errorsX.mobile = "Phone Number is required.";
    }
    if (valuesX?.email == "") {
      isValidForm = false;
      errorsX.email = "Email is required.";
    }
    if (valuesX?.designation == "") {
      isValidForm = false;
      errorsX.designation = "Designation is required.";
    }
    // if (valuesX?.dob == "") {
    //   isValidForm = false;
    //   errorsX.dob = "DOB is required.";
    // }

    console.log(errorsX);
    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {
      console.log('^^^^^^^^ values', values);
      submitForm();
    }
  };
  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const submitForm = () => {
    var idx =
      industries[
      industries.findIndex((org, index) => org?._id == values?.industry)
      ];
    var club = "none";
    if (values?.isBec) club = "BEC";
    else if (values?.isGcc) club = "GCC";
    var data = {
      // ...values,
      firstname: values?.firstname,
      lastname: values?.lastname,
      email: values?.email,
      club: club,
      isPremium: values?.isPremium,
      industry: {
        _id: idx?._id,
        name: idx?.name,
      },
      mobile: {
        countryCode: "91",
        number: values?.mobile,
      },
      designation: values?.designation,
      dob: values?.dob,
      membershipExpiresAt: values?.membershipExpiresAt,
    };

    // CERTIFIED ORGANIZATIONS
    if (values?.organization != "11111") {
      data.organization =
        organizations[
        organizations.findIndex(
          (org, index) => org?.client_id == values?.organization
        )
        ];
    }
    // OTHER ORGANIZATION
    else {
      data.organization = {
        client_id: generateRandomNumber(),
        client_name: otherOrg,
      };
    }
    console.log("organization ----------", data?.organization);
    console.log("data ----------", data);

    API.editUser(props?.values?._id, data)
      .then((res) => {
        props?.setOpenEditModel(false);
        notify(notifyTypes?.success, "User Edited Successfully.");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Edit user.");
      })
      .finally((e) => {
        // setLoading(false);
        props.fetchUsers(props.searchValue);
      });
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const [publishOption, setPublishOption] = useState(false);
  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
    console.log("yooo");
  };
  return (
    <div
      className="add_case_study_main"
      style={{
        backgroundColor: "white",
        width: "40vw",
        padding: "1.5rem",
        borderRadius: "1rem",
        height: "50vw",
        overflowY: "scroll",
      }}
    >
      <div
        style={{ position: "relative" }}
        className="heading_publish_container"
      >
        <h1>Edit User</h1>
      </div>
      {/* <form className="form_add_case_study" action=""> */}
      <div className="form_add_case_study" action="">
        <div className="row">
          <div className="col-lg-12">
            <div class="form-group">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                class="form-control"
                id=""
                name="firstname"
                value={values?.firstname}
                onChange={handleChangeValues}
                placeholder="First Name"
              />
              <div className="error-text">{errors?.firstname}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                class="form-control"
                id=""
                name="lastname"
                value={values?.lastname}
                onChange={handleChangeValues}
                placeholder="Last Name"
              />
              <div className="error-text">{errors?.lastname}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Phone Number</label>
              <div className="mobile_no_main_container">
                <select
                  class="form-select phone_no_start_select"
                  aria-label="Default select example"
                >
                  <option selected>+91</option>
                  <option value="2">+92</option>
                  <option value="3">+93</option>
                </select>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  name="mobile"
                  value={values?.mobile}
                  onChange={handleChangeValues}
                  placeholder="99 9999 9999"
                  disabled
                />
              </div>
              <div className="error-text">{errors?.mobile}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Email ID</label>
              <input
                type="text"
                class="form-control"
                id=""
                name="email"
                value={values?.email}
                onChange={handleChangeValues}
                placeholder="Email ID"
                disabled
              />
              <div className="error-text">{errors?.email}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Designation</label>
              <input
                type="text"
                class="form-control"
                id=""
                name="designation"
                value={values?.designation}
                onChange={handleChangeValues}
                placeholder="Designation"
              />
              <div className="error-text">{errors?.designation}</div>
            </div>

            <div
              class="form-group"
              style={{ flexDirection: "column", display: "flex" }}
            >
              <label
                style={{
                  marginBottom: "10px",
                }}
                htmlFor=""
              >
                DOB
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dob}
                  onChange={(newDob) => {
                    setDob(newDob);
                    if (newDob && dayjs.isDayjs(newDob)) {
                      console.log("dayjs string", newDob.toISOString());
                      setValues({
                        ...values,
                        dob: newDob.toISOString(),
                      });
                    }
                    validateForm({
                      ...values,
                      dob: newDob.toISOString(),
                    }, false)
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                />
              </LocalizationProvider>
              <div className="error-text">{errors?.dob}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Organization Name</label>
              {organizations && (
                <Autocomplete
                  class=" phone_no_start_select"
                  options={organizations}
                  // freeSolo
                  getOptionLabel={(option) => option.client_name}
                  autoComplete
                  onChange={(event, newVal) => {
                    // IF NEW VALUE IS NULL THEN SET VALUES TO EMPTY STRING
                    if (newVal?.client_id) {
                      if (newVal) {
                        setSelectOrg(newVal);
                        setValues({
                          ...values,
                          organization: newVal.client_id
                        });
                        validateForm({
                          ...values,
                          organization: newVal.client_id
                        }, false);
                      }
                    }
                    // IF NEW VALUE IS NOT NULL THEN SET VALUES TO NEW VALUE
                    else {
                      setValues({
                        ...values,
                        organization: '',
                      });
                    }
                  }}
                  // onInputChange={(event, newVal) => {
                  //   setOtherOrg(newVal);
                  //   // console.log("Inside inputChange --> ", newVal);
                  // }}
                  value={selectOrg}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              )}
            </div>

            {values?.organization == "11111" && (
              <div class="form-group">
                <label htmlFor="">Organization Name</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  name="otherOrganization"
                  value={otherOrg}
                  onChange={(e) => {
                    setOtherOrg(e.target.value);
                    validateForm({ ...values });
                  }}
                  placeholder="Other organization name"
                />
                <div className="error-text">{errors?.organization}</div>
              </div>
            )}

            <div class="form-group">
              <label htmlFor="">Industry Type</label>
              <select
                name="industry"
                value={values?.industry}
                onChange={handleChangeValues}
                class="form-select phone_no_start_select"
                aria-label="Default select example"
              >
                <option></option>
                {industries?.map((org, index) => (
                  <option selected={values?._id == org?._id} value={org?._id}>
                    {org.name}
                  </option>
                ))}
              </select>
              {/* <input
                type="text"
                class="form-control"
                id=""
                placeholder="Organization Name"
              /> */}
              <div className="error-text">{errors?.industry}</div>
            </div>
            <div class="form-group">
              <div className="gcc_bec_main_container">
                <FormGroup className="custo_FormGroup">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.isGcc}
                        onChange={(e) => {
                          if (values?.isBec && !values?.isGcc) {
                            setValues({
                              ...values,
                              isBec: false,
                              isGcc: !values?.isGcc,
                            });
                            console.log("inside GCC change");
                          }
                          else if (!values?.isBec && !values?.isGcc) {
                            setValues({
                              ...values,
                              isBec: false,
                              isGcc: !values?.isGcc,
                              isPremium: true,
                            });
                          } else {
                            setValues({ ...values, isGcc: !values?.isGcc });
                          }
                        }}
                        className="custom_table_checkbox"
                      />
                    }
                    label="GCC"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.isBec}
                        onChange={(e) => {
                          if (values?.isGcc && !values?.isBec) {
                            setValues({
                              ...values,
                              isGcc: false,
                              isBec: !values?.isBec,
                            });
                            console.log("inside BEC change");
                          }
                          else if (!values?.isBec && !values?.isGcc) {
                            setValues({
                              ...values,
                              isGcc: false,
                              isBec: !values?.isGcc,
                              isPremium: true,
                            });
                          }
                          else {
                            setValues({ ...values, isBec: !values?.isBec });
                          }
                        }}
                        className="custom_table_checkbox"
                      />
                    }
                    label="BEC"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.isPremium}
                        onChange={(e) => {
                          if(values?.isBec || values?.isGcc) {
                            setValues({ ...values, isPremium: true });
                          }
                          else {
                            setValues({ ...values, isPremium: !values?.isPremium });
                          }
                        }}
                        className="custom_table_checkbox"
                      />
                    }
                    label="Premium"
                  />
                </FormGroup>
              </div>
            </div>
          </div>

          <div
            class="form-group"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <label
              style={{
                marginBottom: "10px",
              }}
              htmlFor=""
            >
              Expiry Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={expiryDate}
                onChange={(newDate) => {
                  setExpiryDate(newDate);
                  if (newDate && dayjs.isDayjs(newDate)) {
                    console.log("dayjs string", newDate.toISOString());
                    setValues({
                      ...values,
                      membershipExpiresAt: newDate.toISOString(),
                    });
                  }
                  validateForm({
                    ...values,
                    membershipExpiresAt: newDate.toISOString(),
                  }, false)
                }}
                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
              />
            </LocalizationProvider>
            <div className="error-text">{errors?.dob}</div>
          </div>

          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Submit
            </button>
            <button
              onClick={() => {
                props?.setOpenEditModel(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddResearchPaper;
