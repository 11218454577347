import React, { useState } from "react";
import HeadingTable from "./HeadingTable/HeadingTable";
import "./TableHeadingContainer.scss";
import downloadIcon from "../../Asset/images/interest Area/download.svg";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import MainModelContainer from "../Modal/Interest-Area-modal/Import-ModelContainer";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import inportplusicon from "../../Asset/images/icons/inportplusicon.svg";
import progresscloud from "../../Asset/images/icons/progress-cloud.svg";
import errorIcon from "../../Asset/images/icons/errorIcon.svg";
import righttickicon from "../../Asset/images/icons/right-tick-icon.svg";

const TableHeadingContainer = ({ Heading, isModalOpen, setIsModalOpen,setOpenDeleteConfirmation }) => {
  
  const [importOpen, setImportOpen] = useState(false);

  const openImportHandler = () => {
    setImportOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  ///////////////////////////////////////////////////////////////////////////
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  /////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{marginBottom:"2rem"}} className="table_heading_container">
      <HeadingTable Heading={Heading} />
      <div className="import_add_container">
        {/* <button onClick={openImportHandler} className="import_headin_button">
          {" "}
          <img src={downloadIcon} alt="" /> Import
        </button> */}

        <button onClick={openModal} className="Add_Head_Button">
          <img src={AddIcon} alt="" />
        </button>

        {importOpen ? (
          <MainModelContainer
            className="input_button_model"
            {...{ importOpen, setImportOpen }}
          >
            <div className="import_data_container">
              
              <h1 className="modal_heading">Import Data</h1>

              <div className="drap_n_drop_area">
                <img src={inportplusicon} alt="" />
                <p>
                  Drag and drop [file_name]<b>.xlsx</b>  file here or Click here to
                  <label htmlFor="tryme"> &nbsp;Browse </label> a file.
                  <input className="d-none" id="tryme" type="file" />
                </p>
              </div>

              <div className="input_modal_footer">
                <p>Plase check “Sample.xlsx” before uploading any data </p>
                <button>Download Sample</button>
              </div>
            </div>


            {/* <div className="import_data_container">
              <div className="progress_bar_container">
                <img src={progresscloud} alt="progresscloud" />
                <h4>
                  {" "}
                  <strong>10020</strong> Data found
                </h4>
                
                <Box sx={{ width: "100%" }}>
                  <LinearProgress className="progressBarColor" variant="determinate" value={progress} />
                </Box>
               
                <p>56% Uploaded</p>
              </div>
              <div className="import_cancel_container">
              <button className="import_cancel">Cancel</button>
              </div>
            </div> */}


            {/* <div className="import_data_container" >
                <div className="Error_message_container">
                    <img src={errorIcon} alt="" />
                    <h6>Error [422]</h6>
                    <p>An error occurred while uploading the data.
                        The file is either wrong, corrupted, or the format is improper.</p>
                    <p>Please check Sample sheet before uploading.</p>
                    <div className="Reupload_button_container">
                        <button className="import_Reupload">Reupload</button>
                    </div>
                </div>

            </div> */}


            {/* <div className="import_data_container">
              <div className="data_uploaded_container">
                <img src={righttickicon} alt="" />
                <h4>
                  {" "}
                  <strong>10020</strong> Data uploaded
                </h4>
                <div className="Done_button_container">
                  <button className="import_Done">Done</button>
                </div>
              </div>
            </div> */}


          </MainModelContainer>
        ) : null}
      </div>
    </div>
  );
};
export default TableHeadingContainer;
