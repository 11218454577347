import axios from "./Axios";

export default class Api {
  Login(data) {
    return axios.post("/admin/login", data);
  }

  ChangePassword() {
    return axios.put("/admin/changePassword", {
      headers: {
        Authorization: localStorage.accessToken,
      },
    });
  }

  getBlogs(searchValue = "") {
    var url = searchValue
      ? `/admin/blogs?title=${searchValue}`
      : "/admin/blogs";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpecificBlog(_id) {
    return axios.get(`/admin/blogs/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addBlogs(data) {
    return axios.post("/blogs/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteBlog(id) {
    return axios.delete(`/blogs/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editBlog(id, data) {
    return axios.put(`/blogs/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  uploadBlogMedia(id, data) {
    return axios.put(`/blogs/${id}/media`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
        "Content-Type": "multipart/form-dataContent",
      },
    });
  }

  getInterestArea(searchValue = "") {
    var url = searchValue
      ? `/admin/interests?name=${searchValue}`
      : "/admin/interests";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getInterestAreaActiveOnly(searchValue = "") {
    var url = searchValue
      ? `/admin/interests/active-only?name=${searchValue}`
      : "/admin/interests/active-only";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addInterest(data) {
    return axios.post("/admin/interests", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteInterest(id) {
    return axios.delete(`/admin/interests/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getIndustries(searchValue = "") {
    var url = searchValue
      ? `/admin/industries?name=${searchValue}`
      : "/admin/industries";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getIndustriesActiveOnly(searchValue = "") {
    var url = searchValue
      ? `/admin/industries/active?name=${searchValue}`
      : "/admin/industries/active";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteIndustry(id) {
    return axios.delete(`/admin/industry/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editIndustry(id, data) {
    return axios.put(`/admin/industry/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addIndustry(data) {
    return axios.post("/admin/industry", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editInterest(id, data) {
    return axios.put(`/admin/interests/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getPracticeArea(searchValue = "") {
    var url = searchValue
      ? `/admin/practiseAreas?name=${searchValue}`
      : "/admin/practiseAreas";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getPracticeAreaActiveOnly(searchValue = "") {
    var url = searchValue
      ? `/admin/practiseAreas/active?name=${searchValue}`
      : "/admin/practiseAreas/active";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpeakers(searchValue = "") {
    var url = searchValue
      ? `/admin/speaker?name=${searchValue}`
      : "/admin/speaker";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpeakerVideo(id) {
    return axios.get(`/admin/videos/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addPractiseArea(data) {
    return axios.post("/admin/practiseArea", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deletePractiseArea(id) {
    return axios.delete(`/admin/practiseArea/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editPractise(id, data) {
    return axios.put(`/admin/practiseArea/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpeakers(searchValue = "") {
    var url = searchValue
      ? `/admin/speaker?name=${searchValue}`
      : "/admin/speaker";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addSpeaker(data) {
    return axios.post("/admin/speaker", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editPractise(id, data) {
    return axios.put(`/admin/practiseArea/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteSpeaker(id) {
    return axios.delete(`/admin/speaker/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editSpeaker(id, data) {
    return axios.put(`/admin/speaker/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpeakerVideos(searchValue = "") {
    var url = searchValue
      ? `/admin/videos?title=${searchValue}`
      : "/admin/videos";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addSpeakerVideo(data) {
    return axios.post("/speakervideos/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  uploadSpeakerVideoMedia(data, id) {
    console.log("id>>>", id);
    return axios.post(`/speakervideos/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteSpeakerVideo(id) {
    return axios.delete(`/speakervideos/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editSpeakerVideo(id, data) {
    return axios.put(`/speakervideos/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getCaseStudies(searchValue = "") {
    var url = searchValue
      ? `/admin/casestudies?title=${searchValue}`
      : "/admin/casestudies";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addCaseStudies(data) {
    return axios.post("/casestudies", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getBestPeoplePratices(searchValue = "") {
    var url = searchValue ? `/admin/bpps?title=${searchValue}` : "/admin/bpps";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getBestPeoplePratice(id) {
    return axios.get(`/admin/bpps/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editInterest(id, data) {
    return axios.put(`/admin/interests/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteCaseStudy(id) {
    return axios.delete(`/casestudies/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editCaseStudy(id, data) {
    return axios.put(`/casestudies/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  uploadCaseStudyMedia(id, data) {
    return axios.put(`/casestudies/${id}/media`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  uploadCaseStudyContent(id, data) {
    return axios.post(`/casestudies/${id}/content`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editBestPeoplePractices(id, data) {
    return axios.put(`/bestpeoplepractises/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteBestPeoplePratices(id) {
    return axios.delete(`/bestpeoplepractises/${id}`, {
      // return axios.delete(`/bestpeoplepractises/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  addBestPeoplePratice(data) {
    return axios.post("/bestpeoplepractises/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  uploadBestPeoplePraticeMedia(data, id) {
    return axios.post(`/bestpeoplepractises/${id}/logo`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // research paper
  getResearchPapers(searchValue = "") {
    var url = searchValue
      ? `/admin/papers?title=${searchValue}`
      : "/admin/papers";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  addResearchPaper(data) {
    // REMOVE AUTTHORS KEY
    delete data["authors"];
    return axios.post("/researchpapers/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  getResearchPaper(id) {
    return axios.get(`/admin/papers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  editResearchPaper(id, data) {
    return axios.put(`/researchpapers/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  deleteResearchPaper(id) {
    // return axios.delete(`/researchpapers/${id}/${localStorage?.userId}`, {
    return axios.delete(`/researchpapers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  uploadResearchPaperMedia(data, id) {
    return axios.put(`/researchpapers/${id}/media`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }
  // users
  getUsers(searchValue = "") {
    var url = searchValue
      ? `/admin/users?username=${searchValue}`
      : "/admin/users";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addUser(data) {
    return axios.post(`/admin/users/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editUser(id, data) {
    return axios.put(`/admin/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  exportUsers() {
    return axios.get(`/admin/users/export`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteUser(id) {
    return axios.delete(`/admin/users/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  deleteUserHard(id) {
    return axios.delete(`/user/hard-delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  // banners
  getBanners(searchValue = "") {
    var url = searchValue
      ? `/admin/banners?title=${searchValue}`
      : "/admin/banners";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  getBanner(id) {
    return axios.get(`/admin/banner/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editBanner(id, data) {
    return axios.put(`/admin/banner/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  addBanner(data) {
    return axios.post(`/admin/banner`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  uploadBannerImage(data, id) {
    return axios.put(`/admin/banner/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteBanner(id) {
    return axios.delete(`/admin/banner/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // organizations
  getAllOrganizations(searchValue = "") {
    var url = searchValue
      ? `/user/profile/organizations?title=${searchValue}`
      : "/user/profile/organizations";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // events
  getEvents() {
    return axios.get(`/events/admin`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  addEvent(data) {
    return axios.post(`/events`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  editEvent(id, data) {
    return axios.put(`/events/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  uploadEventMedia(id, data) {
    return axios.put(`/events/${id}/media`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  deleteEvent(id) {
    return axios.delete(`/events/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // Admin

  getAdmins(searchValue = "") {
    var url = searchValue
      ? `/admin/get-all?name=${searchValue}`
      : "/admin/get-all";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addAdmin(data) {
    return axios.post(`/admin/register/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editAdmin(id, data) {
    return axios.put(`/admin/edit/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  deleteAdmin(id) {
    return axios.delete(`/admin/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // Community Groups

  getAllCommunity(searchValue = "") {
    var url = searchValue
      ? `/discussion/community?search=${searchValue}`
      : "/discussion/community";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  addCommunity(data) {
    return axios.post("/discussion/community/interest-community", data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  deleteCommunity(id) {
    return axios.delete(`/discussion/community/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
  addCommunityImage(data, id) {
    console.log("id>>>", id);
    return axios.post(`/discussion/community/${id}/image`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpecificCommunity(id) {
    return axios.get(`/discussion/community/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  editCommunity(id, data) {
    return axios.put(`/discussion/community/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getCommunityMembers(id, searchValue = "") {
    var url = searchValue
      ? `/discussion/communityUser/get-members/${id}?search=${searchValue}`
      : `/discussion/communityUser/get-members/${id}`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  // Polls

  getAllPolls(searchValue = "") {
    var url = searchValue
      ? `/polls/web/all?search=${searchValue}`
      : "/polls/web/all";
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpecificPolls(id) {
    return axios.get(`/polls/web/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getSpecificVoters(pollId, optionId) {
    return axios.get(`polls/option/voters/${pollId}/${optionId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }

  getPollCsv(pollId) {
    return axios.get(`polls/pollCsv/${pollId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
  }
}
