// import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Button } from "@mui/material";
import Apilib from "../../Api";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ChipComponent = (props) => {
  const API = new Apilib();
  const theme = useTheme();
  const [allInterest, setAllInterest] = React.useState([]);
  const [selectedInterest, setSelectedInterest] = React.useState([]);
  const [MainData, setMainData] = useState([]);
  const disabled = props?.disabled;
  const names = MainData;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value ^^^^^^", value);
    if (value == "" || value == undefined) {
      setSelectedInterest([]);
      return;
    } else {
      if (typeof value === "string" && value !== null) {
        setSelectedInterest(value.split(","));
        props?.onSelectChange(value.split(","));
      } else if (value.includes("all")) {
        setSelectedInterest(
          selectedInterest.length === names.length ? [] : names
        );
        props?.onSelectChange(
          selectedInterest.length === names.length ? [] : names
        );
      }
      // NOTE: THIS ELSE BLOCK IS FOR WHEN USER DE-SELECTS THE INTEREST
      // THE ELEMENT WHICH USER SELECTS, IS ALWAYS THE LAST ELEMENT IN THE ARRAY
      // SO WE CAN CHECK IF THE LAST ELEMENT IS CHECKED OR NOT AND  THEN WE FILTER IT OUT
      else {
        let newValue = value;
        if (value.length < selectedInterest.length) {
          setSelectedInterest(value);
        } else {
          if (checkInterestChecked(newValue[newValue.length - 1])) {
            newValue = selectedInterest.filter(
              (_existing) =>
                _existing?._id != newValue[newValue.length - 1]?._id
            );
          }
          setSelectedInterest(newValue);
          props?.onSelectChange(newValue);
        }
      }
    }
  };

  const updateSelection = (event) => {
    const {
      target: { value },
    } = event;
    console.table(value);
    // let existsOnIndex = value.find(_selected => _selected?._id == value?._id)
    // console.log(existsOnIndex)

    // if (!existsOnIndex) {
    //   setSelectedInterest([...selectedInterest, value]);
    // } else {
    //   setSelectedInterest(selectedInterest.filter(_existing=> _existing?._id != value?._id))
    // }
  };

  const handleDelete = (IndustryId) => {
    const updatedSelectedInterest = selectedInterest.filter(
      (industry) => industry._id !== IndustryId
    );
    setSelectedInterest(updatedSelectedInterest);
    props?.onSelectChange(updatedSelectedInterest);

    console.log("delete", updatedSelectedInterest);
  };

  const handleSeeMore = () => {
    const nextIndex = allInterest.length;
    const nextNames = names.slice(nextIndex, nextIndex + 5);
    setAllInterest((prevVisibleNames) => [...prevVisibleNames, ...nextNames]);
  };

  const allSelected = selectedInterest.length === names.length;

  useEffect(() => {
    setSelectedInterest(props?.selectedData);
  }, [props]);

  useEffect(() => {
    API.getInterestAreaActiveOnly()
      .then((res) => {
        console.log("get interest areas", res?.data?.interests);
        setAllInterest(res?.data?.interests);
        setMainData(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    setAllInterest(names.slice(0, 5));
  }, [MainData]);

  const checkInterestChecked = (interest) => {
    for (let i = 0; i < selectedInterest.length; i++) {
      const selectedInt = selectedInterest[i];
      if (selectedInt && interest) {
        if (selectedInt._id == interest._id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div>
      <FormControl className="For_chip_section" sx={{ m: 1, width: "100%" }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          disabled={disabled}
          value={selectedInterest}
          onChange={handleChange}
          // onChange={updateSelection}
          input={<OutlinedInput label="Tag" />}
          renderValue={() => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedInterest.map(
                (interest) =>
                  // console.log(value),
                  interest && (
                    <Chip
                      disabled={disabled}
                      key={interest?._id}
                      label={interest?.name}
                      onDelete={() => {
                        handleDelete(interest._id);
                      }}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  )
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem
            key="all"
            value="all"
            onClick={() => setSelectedInterest(allSelected ? [] : names)}
            // disabled={allSelected}
          >
            <Checkbox checked={allSelected} />
            <ListItemText primary="All" />
          </MenuItem>

          {allInterest.map((interest) => {
            return (
              <MenuItem key={interest?._id} value={interest}>
                {/* <Checkbox checked={selectedInterest.indexOf(interest) > -1} /> */}
                <Checkbox checked={checkInterestChecked(interest)} />
                <ListItemText primary={interest?.name} />
              </MenuItem>
            );
          })}

          {allInterest.length < names.length && (
            <MenuItem onClick={handleSeeMore}>See more</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
export default ChipComponent;
