import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import "./date-picker.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

const CommonDatePicker = (props) => {
  const [datevalue, setDateValue] = React.useState("");
  React.useEffect(() => {
    if (props.values.scheduledTime) {
      const dayjsObject = dayjs(props.values.scheduledTime)
        .subtract(5, "hours")
        .subtract(30, "minutes");
      setDateValue(dayjsObject);
      console.log(datevalue, "inside Effect");
    }
  }, []);

  const handleDateChange = (newValue) => {
    // console.log(newValue, " <------------------------------------ newValue");
    // is newValue a valid iso string?
    if(newValue && newValue.isValid()) {
      const utcDate = dayjs(newValue).toDate();
      setDateValue(utcDate);
  
      const istOffset = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
      const istTime = utcDate.getTime() + istOffset * 60 * 1000;
      const istDate = new Date(istTime);
      
      props.setValues({
        ...props.values,
        scheduledTime: istDate.toISOString(),
      });
      props.validateForm({
        ...props.values,
        scheduledTime: istDate.toISOString(),
      });
    }
    else {
      setDateValue(null);
      props.validateForm({
        ...props.values,
        scheduledTime: null,
      });
    }

  };

  const minDate = new dayjs();

  return (
    <div className="custom_datePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            label=""
            ampm={true}
            value={datevalue}
            onChange={handleDateChange}
            minDateTime={minDate}
          />
        </DemoContainer>
      </LocalizationProvider>
      <div className="error-text">{props.errors?.scheduledTime}</div>
    </div>
  );
};

export default CommonDatePicker;
