import React, { useState, useRef, useEffect } from "react";
import AddSpeakerVideoModalcontainer from "../../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import ChipComponent from "../ChipComponent";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import SelectSpeaker from "../../../UICommon/DropDowns/SelectSpeaker";

// avatar
import svg4 from "../../../Asset/images/avatars/4.svg";
import Apilib from "../../../Api";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

// import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PreviewSpeakerVideoModel from "../../AddSpeakerVideo/Modals/PreviewSpeakerVideo-model";
import ViewCommunityMembers from "./ViewCommunityMembers";

const EditCommunityGroupModal = (props) => {
  const API = new Apilib();
  const imageref = useRef();
  const id = props?.id;
  const [values, setValues] = useState({
    name: "",
    description: "",
    interests: [],
    industries: [],
    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    userLevel: [],
    isPremium: false,
    isCertified: false,
    isActive: false,
    isGcc: false,
    isBec: false,
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const [viewCommunityMembers, setViewCommunityMembers] = useState(false);

  useEffect(() => {
    fetchSpecificCommunity(id);
  }, [id]);

  const fetchSpecificCommunity = (id) => {
    API.getSpecificCommunity(id)
      .then((res) => {
        console.log("Get specific community");
        console.log(res?.data?.data);
        var data = res?.data?.data;

        setValues({
          name: data.name,
          description: data.description,
          interests: [...data.interests],
          industries: [...data.industries],
          imageValues: data?.profilePic
            ? {
                loaded: true,
                url: data?.profilePic,
                type: "",
                name: "",
                source: "",
              }
            : {
                loaded: false,
                url: "",
                type: "",
                name: "",
                source: "",
              },
          isPremium: data?.userLevel?.includes("premium"),
          isCertified: data?.userLevel?.includes("certified"),
          isGcc: data?.userLevel?.includes("GCC"),
          isBec: data?.userLevel?.includes("BEC"),
        });
        props.setPreviousImage(data?.profilePic ? true : false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    debugger;
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Community Name is required.";
    }

    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }

    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {
      valuesX.userLevel = ["free", "non-certified"];

      if (valuesX.isPremium && !valuesX.userLevel.includes("premium"))
        valuesX.userLevel.push("premium");
      // if (!valuesX.isPremium && !valuesX.userLevel.includes("free"))
      //   valuesX.userLevel.push("free");
      if (valuesX.isCertified && !valuesX.userLevel.includes("certified"))
        valuesX.userLevel.push("certified");
      // if (
      //   !valuesX.isCertified &&
      //   !valuesX.userLevel.includes("non-certified")
      // )
      //   valuesX.userLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.userLevel.includes("GCC"))
        valuesX.userLevel.push("GCC");
      if (valuesX.isBec && !valuesX.userLevel.includes("BEC"))
        valuesX.userLevel.push("BEC");

      console.log(valuesX, "scheduledTime ");
      props.editCommunity(id, valuesX);
      // props?.setShowPreview(valuesX);

      setValues({
        name: "",
        description: "",
        interests: [],
        industries: [],
        imageValues: {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
        userLevel: [],
        isPremium: false,
        isCertified: false,
        isActive: false,
        isGcc: false,
        isBec: false,
      });

      setErrors({
        name: "",
      });
    }
  };
  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        imageref.current.value = null;
      }
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#002566",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const _viewMembers = () => {
    setViewCommunityMembers(true);
  };

  return (
    <>
      {viewCommunityMembers ? (
        <AddSpeakerVideoModalcontainer>
          <ViewCommunityMembers
            {...{
              id,
              setViewCommunityMembers,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}
      <div className="add_speaker_video_main">
        <div className="speaker_video_head">
          <h1 style={styles.headingCommunity}>Edit Community Group</h1>
        </div>
        <div className="body_add_speaker">
          <div className="Add_speaker_video_form">
            <div className="row">
              <div className="col-lg-6">
                <div class="form-group">
                  <label htmlFor="">Community Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id=""
                    placeholder="Community Name"
                    name="name"
                    value={values?.name}
                    onChange={handleChangeValues}
                  />
                  <div className="error-text">{errors?.name}</div>
                </div>

                <div class="form-group">
                  <label htmlFor="">Community Description</label>
                  <textarea
                    class="form-control"
                    style={{
                      height: 600,
                      width: "100%",
                    }}
                    placeholder="Type here..."
                    name="description"
                    value={values?.description}
                    onChange={handleChangeValues}
                  ></textarea>
                  <div className="error-text">{errors?.description}</div>
                </div>
              </div>

              <div className="col-lg-6">
                <h6 className="setting_heading">Settings</h6>
                {props.Certification_Switch_Disabled ? null : (
                  <div className="member_free_button_container">
                    <p className="member_type">Member type</p>
                    <p className="free_premium_container">
                      <span className="free_Font">Free</span>
                      <FormGroup>
                        <FormControlLabel
                          className="status_radio_button custom_status_radio_button "
                          control={
                            <IOSSwitch
                              className="radio_button_background"
                              disabled
                              sx={{ m: 1 }}
                              checked={values?.isPremium}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isPremium: !values?.isPremium,
                                });
                              }}
                            />
                          }
                        />
                      </FormGroup>
                      <span className="premium_Font">Premium</span>
                    </p>
                  </div>
                )}
                {props.Certification_Switch_Disabled ? null : (
                  <div className="member_free_button_container">
                    <p className="member_type">Certification</p>
                    <p className="free_premium_container">
                      <span className="free_Font">Non-Certified</span>
                      <FormGroup>
                        <FormControlLabel
                          className="status_radio_button custom_status_radio_button "
                          control={
                            <IOSSwitch
                              disabled
                              className="radio_button_background"
                              sx={{ m: 1 }}
                              checked={values?.isCertified}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isCertified: !values?.isCertified,
                                });
                              }}
                            />
                          }
                        />
                      </FormGroup>
                      <span className="premium_Font">Certified</span>
                    </p>
                  </div>
                )}

                <div className="member_free_button_container">
                  <p className="member_type">GCC</p>
                  <p className="free_premium_container">
                    <span className="free_Font">No</span>
                    <FormGroup>
                      <FormControlLabel
                        className="status_radio_button custom_status_radio_button "
                        control={
                          <IOSSwitch
                            disabled
                            className="radio_button_background"
                            sx={{ m: 1 }}
                            checked={values?.isGcc}
                            onChange={() => {
                              setValues({
                                ...values,
                                isGcc: !values?.isGcc,
                              });
                            }}
                          />
                        }
                      />
                    </FormGroup>
                    <span className="premium_Font">Yes</span>
                  </p>
                </div>

                <div className="form-group member_free_button_container">
                  <p className="member_type">BEC</p>
                  <p className="free_premium_container">
                    <span className="free_Font">No</span>
                    <FormGroup>
                      <FormControlLabel
                        className="status_radio_button custom_status_radio_button "
                        control={
                          <IOSSwitch
                            disabled
                            className="radio_button_background"
                            sx={{ m: 1 }}
                            checked={values?.isBec}
                            onChange={() => {
                              setValues({
                                ...values,
                                isBec: !values?.isBec,
                              });
                            }}
                          />
                        }
                      />
                    </FormGroup>
                    <span className="premium_Font">Yes</span>
                  </p>
                </div>

                <div class="form-group">
                  <label>Community Logo Upload (1080x1080px)</label>
                  <br />
                  <span style={{ fontSize: "10px", color: "#002566" }}>
                    {" "}
                    (Accepted format is .jpg, .png, .jpeg and size 350-400kb)
                  </span>
                  {values?.imageValues?.loaded ? (
                    <div>
                      <img
                        style={{
                          height: 250,
                          width: "100%",
                        }}
                        src={values?.imageValues?.url}
                      />
                      <br></br>
                      <button
                        onClick={() => {
                          setValues({
                            ...values,
                            imageValues: {
                              loaded: false,
                              url: "",
                            },
                          });
                        }}
                      >
                        Remove Image
                      </button>
                    </div>
                  ) : (
                    <div className="upload_photo_container">
                      <img src={importPhotoIcon} alt="" />
                      <p>
                        Drag and Drop or{" "}
                        <label htmlFor="AddPhoto"> Browse </label> to upload
                      </p>
                    </div>
                  )}
                  <div className="error-text">{errors?.imageValues}</div>
                  <input
                    type="file"
                    class="form-control"
                    id="AddPhoto"
                    placeholder="Name"
                    style={{ display: "none" }}
                    ref={imageref}
                    onChange={onImageChange}
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="">Interest area</label>
                  <InterestAreaDropDown
                    disabled
                    selectedData={values?.interests}
                    onSelectChange={(data) => {
                      // console.log(data, "data");
                      setValues({
                        ...values,
                        interests: [...data],
                      });
                    }}
                  />
                  <div className="error-text">{errors?.interests}</div>
                </div>

                <div class="form-group">
                  <label htmlFor="">Industry type</label>
                  <IndustryTypeDropDown
                    disabled
                    selectedData={values?.industries}
                    onSelectChange={(data) => {
                      console.log(data);
                      setValues({
                        ...values,
                        industries: [...data],
                      });
                    }}
                  />
                  <div className="error-text">{errors?.industries}</div>
                </div>
                <div style={styles.viewMembersTag}>
                  <h6 className="setting_heading">Members</h6>
                  <div>
                    <span style={styles.spanForViewButton}>
                      <p onClick={_viewMembers}>
                        <u>View</u>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="next_cancel_button_container">
              <button
                onClick={() => {
                  validateForm(values, true);
                }}
              >
                Submit
              </button>
              <button onClick={() => props.setOpenEditModel(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditCommunityGroupModal;

const styles = {
  headingCommunity: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#3f434a",
    marginBottom: 0,
  },
  spanForViewButton: {
    color: "#002566",
    cursor: "pointer",
  },
  viewMembersTag: {
    display: "flex",
    justifyContent: "space-between",
  },
};
