import React from 'react'
import  "../../Asset/scss/pagenotfound.scss";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
const PageNotFound = () => {
  return (
    <>
    <section className='pagenotfoundSec'>

<div className='text-center'>
    <h1>404</h1>
    <h4>We can't seem to find that</h4>
    <p>The page you're looking for doesn't exist or has been moved</p>
    <Link to="/interest_area">
    <button><KeyboardBackspaceIcon />  Back to Home</button>
    </Link>
</div>


    </section>
    </>
  )
}

export default PageNotFound;