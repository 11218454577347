import React, { useState, useEffect } from "react";
import "../../../UICommon/Modal/Industry-Type-modal/EditIndustryType-Modal.scss";
import redCross from "../../../Asset/images/icons/redCross.svg";
import avatar3 from "../../../Asset/images/avatars/3.svg";

const SelectSpeaker = ({ setShowSelectSpeaker }) => {
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const addCategory = (event) => {
    event.preventDefault();
    const _newCategory = event.target["categoryInput"].value;
    setCategories([...new Set([...categories, _newCategory])]);
    setText("");
    console.log(categories);
  };

  const removeCategory = (category) => {
    const _newCategories = [];
    categories.map((existingcategory) => {
      if (existingcategory != category) {
        _newCategories.push(existingcategory);
      }
    });
    setCategories(_newCategories);
  };

  return (
    <div className="select_main_container">
      <h1 className="modal_heading">Select Speaker</h1>
      <div>
        <p className="ModalPara">Add a Speaker and press enter</p>
        <form onSubmit={addCategory}>
          <input
            className="ADD_category_input"
            type="text"
            placeholder="Add a category"
            name="categoryInput"
            value={text}
            onChange={handleChange}
          />
          <p>Added</p>
          <div className="category_container">
            {categories.map((category) => (
              <div key={category} className="category_tile">
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <img src={avatar3} alt="" />
                  <p className="CategoryName"> {category} </p>
                </div>
                <button
                  type="button"
                  className="remove_category "
                  onClick={() => removeCategory(category)}
                >
                  {" "}
                  <img src={redCross} alt="" />{" "}
                </button>
              </div>
            ))}
          </div>

          <button
            style={{ marginTop: "1rem" }}
            className="submitModalButton"
            type="submit"
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default SelectSpeaker;
