import React from "react";
import deleteConfirmationIcon from "../../../Asset/images/icons/deleteConfirmationIcon.svg";
import "./deleteConfirmation.scss";

const DeleteConfirmation = (props) => {
  return (
    <div className="main_delete_container">
      <div className="delete_container">
        <img src={deleteConfirmationIcon} alt="" />
        <div className="delete_content">
          <h1>Sure you want to delete it?</h1>

          <div className="delete_content_button_content ">
            <button
              onClick={() => {
                props?.onDelete();
              }}
            >
              Delete
            </button>
            <button
              onClick={() => {
                props?.setOpenDeleteConfirmation(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmation;
