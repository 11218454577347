import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Apilib from "../../Api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ChipComponent = (props) => {
  const [allInterest, setAllInterest] = useState([]);
  const API = new Apilib();

  const theme = useTheme();
  const [selectedInterest, setSelectedInterest] = React.useState(
    props?.selectedData || []
  );

  useEffect(() => {
    fetchInterests();
  }, []);

  const fetchInterests = () => {
    API.getSpeakers()
      .then((res) => {
        // console.log("get speaker areas", res?.data?.speakers);
        setAllInterest(res?.data?.speakers);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSelectedInterest(value);
    props?.onSelectChange(value);
  };

  const handleDelete = (speakerId) => {
    const updatedSelectedInterest = selectedInterest.filter(
      (speaker) => speaker._id !== speakerId
    );
    setSelectedInterest(updatedSelectedInterest);
    props?.onSelectChange(updatedSelectedInterest);

    console.log("delete", updatedSelectedInterest);
  };



  return (
    <div>
      <FormControl className="For_chip_section" sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label"></InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedInterest}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((speaker) => (
                // <Chip
                //   key={speaker?._id}
                //   avatar={
                //     <img
                //       src={speaker?.profilePic}
                //       style={{
                //         width: 20,
                //         height: 20,
                //         borderRadius: "50%",
                //       }}
                //     />
                //   }
                //   label={speaker?.name}
                // />
                <Chip
                  key={speaker?._id}
                  avatar={
                    <img
                      src={speaker?.profilePic}
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                      }}
                    />
                  }
                  label={speaker?.name}
                  variant="outlined"
                  clickable={true}
                  onDelete={() => {
                    handleDelete(speaker._id);
                  }}
                  onMouseDown={(event) => { event.stopPropagation(); }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {allInterest?.map((speaker) => (
            <MenuItem
              key={speaker?._id}
              value={speaker}
              style={getStyles(speaker?.name, selectedInterest, theme)}
            >
              <div>
                <img
                  src={speaker?.profilePic}
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                />{" "}
                {speaker?.name}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default ChipComponent;
