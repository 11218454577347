import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// preview header
import MobileSignal from "../../../Asset/images/icons/MobileSignal.svg";
import Wifi from "../../../Asset/images/icons/Wifi.svg";
import Battery from "../../../Asset/images/icons/Battery.svg";

import preview_video_thumbnail from "../../../Asset/images/speakerVideo/preview_video_thumbnail.png";
import bookmarkIcon from "../../../Asset/images/icons/bookmarkIcon.svg";
import shareIcon from "../../../Asset/images/icons/shareIcon.svg";
import thumbsUp from "../../../Asset/images/icons/thumbsUp.svg";
import studyListImg from "../../../Asset/images/casestudy/studyListImg.png";
import profilephoto from "../../../Asset/images/casestudy/profile photo.png";
import backArrow from "../../../Asset/images/icons/backArrow.svg";
import ssLogoBPP from "../../../Asset/images/ssLogoBPP.png";
import previewLogo from "../../../Asset/images/icons/previewLogo.png";
import "../../BestPeoplePractices/bestPeople.scss";
import "./previewAdsBanner.scss";
import researchDetailImg from "../../../Asset/images/researchDetailImg.png";
const PreviewAdsBanner = ({ submit, setOpenBannerPreview, values, setOpenEditDrawer, toggleDrawer, setState }) => {
  console.log("abhishel", values);
  return (
    <div className="preview_case_study_main">
      <h6 className="heading_preview_mode">Preview Mode</h6>
      <div className="row">
        <div className="offset-lg-4 col-lg-4">
          <div className="speaker_video_list_container">
            <div className="preview_time_icons_container">
              <p>9:41</p>
              <div className="signal_other_Icons">
                <img src={MobileSignal} alt="" />
                <img src={Wifi} alt="" />
                <img src={Battery} alt="" />
              </div>
            </div>

            <Stack className="skeleton_square_container1" spacing={1}>
              <Skeleton variant="rectangular" width={30} height={30} />
              <img src={previewLogo} alt="" />
              <div className="custom_boxInline">
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={30} height={30} />
              </div>
            </Stack>

            <div>
              <div
                className="preview_Top_banner_container"
                style={{
                  backgroundImage: `url(${values?.image?.url})`,
                  width: "100%",
                }}
              >
                <h6
                  style={{
                    backgroundColor: values?.backgroundColor,
                  }}
                >
                  {values?.eyeBrowText}
                </h6>
                <h4>{values?.title}</h4>
                <p>{values?.details}</p>
                <img src="" alt="" />
              </div>
            </div>

            <div className="custom_preview_boxes_oneLine">
              <Skeleton variant="rectangular" width={130} height={35} />
              <Skeleton variant="rectangular" width={35} height={35} />
            </div>

            <div className="skelaton_below_banner_container">
              <div className="skelaton_below_banner">
                <Skeleton variant="rounded" width={250} height={135} />
                <Skeleton variant="rectangular" width={125} height={15} />
                <Skeleton variant="rectangular" width={115} height={12} />
                <Skeleton variant="rectangular" width={100} height={15} />
              </div>
              <div className="skelaton_below_banner">
                <Skeleton variant="rounded" width={250} height={135} />
                <Skeleton variant="rectangular" width={125} height={15} />
                <Skeleton variant="rectangular" width={115} height={12} />
                <Skeleton variant="rectangular" width={100} height={15} />
              </div>
            </div>

            <div className="custom_preview_boxes_oneLine">
              <Skeleton variant="rectangular" width={130} height={35} />
              <Skeleton variant="rectangular" width={35} height={35} />
            </div>

            <div className="skeleton4_square_container">
              <Skeleton variant="rounded" width={120} height={60} />
              <Skeleton variant="rounded" width={120} height={60} />
              <Skeleton variant="rounded" width={120} height={60} />
              {/* <Skeleton variant="rectangular" width={120} height={135} /> */}
            </div>

            <div className=" custom_sudo_fix_footer ">
              <Stack
                className="skeleton_square_container1"
                style={{ marginBottom: "1rem" }}
                spacing={1}
              >
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={30} height={30} />
              </Stack>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bottom_black_line"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="publish_close_button_container">
          <button
            onClick={() => {
              submit();
            }}
          >
            Publish
          </button>
          <button onClick={() => setOpenBannerPreview(false)}>Close</button>
          <button
            onClick={() => {
              setOpenBannerPreview(false);
              values.type == "add" ? setState({ right: true }) : setOpenEditDrawer(true);
            }}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewAdsBanner;
