import { ActionTypes } from "../constants/action-types";

const initialState = {
  userInfo: [],
};
export const UserReducer = (state = initialState, { type, payload }) => {
  console.log("user payload", type , payload)
  switch (type) {
    case ActionTypes.SET_USERINFO:
      return { ...state, userInfo: payload };
    default:
      return state;
  }
};