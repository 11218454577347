import React, { useEffect, useRef, useState } from "react";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Apilib from "../../../Api";
// import "../casestudy.scss";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import deleteIcon from "../../../Asset/images/icons/deleteIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";

import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import PracticeAreaDropDown from "../../../UICommon/DropDowns/PracticeAreaDropDown";
import SelectSpeakerDropDown from "../../../UICommon/DropDowns/SelectSpeakerDropDown";
import closeIcon from "../../../Asset/images/icons/closeIcon.svg";
import AddAuthor from "../../../UICommon/Modal/Author/AddAuthor";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";

const TextEditorHeight = "150vh";
const AddBlog = (props, { setOpenPreviewCaseStudy, setOpenCaseStudy }) => {
  const API = new Apilib();
  const [values, setValues] = useState({
    title: "",
    practiseName: "",
    slogan: "",
    content: "",
    interests: [],
    authors: [],
    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    authorImageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    audioValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    // audioValues:{},
    industries: [],
    practiseArea: [],
    contentLevel: ["free", "non-certified"],
    // contentLevel: [],
    isPremium: false,
    isCertified: false,
    isActive: false,
    isGcc: false,
    isBec: false,
    minsToRead: "",
  });

  const audioRef = useRef(null);
  const audioInputRef = useRef(null);

  const [authors, setAuthors] = useState([]);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const [errors, setErrors] = useState({
    title: "",
    video: "",
    content: "",
    interests: "",
    imageValues: "",
    // audioValues:{},
    speakers: "",
    audioFileError: ""
  });

  const [dob, setDob] = useState(null);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const addAuthor = () => {
    setErrors({});
    let authorsCp = [...values.authors];
    if (text && text.length >= 3) {
      authorsCp.push({ name: text });
      setValues({ ...values, authors: authorsCp });
      setText("");
    } else {
      setErrors({ ...errors, authors: 'Please enter a valid author name.' })
    }

  };

  const removeAuthor = (authorName) => {
    let authorsCp = [...values.authors];
    authorsCp = authorsCp.filter((el) => el.name !== authorName);
    setValues({ ...values, authors: authorsCp });
  };

  const [showAddAuthor, setShowAddAuthor] = useState(false);


  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };
  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    console.log(valuesX?.authorImageValues);
    if (valuesX?.imageValues?.loaded === false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.authorImageValues?.loaded === false) {
      isValidForm = false;
      errorsX.authorImageValues = "Author Image is required.";
    }
    if (valuesX?.title === "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.content === "") {
      isValidForm = false;
      errorsX.content = "Content is required.";
    }
    if (valuesX?.minsToRead === "") {
      isValidForm = false;
      errorsX.minsToRead = "Minutes to read is required.";
    }
    if (valuesX?.authors?.length === 0) {
      isValidForm = false;
      errorsX.authors = "Please add atleast one author.";
    }
    if (valuesX?.scheduledTime
      &&
      new Date(valuesX?.scheduledTime) < new Date()) {
      isValidForm = false;
      errorsX.scheduledTime = "Please select valid date and time.";
      setPublishOption((publishOption) => true);
    }
    if (valuesX?.interests?.length == 0) {
      isValidForm = false;
      errorsX.interests = "Please select atleast one interest.";
    }
    if (valuesX?.industries?.length == 0) {
      isValidForm = false;
      errorsX.industries = "Please select atleast one industry.";
    }

    setErrors({ ...errorsX });
    console.log(errorsX);
    if (isValidForm && shouldeSubmitForm) {
      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium"))
        valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free"))
        valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified"))
        valuesX.contentLevel.push("certified");
      if (
        !valuesX.isCertified &&
        !valuesX.contentLevel.includes("non-certified")
      )
        valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC"))
        valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC"))
        valuesX.contentLevel.push("BEC");
      // props?.setShowPreview(valuesX);
      props?.setPreviewValues(valuesX);
      props?.setOpenPreviewCaseStudy(true);
    }
  };

  useEffect(() => {
    console.log(values);
  }, [values]);

  useEffect(() => {
    return () => {
      props.setAudioFileDeleted(false);
    }
  }, [])

  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onAudioChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let audio = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      console.log("audio  -----------------------> type", type);
      if (!type.includes('audio')) {
        setErrors({ ...errors, audioFileError: 'Please select only valid audio files' })
        return;
      }
      // if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
      if (1) {
        setValues({
          ...values,
          audioValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(audio),
            name: e?.target?.files[0]?.name,
          },
        });
        props.setAudioFileDeleted(false);
        validateForm(
          {
            ...values,
            audioValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(audio),
              name: e?.target?.files[0]?.name,
            },
          },
          false
        );
        if (!values?.audioValues?.loaded) {

        }
        else {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
    audioInputRef.current.value = null;
  };

  const onAuthorImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          authorImageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            authorImageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const openPreviewHandler = (event) => {
    event.preventDefault();
    setOpenPreviewCaseStudy(true);
  };
  const [publishOption, setPublishOption] = useState(false);
  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
    console.log("yooo");
  };

  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const textInput = useRef(null);

  const handleFilePickerCallback = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.onchange = function () {
        const file = this.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          callback(imageUrl, { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    }
  };

  const _onDeleteAudio = () => {
    setValues({
      ...values,
      audioValues: {},
    });
    props.setAudioFileDeleted(true);
  }

  return (
    <div className="add_case_study_main">
      <div
        style={{ position: "relative" }}
        className="heading_publish_container"
      >
        <h1>Add Blog</h1>
        <button className="custom_publish_button" onClick={publishOptionHandler}>
          Publish <img src={BlueDropdownIcon} alt="BlueDropdownIcon" />
        </button>
        {publishOption ? (
          <div className="publish_option_container">
            <CommonDatePicker values={values} setValues={setValues} validateForm={validateForm} errors={errors} />

          </div>
        ) : null}
      </div>
      <div className="form_add_case_study" action="">
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label htmlFor="">Title</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="Title"
                name="title"
                value={values?.title}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.title}</div>
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Slogan</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="slogan"
                name="slogan"
                value={values?.slogan}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.slogan}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Practice Name</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder="practice Name"
                name="practiseName"
                value={values?.practiseName}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.practiseName}</div>
            </div> */}

            <div class="form-group">
              <label htmlFor="">Content</label>
              <div className="custom_font">
                <Editor
                  // apiKey='qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1'
                  apiKey='kwx0w0c6p27cr8jgtt1s2ue34zz5ueco49356dd67orwdh06'
                  // cloudChannel='5-dev'

                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    height: 500,
                    menubar: false,
                    selector: 'textarea',
                    plugins: [
                      "advlist",
                      "anchor",
                      "autolink",
                      "codesample",
                      "fullscreen",
                      "help",
                      "image",
                      "tinydrive",
                      "lists",
                      "link",
                      "media",
                      "preview",
                      "searchreplace",
                      "table",
                      "template",
                      "visualblocks",
                      "wordcount",
                      "emoticons",
                      // "insertfile",

                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "  fontsize fontsizeinput" +
                      "sizeselect | bold italic underline| fontselect |  fontsizeselect" +
                      "| alignleft aligncenter alignright alignjustify |" +
                      " image ",
                    font_size_formats:
                      "8px 10px 12px 14px 16px 18px 24px 36px 48px ",
                    file_picker_callback: handleFilePickerCallback,
                    // "insertfile link image emoticons",
                    font_size_formats:
                      "8px 10px 12px 14px 16px 18px 24px 36px 48px",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  // apiKey="amp50q3a5xog8bbq41f0jheeqkoo51zbhw56sbrjxrvu95pj"
                  value={values.content}
                  onEditorChange={(newValue, editor) =>
                    setValues({ ...values, content: newValue })
                  }
                />
              </div>
              <div className="error-text">{errors?.content}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Mins to Read</label>
              <input
                className="form-control"
                type="number"
                id=""
                placeholder="Add Mins to Read"
                name="minsToRead"
                value={values?.minsToRead}
                onChange={handleChangeValues}
              />
              <div className="error-text">{errors?.minsToRead}</div>
            </div>

            <div
              class="form-group"
              style={{ flexDirection: "column", display: "flex" }}
            >
              <label
                style={{
                  marginBottom: "10px",
                }}
                htmlFor=""
              >
                Date <span style={{ color: "red" }}>(Note: This field is strictly for past dated content upload for Admins)</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dob}
                  onChange={(newDob) => {
                    setDob(newDob);
                    if (newDob && dayjs.isDayjs(newDob)) {
                      console.log("dayjs string", newDob.toISOString());
                      setValues({
                        ...values,
                        createdAt: newDob.toISOString(),
                      });
                    }
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                />
              </LocalizationProvider>
              <div className="error-text">{errors?.dob}</div>
            </div>

            <div class="form-group">
              <label htmlFor="">Audio</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label className={values?.audioValues?.loaded ? "custom_audio1" : "custom_audio"} htmlFor="Audio">
                  {values?.audioValues?.loaded ? (
                    <audio ref={audioRef} controls>
                      <source key={values?.audioValues?.url} src={values?.audioValues?.url} />
                    </audio>
                  ) : " Add audio file   "}
                  <img src={importPhotoIcon} alt="" />
                </label>
                {
                  values?.audioValues?.loaded ?
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={_onDeleteAudio}>
                      <img src={deleteIcon} alt="" width={22} className="m-2" />
                    </label>
                    : null
                }

              </div>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder="Audio file"
                onChange={onAudioChange}
                ref={audioInputRef}
              />
              <div className="error-text">{errors?.audioFileError}</div>
            </div>

          </div>
          <div className="col-lg-6" style={{}}>
            <h6 className="setting_heading">Settings</h6>

            <div className="member_free_button_container">
              <p className="member_type">Member type</p>
              <p className="free_premium_container">
                <span className="free_Font">Free</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isPremium}
                        onChange={() => {
                          setValues({
                            ...values,
                            isPremium: !values?.isPremium,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Premium</span>
              </p>
            </div>
            <div className="member_free_button_container">
              <p className="member_type">Certification</p>
              <p className="free_premium_container">
                <span className="free_Font">Non-Certified</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isCertified}
                        onChange={() => {
                          setValues({
                            ...values,
                            isCertified: !values?.isCertified,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Certified</span>
              </p>
            </div>

            <div className="member_free_button_container">
              <p className="member_type">GCC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isGcc}
                        onChange={() => {
                          setValues({
                            ...values,
                            isGcc: !values?.isGcc,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div className="form-group member_free_button_container">
              <p className="member_type">BEC</p>
              <p className="free_premium_container">
                <span className="free_Font">No</span>
                <FormGroup>
                  <FormControlLabel
                    className="status_radio_button custom_status_radio_button "
                    control={
                      <IOSSwitch
                        className="radio_button_background"
                        sx={{ m: 1 }}
                        checked={values?.isBec}
                        onChange={() => {
                          setValues({
                            ...values,
                            isBec: !values?.isBec,
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
                <span className="premium_Font">Yes</span>
              </p>
            </div>

            <div class="form-group">
              <label>Thumbnail Image Upload (400x320px)</label>
              <br />
              <span style={{ fontSize: "10px", color: "#002566" }}>
                {" "}
                (Accepted format is .jpg, .png, .jpeg and size 50-100kb)
              </span>
              {values?.imageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={values?.imageValues?.url}
                  />
                  <br></br>
                  <button
                    onClick={() => {
                      setValues({
                        ...values,
                        imageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                      validateForm(
                        {
                          ...values,
                          imageValues: {
                            loaded: false,
                            url: "",
                          },
                        },
                        false
                      );
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or <label htmlFor="AddPhoto"> Browse </label>{" "}
                    to upload
                  </p>
                </div>
              )}
              <div className="error-text">{errors?.imageValues}</div>
              <input
                type="file"
                class="form-control"
                id="AddPhoto"
                placeholder="Name"
                style={{ display: "none" }}
                onChange={onImageChange}
              />
            </div>

            {/* <div class="form-group">
              <label htmlFor="">Tags</label>
              <ChipComponent />
            </div> */}

            {/* <div class="form-group">
              <div class="form-group">
                <label htmlFor="">Practice Area</label>
                <PracticeAreaDropDown
                  selectedData={values?.practiseArea}
                  onSelectChange={(data) => {
                    // console.log(data, "data");
                    setValues({
                      ...values,
                      practiseArea: [...data],
                    });
                    validateForm({ ...values, practiseArea: [...data] }, false);
                  }}
                />
                <div className="error-text">{errors?.practiseArea}</div>
              </div>
            </div> */}
            <div class="form-group">
              <label htmlFor="">Interest area</label>
              <InterestAreaDropDown
                selectedData={values?.interests}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    interests: [...data],
                  });
                  validateForm({ ...values, interests: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.interests}</div>
            </div>
            <div class="form-group">
              <label htmlFor="">Industry type</label>
              <IndustryTypeDropDown
                selectedData={values?.industries}
                onSelectChange={(data) => {
                  // console.log(data, "data");
                  setValues({
                    ...values,
                    industries: [...data],
                  });
                  validateForm({ ...values, industries: [...data] }, false);
                }}
              />
              <div className="error-text">{errors?.industries}</div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Document</label>
              <label className="custom_audio " htmlFor="Audio">
                {" "}
                <img src={importPhotoIcon} alt="" />
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="Audio"
                placeholder=""
              />
            </div> */}

            <div class="form-group">
              <label htmlFor="">Author Name</label>
              <div className="inline_label_add">
                <input
                  className="ADD_category_input"
                  type="text"
                  placeholder="Add Author Name"
                  ref={textInput}
                  name="categoryInput"
                  value={text}
                  onChange={(event) => setText(event?.target?.value)}
                />
                <button className="submitModalButton" onClick={addAuthor}>
                  Add
                </button>
              </div>
              <div className="error-text">{errors?.authors}</div>
              <div className="category_container">
                {values?.authors?.map((category) => (
                  <div className="category_tile">
                    {" "}
                    <p className="CategoryName"> {category?.name} </p>
                    <button
                      type="button"
                      className="remove_category"
                      onClick={() => removeAuthor(category?.name)}
                    >
                      {" "}
                      <img src={closeIcon} alt="" />{" "}
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="">Author Photo/Company Icon</label>
              <input
                className="form-control"
                type="file"
                id="Audio"
                placeholder=""
                onChange={onAuthorImageChange}
              />
              <div className="error-text">{errors?.authorImageValues}</div>
            </div> */}
            <div class="form-group">
              <label>Author Photo/Company Icon (1080x1080px)</label>
              <br />
              <span style={{ fontSize: "10px", color: "#002566" }}>
                {" "}
                (Accepted format is .jpg, .png, .jpeg and size 300-350kb)
              </span>
              {values?.authorImageValues?.loaded ? (
                <div>
                  <img
                    style={{
                      height: 250,
                      width: "100%",
                    }}
                    src={values?.authorImageValues?.url}
                  />
                  <br></br>
                  <button
                    onClick={() => {
                      setValues({
                        ...values,
                        authorImageValues: {
                          loaded: false,
                          url: "",
                        },
                      });
                      validateForm(
                        {
                          ...values,
                          authorImageValues: {
                            loaded: false,
                            url: "",
                          },
                        },
                        false
                      );
                    }}
                    style={{ marginTop: "1rem" }}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <div className="upload_photo_container">
                  <img src={importPhotoIcon} alt="" />
                  <p>
                    Drag and Drop or{" "}
                    <label htmlFor="authorImage"> Browse </label> to upload
                  </p>
                </div>
              )}
              <div className="error-text">{errors?.authorImageValues}</div>
              <input
                type="file"
                class="form-control"
                id="authorImage"
                placeholder="Author Photo/Company Icon"
                onChange={onAuthorImageChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="next_cancel_button_container">
            <button
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Next
            </button>
            <button
              onClick={() => {
                props.forCloseModel(false);
              }}
            >
              {/* <button onClick={() => props?.setOpenAddVideo(false)}> */}
              Cancel
            </button>
          </div>
        </div>
      </div>
      {showAddAuthor ? (
        <ModalContainer setShowSelectSpeaker={setShowAddAuthor}>
          <AddAuthor {...{ setShowAddAuthor, addAuthor }} />
        </ModalContainer>
      ) : null}
    </div>
  );
};
export default AddBlog;
