import React, { Fragment } from "react";

import "../../../Asset/scss/login/login.scss";
import "../../../Asset/scss/ForgotPassword/RecoverPassword.scss";

import Loginsvg from "../../../Asset/images/LoginImage.svg";
import ResetPasswordLock from "../../../Asset/images/ResetPasswordLock.png";
import { Link } from "react-router-dom";

const RecoverPassword = () => {

  return (
    <Fragment>
      <div className="custom">
        <div className="MainContainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 login_img_container displayFlexCenter">
                <img src={Loginsvg} alt="" />
              </div>
              <div className="offset-lg-1 col-lg-5 col-md-5 displayFlexCenter">
                <div className="container FormContainer">
                    <img className="ResetLock" src={ResetPasswordLock} alt="" />
                <h4 className="Login_heading text-center RecoverPasswordHeading">Recover Your Password</h4>
                  <form>
                    <div className="Lable_input_container">
                      <label htmlFor="exampleInputEmail1 " className="form-label">
                        Email
                      </label>
                      <input
                        type="email "
                        className="form-control LogingEmail "
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <Link to="/ResetPassword">
                    <button type="submit" className="btn btn-primary Login_button">
                        Recover Password
                    </button>
                    </Link>
                  </form>
                  <h6 className="GoBackLogin">Go back to <Link to="/"><span>Login</span></Link>    </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default RecoverPassword;
