import React, { useState, useRef, useEffect } from "react";
import AddSpeakerVideoModalcontainer from "../../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import BlueDropdownIcon from "../../../Asset/images/icons/BlueDropdownIcon.svg";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import deleteIcon from "../../../Asset/images/icons/deleteIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import ChipComponent from "../ChipComponent";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";
import SelectSpeaker from "../../../UICommon/DropDowns/SelectSpeaker";

// avatar
import svg1 from "../../../Asset/images/avatars/1.svg";
import svg2 from "../../../Asset/images/avatars/2.svg";
import svg3 from "../../../Asset/images/avatars/3.svg";
import svg4 from "../../../Asset/images/avatars/4.svg";
import Apilib from "../../../Api";
import ModalContainer from "../../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import CommonDatePicker from "../../../UICommon/Date-Picker/CommonDatePicker";

const AddSpeakerVideoModal = (props, { setShowPreview, setOpenAddVideo }) => {
  const API = new Apilib();
  const [addMoreText, setAddMoreText] = useState(false);
  const [publishOption, setPublishOption] = useState(false);
  const [showSelectSpeaker, setShowSelectSpeaker] = useState(false);
  const [values, setValues] = useState({
    title: "",
    video: "",
    description: "",
    // interests: [
    //   {
    //     _id: "634e94bf3e973313be9c0ffb",
    //     name: "Leadership",
    //   },
    //   {
    //     _id: "634e94bf3e973313be9c1005",
    //     name: "Collaboration",
    //   },
    //   {
    //     _id: "634e94bf3e973313be9c1007",
    //     name: "Agility & Resilience",
    //   },
    //   {
    //     _id: "634e94bf3e973313be9c1013",
    //     name: "Talent Management",
    //   },
    //   {
    //     _id: "634e94bf3e973313be9c1015",
    //     name: "Communication",
    //   },
    //   {
    //     _id: "634e94bf3e973313be9c1017",
    //     name: "Strategy",
    //   },
    // ],
    interests: props.interests,
    industries: props.industries,

    imageValues: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    audioValues: {},
    // speakers: [
    //   {
    //     _id: "637f01513eed510b020fa4b4",
    //     name: "Harsh Mariwala",
    //     profilePic:
    //       "https://gptw-app.s3.ap-south-1.amazonaws.com/speakers/common",
    //   },
    // ],
    speakers: props.speakers,
    // contentLevel: ["free", "non-certified"],
    contentLevel: [],
    isPremium: false,
    isCertified: false,

    isActive: false,
    isGcc: false,
    isBec: false,
  });

  const [errors, setErrors] = useState({
    title: "",
    video: "",
    description: "",
    interests: "",
    imageValues: "",
    // audioValues:{},
    speakers: "",
    audioFileError: ""
  });

  const audioRef = useRef(null);
  const audioInputRef = useRef(null);

  useEffect(() => {
    fetchSpeakerVideo(props?.id);
    return () => {
      props.setAudioFileDeleted(false);
    }
  }, [props?.id]);

  const fetchSpeakerVideo = (id) => {
    API.getSpeakerVideo(id)
      .then((res) => {
        console.log(res?.data);
        var data = res?.data?.data;
        console.log("SETING INDUS ", values.industries);
        setValues({
          ...data,
          speakers: [...values.speakers],
          interests: [...values.interests],
          industries: [...values.industries],
          imageValues: data?.coverImage
            ? {
              loaded: true,
              url: data?.coverImage,
              type: "",
              name: "",
              source: "",
            }
            : {
              loaded: false,
              url: "",
              type: "",
              name: "",
              source: "",
            },
          audioValues: data?.audio
            ? {
              loaded: true,
              url: data?.audio,
              type: "",
              name: "",
              source: "",
            }
            : {
              loaded: false,
              url: "",
              type: "",
              name: "",
              source: "",
            },
          isPremium: data?.contentLevel.includes('premium'),
          isCertified: data?.contentLevel.includes('certified'),
          isGcc: data?.contentLevel.includes('GCC'),
          isBec: data?.contentLevel.includes('BEC'),
        });
        // setPage(1);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target?.name]: event?.target?.value,
      },
      false
    );
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};
    // console.log(valuesX?.imageValues);
    if (valuesX?.imageValues?.loaded == false) {
      isValidForm = false;
      errorsX.imageValues = "Thumbnail is required.";
    }
    if (valuesX?.title == "") {
      isValidForm = false;
      errorsX.title = "Title is required.";
    }
    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }
    if (valuesX?.video == "") {
      isValidForm = false;
      errorsX.video = "Video Link is required.";
    }
    if (valuesX?.speakers?.length == 0) {
      isValidForm = false;
      errorsX.speakers = "Please select atleast one speaker.";
    }
    if (valuesX?.scheduledTime
      &&
      new Date(valuesX?.scheduledTime) < new Date()
      &&
      !valuesX?.isPublished
      &&
      valuesX?.isPublished != undefined) {
      isValidForm = false;
      errorsX.scheduledTime = "Please select valid date and time.";
      setPublishOption((publishOption) => true);
    }

    if (valuesX?.interests?.length == 0) {
      isValidForm = false;
      errorsX.interests = "Please select atleast one interest.";
    }
    if (valuesX?.industries?.length == 0) {
      isValidForm = false;
      errorsX.industries = "Please select atleast one industry.";
    }

    // console.log(errorsX);
    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {

      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium"))
        valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free"))
        valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified"))
        valuesX.contentLevel.push("certified");
      if (
        !valuesX.isCertified &&
        !valuesX.contentLevel.includes("non-certified")
      )
        valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC"))
        valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC"))
        valuesX.contentLevel.push("BEC");

      props?.setShowPreview(valuesX);
    }
  };
  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        validateForm(
          {
            ...values,
            imageValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(img),
            },
          },
          false
        );
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };
  const onAudioChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let audio = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      console.log("audio  -----------------------> type", type);
      if (!type.includes('audio')) {
        setErrors({ ...errors, audioFileError: 'Please select only valid audio files' })
        return;
      }
      // if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
      if (1) {
        setValues({
          ...values,
          audioValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(audio),
            name: e?.target?.files[0]?.name,
          },
        });
        props.setAudioFileDeleted(false);
        validateForm(
          {
            ...values,
            audioValues: {
              loaded: true,
              source: e?.target?.files[0],
              url: URL.createObjectURL(audio),
              name: e?.target?.files[0]?.name,
            },
          },
          false
        );
        if (!values?.audioValues?.loaded) {

        }
        else {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
    audioInputRef.current.value = null;
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const publishOptionHandler = () => {
    setPublishOption((publishOption) => !publishOption);
  };
  const addMoreTextHandler = () => {
    setAddMoreText(true);
  };
  const showPreviewHandler = (e) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const inputRef = React.useRef(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const _onDeleteAudio = () => {
    setValues({
      ...values,
      audioValues: {},
    });
    props.setAudioFileDeleted(true);
  }


  console.log(values, "values Speaker Video")
  console.log(values?.isPublished, "values?.isPublished")

  return (
    <div className="add_speaker_video_main">
      {showSelectSpeaker ? (
        <ModalContainer {...{ setShowSelectSpeaker }}>
          <SelectSpeaker
            selectedData={values?.speakers}
            onSelectChange={(data) => {
              setValues({
                ...values,
                speakers: data,
              });
              validateForm(
                {
                  ...values,
                  speakers: data,
                },
                false
              );
            }}
          />
        </ModalContainer>
      ) : null}
      <div className="speaker_video_head">
        <div className="underLine_section">
          <h6>Speaker video</h6>
        </div>
        <div className="head_buttons_container">
          {/* <button type="button">Preview</button> */}

          {!values?.isPublished && values?.isPublished != undefined ? <button className="custom_publish_button" onClick={publishOptionHandler}>
            Publish <img src={BlueDropdownIcon} alt="BlueDropdownIcon" />
          </button> : null}

          {publishOption ? (
            <div className="publish_option_container">
              <CommonDatePicker values={values} setValues={setValues} validateForm={validateForm} errors={errors} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="body_add_speaker">
        <h1>Speaker Video</h1>
        <div className="Add_speaker_video_form">
          {/* <form action="" className="Add_speaker_video_form"> */}
          <div className="row">
            <div className="col-lg-6">
              <div class="form-group">
                <label>List Thumbnail (1920x1080px)</label>
                <br /><span style={{ fontSize: "10px", color: "#002566" }}> (Accepted format is .jpg, .png, .jpeg and size 350-400kb)</span>
                {values?.imageValues?.loaded ? (
                  <div>
                    <img
                      style={{
                        height: 250,
                        width: "100%",
                      }}
                      src={values?.imageValues?.url}
                    />
                    <br></br>
                    <button
                      onClick={() => {
                        setValues({
                          ...values,
                          imageValues: {
                            loaded: false,
                            url: "",
                          },
                        });
                        validateForm(
                          {
                            ...values,
                            imageValues: {
                              loaded: false,
                              url: "",
                            },
                          },
                          false
                        );
                      }}
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div className="upload_photo_container">
                    <img src={importPhotoIcon} alt="" />
                    <p>
                      Drag and Drop or{" "}
                      <label htmlFor="AddPhoto"> Browse </label> to upload
                    </p>
                  </div>
                )}
                <div className="error-text">{errors?.imageValues}</div>
                <input
                  type="file"
                  class="form-control"
                  id="AddPhoto"
                  placeholder="Name"
                  style={{ display: "none" }}
                  onChange={onImageChange}
                />
              </div>
              <div class="form-group">
                <label htmlFor="">Video Link</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Video Link"
                  name="video"
                  value={values?.video}
                  onChange={handleChangeValues}
                />
                <div className="error-text">{errors?.video}</div>
              </div>
              <div class="form-group">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Title"
                  name="title"
                  value={values?.title}
                  onChange={handleChangeValues}
                />
                <div className="error-text">{errors?.title}</div>
              </div>

              <div class="form-group">
                <label htmlFor="">Description</label>
                <textarea
                  class="form-control"
                  style={{
                    height: 300,
                    width: "100%",
                  }}
                  name="description"
                  value={values?.description}
                  onChange={handleChangeValues}
                ></textarea>
                <div className="error-text">{errors?.description}</div>

                {/* <Editor
                  apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue="<p>Type here...</p>"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "bold italic underline | alignleft aligncenter " +
                      "alignright alignjustify | ",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                /> */}
              </div>
            </div>

            <div className="col-lg-6">
              <h6 className="setting_heading">Settings</h6>

              <div className="member_free_button_container">
                <p className="member_type">Member type</p>
                <p className="free_premium_container">
                  <span className="free_Font">Free</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isPremium}
                          onChange={() => {
                            setValues({
                              ...values,
                              isPremium: !values?.isPremium,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Premium</span>
                </p>
              </div>
              <div className="member_free_button_container">
                <p className="member_type">Certification</p>
                <p className="free_premium_container">
                  <span className="free_Font">Non-Certified</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isCertified}
                          onChange={() => {
                            setValues({
                              ...values,
                              isCertified: !values?.isCertified,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Certified</span>
                </p>
              </div>

              <div className="member_free_button_container">
                <p className="member_type">GCC</p>
                <p className="free_premium_container">
                  <span className="free_Font">No</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isGcc}
                          onChange={() => {
                            setValues({
                              ...values,
                              isGcc: !values?.isGcc,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Yes</span>
                </p>
              </div>

              <div className="form-group member_free_button_container">
                <p className="member_type">BEC</p>
                <p className="free_premium_container">
                  <span className="free_Font">No</span>
                  <FormGroup>
                    <FormControlLabel
                      className="status_radio_button custom_status_radio_button "
                      control={
                        <IOSSwitch
                          className="radio_button_background"
                          sx={{ m: 1 }}
                          checked={values?.isBec}
                          onChange={() => {
                            setValues({
                              ...values,
                              isBec: !values?.isBec,
                            });
                          }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="premium_Font">Yes</span>
                </p>
              </div>
              <div className="list_of_speakers_container">
                <ul>
                  {values?.speakers?.map((speaker, id) => {
                    return (
                      <li key={speaker?._id}>
                        <img src={speaker?.profilePic} alt="" />{" "}
                        <p>{speaker?.name}</p>
                      </li>
                    );
                  })}
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowSelectSpeaker(true);
                    }}
                  >
                    <img src={svg4} alt="" /> <p>Add Speaker +</p>
                  </li>
                </ul>
                <div className="error-text">{errors?.speakers}</div>
              </div>

              {/* <div class="form-group">
                <label htmlFor="">Audio</label>
                <label className="custom_audio " htmlFor="Audio">
                  {" "}
                  <img src={importPhotoIcon} alt="" />
                </label>
                <input
                  className="form-control d-none"
                  type="file"
                  id="Audio"
                  placeholder=""
                />
              </div> */}


              <div class="form-group">
                <label htmlFor="">Audio</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="custom_audio1" htmlFor="Audio">
                    {values?.audioValues?.loaded && (
                      <audio ref={audioRef} controls>
                        <source src={values?.audioValues?.url} type="audio/mpeg" />
                      </audio>
                    )}
                    <img src={importPhotoIcon} alt="" />
                  </label>
                  {
                    values?.audioValues?.loaded ?
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={_onDeleteAudio}>
                        <img src={deleteIcon} alt="" width={22} className="m-2" />
                      </label>
                      : null
                  }

                </div>

                <input
                  className="form-control d-none"
                  type="file"
                  id="Audio"
                  placeholder="Audio file"
                  onChange={onAudioChange}
                  ref={audioInputRef}
                />
                <div className="error-text">{errors?.audioFileError}</div>
              </div>



              <div class="form-group">
                <label htmlFor="">Interest area</label>
                <InterestAreaDropDown
                  selectedData={values?.interests}
                  onSelectChange={(data) => {
                    // console.log(data, "data");
                    setValues({
                      ...values,
                      interests: [...data],
                    });
                    validateForm({ ...values, interests: [...data] }, false);
                  }}
                />
                <div className="error-text">{errors?.interests}</div>
              </div>

              <div class="form-group">
                <label htmlFor="">Industry type</label>
                <IndustryTypeDropDown
                  selectedData={values?.industries}
                  onSelectChange={(data) => {
                    // console.log(data, "data");
                    setValues({
                      ...values,
                      industries: [...data],
                    });
                  }}
                />
                <div className="error-text">{errors?.industries}</div>
              </div>

              <div class="form-group">
                <label htmlFor="">Speaker Video Deep Link</label>
                <div className="custom_copy_container">
                  <input
                    className="form-control"
                    type="text"
                    ref={inputRef}
                    disabled
                    value={`https://forallcommunity.com/speaker-video/${values?._id}`}
                  />
                  <button onClick={handleCopy}>
                    {isCopied ? (
                      <DoneIcon color="success" />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </button>
                </div>
              </div>

              {/* <div class="form-group">
                <label htmlFor="">Industry type</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div> */}
            </div>
          </div>
          <div>
            {addMoreText ? (
              <Editor
                apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Type here...</p>"
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "bold italic underline | alignleft aligncenter " +
                    "alignright alignjustify | ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            ) : null}
          </div>
          {/* <button
            onClick={addMoreTextHandler}
            className="add_read_more_section_button"
            type="button"
          >
            Add Read Mode +
          </button> */}
          <div className="next_cancel_button_container">
            <button
              // onClick={showPreviewHandler}
              onClick={() => {
                validateForm(values, true);
              }}
            >
              Next
            </button>
            <button onClick={() => props?.setOpenEditModel(false)}>
              Cancel
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div >
  );
};
export default AddSpeakerVideoModal;
