import React, { useState } from 'react'
import ModalContainer from '../UICommon/Modal/Interest-Area-modal/Add-ModalContainer'
import Sidebar from './Sidebar/Sidebar'

const SidebarLayoutWithHeader = ({ children }) => {
  return (
    <Sidebar >{children}</Sidebar>
  )
}

const ModalWrapperLayout = ({ children }) => {
  const [ismodalOpen, setIsmodalOpen] = useState(false);

  return <>
    {children}
    <ModalContainer>
      <h1>hiiiiiiiiiiiiiiiiiiiiiiiiiii</h1>
    </ModalContainer>
  </>
}



export { SidebarLayoutWithHeader, ModalWrapperLayout }

