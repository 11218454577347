import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";
import "./AddSpeakers.scss";

import ModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddPracticeAreaModal from "../../UICommon/Modal/Practice-Area-Modal/AddPracticeAreaModal";
import EditPracticeArea from "../../UICommon/Modal/Practice-Area-Modal/EditPracticeArea";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";

import svg1 from "../../Asset/images/avatars/1.svg";
import svg2 from "../../Asset/images/avatars/2.svg";
import svg3 from "../../Asset/images/avatars/3.svg";
import svg4 from "../../Asset/images/avatars/4.svg";
import svg5 from "../../Asset/images/avatars/5.svg";
import svg6 from "../../Asset/images/avatars/6.svg";
import svg7 from "../../Asset/images/avatars/7.svg";
import svg8 from "../../Asset/images/avatars/8.svg";
import importPhotoIcon from "../../Asset/images/icons/importPhotoIcon.svg";
import dropdownArrow from "../../Asset/images/icons/dropdownArrow.svg";
import AddModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddUserModalcontainer from "../../UICommon/Modal/Add-Speakers-modal/AddUser-Modalcontainer";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";
import { Editor } from "@tinymce/tinymce-react";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import Apilib from "../../Api";
import { getDateString, notify, notifyTypes } from "../../Utils";

function createData(
  id,
  profileImg,
  name,
  Title,
  organization,
  description,
  protein
) {
  return {
    id,
    profileImg,
    name,
    Title,
    organization,
    description,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "profilepic",
    numeric: false,
    disablePadding: true,
    label: "PHOTO",
    enableSorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    enableSorting: true,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    enableSorting: true,
  },
  {
    id: "organization",
    numeric: false,
    disablePadding: false,
    label: "organization",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On",
    enableSorting: true,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "status",
    enableSorting: true,
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: " ",
    enableSorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // console.log(headCells);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            className="custom_table_checkbox"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => {
          console.log(headCell.id === orderBy, order);
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {headCell.enableSorting && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {orderBy === headCell.id ? (
                    <Box
                      className="SortArrow"
                      component="span"
                      sx={visuallyHidden}
                    >
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AddSpeakers = () => {
  const API = new Apilib();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdBy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [imageValues, setImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });
  const [editImageValues, setEditImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);

  const [deleteID, setDeleteID] = useState(null);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleRequestSort = (event, property) => {
    console.log(property);
    const isAsc = orderBy === property && order === "asc";
    console.log(isAsc, order, orderBy);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);
  const [values, setValues] = useState({
    name: "",
    title: "",
    organization: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    title: "",
    organization: "",
    description: "",
  });

  const [openAddUsers, setOpenAddUsers] = useState(false);
  const openAddUserModelHandler = () => {
    setOpenAddUsers(true);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    fetchSepakers();
    // setOrder("desc");
    // setOrderBy('createdAt');
  }, []);

  const fetchSepakers = (text) => {
    API.getSpeakers(text)
      .then((res) => {
        setRows(res?.data?.speakers);
        setPage(1);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target.name]: event?.target?.value,
    });

    validateForm(
      {
        ...values,
        [event?.target.name]: event?.target?.value,
      },
      false
    );
  };

  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setImageValues({
          ...imageValues,
          loaded: true,
          source: e?.target?.files[0],
          url: URL.createObjectURL(img),
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onEditImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState({
        //   image: URL.createObjectURL(img),
        // });
        setEditImageValues({
          ...editImageValues,
          loaded: true,
          source: e?.target?.files[0],
          url: URL.createObjectURL(img),
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const validateForm = (valuesX, shouldeSubmitForm = false) => {
    var isValid = true;
    var errorsX = {};
    if (imageValues?.loaded == false) {
      isValid = false;
      errorsX.image = "Image is required.";
    }
    if (valuesX?.name == "") {
      isValid = false;
      errorsX.name = "Name is required.";
    }

    if (valuesX?.organization == "") {
      isValid = false;
      errorsX.organization = "Organization is required.";
    }

    if (valuesX?.title == "") {
      isValid = false;
      errorsX.title = "Title is required.";
    }

    if (valuesX?.details == "") {
      isValid = false;
      errorsX.details = "Description is required.";
    }

    setErrors({
      ...errorsX,
    });
    if (isValid && shouldeSubmitForm) {
      formSubmit();
    }
  };
  const formSubmit = () => {
    var data = new FormData();
    data.append("photo", imageValues?.source);
    data.append("name", values?.name);
    data.append("title", values?.title);
    data.append("organization", values?.organization);
    data.append("details", values?.details);
    data.append("isActive", false);

    API.addSpeaker(data)
      .then((res) => {
        // console.log("added");
        notify(notifyTypes?.success, "Speaker Added Successfully.");
        setValues({
          name: "",
          title: "",
          organization: "",
          details: "",
        });
        setImageValues({
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        });
        fetchSepakers(searchValue);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Speaker.");
      })
      .finally((e) => {
        // setLoading(false);
        setOpenAddUsers(false);
      });
  };

  const deleteSpeaker = (id) => {
    console.log(id, "inside del");
    API.deleteSpeaker(id)
      .then((res) => {
        console.log("delete speaker", res?.data);
        notify(notifyTypes?.success, "Deleted Speaker Successfully.");
        fetchSepakers(searchValue);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Speaker.");
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editSpeaker(id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the status successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchSepakers(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const handleChangeEditValues = (event) => {
    setSelectedRow({
      ...selectedRow,
      [event?.target.name]: event?.target?.value,
    });

    validateEditForm(
      {
        ...selectedRow,
        [event?.target.name]: event?.target?.value,
      },
      false
    );
  };
  const validateEditForm = (valuesX, shouldeSubmitForm = false) => {
    var isValid = true;
    var errorsX = {};
    if (editImageValues?.loaded == false) {
      isValid = false;
      errorsX.image = "Image is required.";
    }
    if (valuesX?.name == "") {
      isValid = false;
      errorsX.name = "Name is required.";
    }

    if (valuesX?.organization == "") {
      isValid = false;
      errorsX.organization = "Organization is required.";
    }

    if (valuesX?.title == "") {
      isValid = false;
      errorsX.title = "Title is required.";
    }

    if (valuesX?.details == "") {
      isValid = false;
      errorsX.details = "Description is required.";
    }

    setErrors({
      ...errorsX,
    });
    if (isValid && shouldeSubmitForm) {
      editSpeaker();
    }
  };
  const editSpeaker = () => {
    var data = new FormData();
    data.append("photo", editImageValues?.source);
    data.append("name", selectedRow?.name);
    data.append("title", selectedRow?.title);
    data.append("organization", selectedRow?.organization);
    data.append("details", selectedRow?.details);
    data.append("isActive", selectedRow?.isActive);
    API.editSpeaker(selectedRow?._id, data)
      .then((res) => {
        // console.log("get interest areas", res?.data);
        notify(notifyTypes?.success, "Updated the Speaker successfully");

        // setRows(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the Speaker");
      })
      .finally((e) => {
        fetchSepakers(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };
  return (
    <>
      <div className="addSpeaker_special_main_container">
        <div
          style={{ marginBottom: "2rem" }}
          className="Page_heading_container"
        >
          <h1>Add Speakers</h1>
          <button onClick={openAddUserModelHandler} className="Add_Head_Button">
            <img src={AddIcon} style={{ marginRight: "0" }} alt="" />
          </button>
        </div>
        {openAddUsers ? (
          <AddUserModalcontainer {...{ openAddUsers, setOpenAddUsers }}>
            <div className="All_speaker_form_container">
              <div className="model_head_container">
                <div className="underLine_section">
                  <h6>Speaker</h6>
                </div>
                {/* <button>
                  Publish <img src={dropdownArrow} alt="" />
                </button> */}
              </div>
              <h1 style={{ marginBottom: "1rem" }} className="Dpecker_heading">
                Speaker
              </h1>

              <div className="add_specker_form">
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label htmlFor="" style={{ marginBottom: "0px" }}>
                        Photo ( 1080x1080px)
                      </label>
                      <br />
                      <span style={{ fontSize: "10px", color: "#002566" }}>
                        {" "}
                        (Accepted format is .jpg, .png, .jpeg and size 300-350kb)
                      </span>
                      {imageValues?.loaded == true ? (
                        <div>
                          <img
                            style={{
                              height: "25vh",
                              width: "70%",
                            }}
                            src={imageValues?.url}
                          />
                          <br></br>
                          <button
                            onClick={() => {
                              setImageValues({
                                ...imageValues,
                                loaded: false,
                                url: "",
                              });
                            }}
                          >
                            Remove Image
                          </button>
                        </div>
                      ) : (
                        <div
                          className="upload_photo_container"
                          style={{ width: "70%" }}
                        >
                          <img src={importPhotoIcon} alt="" />
                          <p>
                            Drag and Drop or{" "}
                            <label htmlFor="AddPhoto"> Browse </label> to upload
                          </p>
                        </div>
                      )}

                      <input
                        type="file"
                        class="form-control"
                        id="AddPhoto"
                        placeholder="Name"
                        accept="image/*"
                        onChange={onImageChange}
                      />
                      <div className="error-text">{errors?.image}</div>
                    </div>
                    <div class="form-group">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        name="name"
                        placeholder="Name"
                        value={values?.name}
                        onChange={handleChangeValues}
                      />
                      <div className="error-text">{errors?.name}</div>
                    </div>
                    <div class="form-group">
                      <label>Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Title"
                        name="title"
                        value={values?.title}
                        onChange={handleChangeValues}
                      />
                      <div className="error-text">{errors?.title}</div>
                    </div>
                    <div class="form-group">
                      <label>Organization</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        name="organization"
                        placeholder="Organization"
                        value={values?.organization}
                        onChange={handleChangeValues}
                      />
                      <div className="error-text">{errors?.organization}</div>
                    </div>
                  </div>
                  <div className="col-lg-6 custom_text_editor">
                    <label htmlFor="">Description</label>
                    <textarea
                      class="form-control"
                      value={values?.details}
                      style={{
                        height: 500,
                        width: "100%",
                      }}
                      onChange={handleChangeValues}
                      name="details"
                    ></textarea>
                    <div className="error-text">{errors?.details}</div>
                    {/* <Editor
                    apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue="<p>Type here...</p>"
                    init={{
                      height: 350,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "bold italic underline | alignleft aligncenter " +
                        "alignright alignjustify | ",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  /> */}
                  </div>
                </div>

                <div className="done_cancel_container">
                  <button
                    onClick={() => {
                      // setOpenAddUsers(false)
                      // formSubmit();
                      validateForm(values, true);
                    }}
                    className="btn done_button"
                    type="submit"
                  >
                    Done
                  </button>
                  <button
                    onClick={() => {
                      setOpenAddUsers(false);
                      setValues({
                        name: "",
                        title: "",
                        organization: "",
                        details: "",
                      });
                      setErrors({
                        name: "",
                        title: "",
                        organization: "",
                        details: "",
                      });
                      setImageValues({
                        loaded: false,
                        url: "",
                        type: "",
                        name: "",
                        source: "",
                      });
                    }}
                    className=" btn cancel_button"
                    type=""
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </AddUserModalcontainer>
        ) : null}

        {openEditModel ? (
          <AddUserModalcontainer
            openAddUsers={openEditModel}
            setOpenAddUsers={setOpenEditModel}
          >
            <div className="All_speaker_form_container">
              <div className="model_head_container">
                <h6>Edit Speaker</h6>
                {/* <button>
                  Publish <img src={dropdownArrow} alt="" />
                </button> */}
              </div>
              <h1 className="Dpecker_heading">Speaker</h1>

              <div className="add_specker_form">
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label htmlFor="" style={{ marginBottom: "0px" }}>
                        Photo (1080x1080px)
                      </label>
                      <br />
                      <span style={{ fontSize: "10px", color: "#002566" }}>
                        {" "}
                        (Accepted format is .jpg, .png, .jpeg and size 300-350kb)
                      </span>
                      {editImageValues.loaded == true ? (
                        <div>
                          <img
                            style={{
                              height: 100,
                              width: 100,
                            }}
                            src={editImageValues.url}
                          />
                          <br></br>
                          <button
                            onClick={() => {
                              setEditImageValues({
                                ...editImageValues,
                                loaded: false,
                                url: "",
                              });
                            }}
                          >
                            Remove Image
                          </button>
                        </div>
                      ) : (
                        <div className="upload_photo_container">
                          <img src={importPhotoIcon} alt="" />
                          <p>
                            Drag and Drop or{" "}
                            <label htmlFor="editPhoto"> Browse </label> to
                            upload
                          </p>
                        </div>
                      )}

                      <input
                        type="file"
                        class="form-control"
                        id="editPhoto"
                        placeholder="Name"
                        accept="image/*"
                        onChange={onEditImageChange}
                      />
                      <div className="error-text">{errors?.image}</div>
                    </div>
                    <div class="form-group">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        name="name"
                        placeholder="Name"
                        value={selectedRow?.name}
                        onChange={handleChangeEditValues}
                      />
                      <div className="error-text">{errors?.name}</div>
                    </div>
                    <div class="form-group">
                      <label>Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        placeholder="Title"
                        name="title"
                        value={selectedRow?.title}
                        onChange={handleChangeEditValues}
                      />
                      <div className="error-text">{errors?.title}</div>
                    </div>
                    <div class="form-group">
                      <label>Organization</label>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        name="organization"
                        placeholder="Organization"
                        value={selectedRow?.organization}
                        onChange={handleChangeEditValues}
                      />
                      <div className="error-text">{errors?.organization}</div>
                    </div>
                  </div>
                  <div className="col-lg-6 custom_text_editor">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      value={selectedRow?.details}
                      style={{
                        height: 500,
                        width: "100%",
                      }}
                      onChange={handleChangeEditValues}
                      name="details"
                    ></textarea>
                    <div className="error-text">{errors?.details}</div>
                    {/* <Editor
                  apiKey="qwllkgntevnqlcq0qsjykmo79nqw6h09nnlu2hzy8ww5mfa1"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue="<p>This is the initial content of the editor.</p>"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "bold italic underline | alignleft aligncenter " +
                      "alignright alignjustify | ",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                /> */}
                  </div>
                </div>

                <div className="done_cancel_container">
                  <button
                    onClick={() => {
                      setOpenEditModel(false);
                      validateEditForm(selectedRow, true);
                      // editSpeaker();
                    }}
                    className="btn done_button"
                    type="submit"
                  >
                    Done
                  </button>
                  <button
                    onClick={() => {
                      setOpenEditModel(false);
                      setSelectedRow({});
                      setErrors({
                        name: "",
                        title: "",
                        organization: "",
                        details: "",
                      });
                      setEditImageValues({
                        loaded: false,
                        url: "",
                        type: "",
                        name: "",
                        source: "",
                      });
                    }}
                    className=" btn cancel_button"
                    type=""
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </AddUserModalcontainer>
        ) : null}
        {openDeleteConfirmation ? (
          <DeleteConfirmation
            onDelete={() => {
              setOpenDeleteConfirmation(false);
              deleteSpeaker(deleteID);
            }}
            {...{ setOpenDeleteConfirmation }}
          />
        ) : null}

        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <SearchBarNActionButtonContainer
              onTextChange={(text) => {
                // console.log(text);
                setPage(1);
                setSearchValue(text);
                fetchSepakers(text);
              }}
              {...{ setOpenDeleteConfirmation }}
              className="codered"
            />

            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className="custom_table_css"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      // console.log(labelId,"labelId");

                      return (
                        <TableRowComponent
                          key={row?._id}
                          changeActiveStatus={(status, id) => {
                            changeActiveStatus(status, id);
                          }}
                          setSelectedRow={(row) => {
                            setSelectedRow(row);
                            setEditImageValues({
                              ...editImageValues,
                              loaded: true,
                              url: row?.profilePic,
                            });
                          }}
                          {...{
                            row,
                            index,
                            selected,
                            handleSelection,
                            showMoreActionForID,
                            setShowMoreActionForID,
                            setOpenEditModel,
                            setOpenDeleteConfirmation,
                            setDeleteID,
                          }}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="table_pageination">
              <TablePagination
                className="rowNo_pageCounting"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              <Pagination
                className="Table_Pagination_number"
                shape="rounded"
                count={needLength}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </Paper>
          {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
        </Box>
      </div>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setOpenDeleteConfirmation,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;

  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowMoreActionForID(null);
      }
    };

    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////

  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row?._id);
    }
  };
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          onClick={(event) => handleSelection(event, row.name)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        <img
          style={{
            height: 40,
            width: 40,
          }}
          src={row?.profilePic}
          alt=""
        />
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        {row.name}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        align="left"
      >
        {row?.title}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        align="left"
      >
        {row?.organization}
      </TableCell>
      
      {/* <TableCell
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
        size="medium"
        align="left"
      >
        <div className="ellipse-text"> {row?.details}</div>
      </TableCell> */}

      <TableCell
        style={{ cursor: "pointer", width: "20%" }}
        size="medium"
        align="left"
      >
        <div className="ellipse-text"> {getDateString(row?.createdAt)}</div>
      </TableCell>

      <TableCell size="medium" align="left">
        {row?.isActive ? (
          <button className="Active_statusButton">Active</button>
        ) : (
          <button className="Disabled_statusButton">InActive</button>
        )}
      </TableCell>
      <TableCell id={labelId} align="left">
        {" "}
        <div ref={ref}>
          <button className="more_option_manu" onClick={openMoreMenuHandler}>
            <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
          </button>
          {/* {showMoreActionForID === row?._id ? ( */}

          <ThreeDotOption
            show={showMoreActionForID === row._id}
            isActive={row?.isActive}
            changeActiveStatus={(status) => {
              changeActiveStatus(status, row?._id);
            }}
            setOpenEditModel={() => {
              setShowMoreActionForID(null);
              setOpenEditModel(true);
              setSelectedRow(row);
            }}
            setDeleteID={() => {
              setDeleteID(row?._id);
            }}
            {...{ setOpenDeleteConfirmation, showMoreActionForID }}
          />
        </div>
        {/* ) : null} */}
      </TableCell>
    </TableRow>
  );
};
export default AddSpeakers;
