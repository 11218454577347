import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import "./addBannerPromotion.scss";
import { Editor } from "@tinymce/tinymce-react";
import importPhotoIcon from "../../../Asset/images/icons/importPhotoIcon.svg";
import ChipComponent from "../../AddSpeakerVideo/ChipComponent";
import { preventDefault } from "@fullcalendar/core/internal";
import researchDetailImg from "../../../Asset/images/researchDetailImg.png";
import AddSpeakerVideoModalcontainer from "../../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import CropDemo from "./cropFunction/CropDemo";
import temp from "../../../Asset/images/temp.png";

import canvasPreview from "./cropFunction/canvasPreview";
import useDebounceEffect from "./cropFunction/useDebounceEffect";
import "react-image-crop/src/ReactCrop.scss";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import InterestAreaDropDown from "../../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../../UICommon/DropDowns/IndustryTypeDropDown";


import { ChromePicker } from "react-color";


import Apilib from "../../../Api";

const WIDTH_OF_DRAWER = "30vw";

export default function EditBannerPromotion({
  setOpenBannerPreview,
  toggleDrawer2,
  state2,
  setState2,
  setOpenEditModel,
  selectedRow,
  openEditDrawer,
  setOpenEditDrawer
}) {
  const editorRef = useRef(null);
  const API = new Apilib();

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [values, setValues] = useState({
    title: "",
    eyeBrowText: "",
    backgroundColor: "",
    link: "",
    interests: [],
    industries: [],
    details: "",
    image: {
      loaded: false,
      url: "",
      type: "",
      name: "",
      source: "",
    },
    contentLevel: [],
  });
  const [bgColor, setBgcolor] = useState("#fff");
  const [allInterest, setAllInterest] = useState({});
  const [allIndustry, setAllIndustry] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    eyeBrowText: "",
    backgroundColor: "",
    link: "",
    interests: "",
    details: "",
  });
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const openBannerPreviewHandler = (event) => {
    event.preventDefault();
    setOpenBannerPreview(values);
  };

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 335,
    height: 175,
  });

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      // Makes crop preview update between images.
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const centerAspectCrop = (width, height, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px", // Can be 'px' or '%'
          x: 25,
          y: 25,
          width: 335,
          height: 175,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const [openCropSection, setOpenCropSection] = useState(true);
  const closeCropSectionHandler = () => {
    setOpenCropSection(false);
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target.name]: event?.target?.value,
    });
    validateEditForm(
      {
        ...values,
        [event?.target.name]: event?.target?.value,
      },
      false
    );
  };

  const handleChangeComplete = (color) => {
    setBgcolor(color.hex);
    setValues({
      ...values,
      backgroundColor: color.hex,
    });
  };

  const validateEditForm = (valuesX, shouldeSubmitForm = false) => {
    var isValid = true;
    var errorsX = {};
    if (valuesX?.image?.loaded == false) {
      isValid = false;
      errorsX.image = "Image is required.";
    }
    // if (valuesX?.backgroundColor == "") {
    //   isValid = false;
    //   errorsX.backgroundColor = "backgroundColor is required.";
    // }

    // if (valuesX?.title == "") {
    //   isValid = false;
    //   errorsX.title = "Title is required.";
    // }
    // if (valuesX?.eyeBrowText == "") {
    //   isValid = false;
    //   errorsX.eyeBrowText = "eyeBrowText is required.";
    // }

    // if (valuesX?.link == "") {
    //   isValid = false;
    //   errorsX.link = "Link is required.";
    // }
    // if (valuesX?.details == "") {
    //   isValid = false;
    //   errorsX.details = "Description is required.";
    // }
    // if (valuesX?.interests?.length == 0) {
    //   isValid = false;
    //   errorsX.interests = "Please select atleast one interest.";
    // }
    setErrors({
      ...errorsX,
    });
    if (isValid && shouldeSubmitForm) {

      valuesX.contentLevel = [];
      if (valuesX.isPremium && !valuesX.contentLevel.includes("premium"))
        valuesX.contentLevel.push("premium");
      if (!valuesX.isPremium && !valuesX.contentLevel.includes("free"))
        valuesX.contentLevel.push("free");
      if (valuesX.isCertified && !valuesX.contentLevel.includes("certified"))
        valuesX.contentLevel.push("certified");
      if (
        !valuesX.isCertified &&
        !valuesX.contentLevel.includes("non-certified")
      )
        valuesX.contentLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.contentLevel.includes("GCC"))
        valuesX.contentLevel.push("GCC");
      if (valuesX.isBec && !valuesX.contentLevel.includes("BEC"))
        valuesX.contentLevel.push("BEC");

      // editSpeaker();
      setState2({ ...state2, ["right"]: false })
      setOpenBannerPreview(values);
      setOpenEditModel(false);
    }
  };

  const onImageChange = (e) => {
    console.log("reached");
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        // setChangeImageError("");
        // if(e.target.files[0].type)
        // this.setState2({
        //   image: URL.createObjectURL(img),
        // });
        setValues({
          ...values,
          image: {
            ...values?.image,
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  useEffect(() => {

    console.log("Inside edit useEffect, row : ", selectedRow);
    fillValues(selectedRow);
  }, [selectedRow]);

  const fillValues = (row) => {
    fetchInterests();
    fetchIndustries();
    console.log("interests : ", allInterest);
    let interestArr = [];
    let industryArr = [];
    if (allInterest.length > 0) {
      allInterest?.map(int => {
        if (row.interests.includes(int._id)) {
          interestArr.push({
            _id: int._id,
            name: int.name
          })
        }
      })
    }
    if (allIndustry.length > 0) {
      allIndustry?.map(int => {
        if (row.industries.includes(int._id)) {
          industryArr.push({
            _id: int._id,
            name: int.name
          })
        }
      })
    }
    console.log(interestArr);
    setValues({
      ...values,
      title: row?.title,
      eyeBrowText: row?.eyeBrowText,
      details: row?.description,
      backgroundColor: row?.backgroundColor,
      link: row?.link,
      interests: interestArr,
      industries: industryArr,
      isPremium: row?.contentLevel?.includes('premium'),
      isCertified: row?.contentLevel?.includes('certified'),
      isGcc: row?.contentLevel?.includes('GCC'),
      isBec: row?.contentLevel?.includes('BEC'),
      image: row?.image
        ? {
          loaded: true,
          url: row?.image,
        }
        : {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
    });
    setBgcolor(row?.backgroundColor);
  }
  const fetchInterests = () => {
    API.getInterestArea()
      .then((res) => {
        console.log("get interest areas", res?.data?.interests);
        setAllInterest(res?.data?.interests);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const fetchIndustries = () => {
    API.getIndustries()
      .then((res) => {
        console.log("get industries", res?.data?.industries);
        setAllIndustry(res?.data?.industries);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  // const getBestPeoplePratice = () => {
  //   API.getBanner(props.bppID)
  //     .then((res) => {
  //       var data = res?.data?.data;
  //       setValues({
  //         ...values,
  //         title: data?.title,
  //         slogan: data?.slogan,
  //         description: data?.content,
  //         practiseName: data?.practiseName,
  //         interests: data?.interests,
  //         imageValues: data?.logo
  //           ? {
  //               loaded: true,
  //               url: data?.logo,
  //             }
  //           : {
  //               loaded: false,
  //               url: "",
  //               type: "",
  //               name: "",
  //               source: "",
  //             },
  //         industries: [...data?.industries],
  //         practiseArea: [data?.practiseArea],
  //         // contentLevel: ["free", "non-certified"],
  //         isPremium: data?.isPremium,
  //         isCertified: data?.isCertified,
  //         isActive: data?.isActive,
  //         isGcc: data?.isGcc,
  //         isBec: data?.isBec,
  //         _id: data?._id,
  //         organizationType: data?.organizationType,
  //       });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     })
  //     .finally((e) => {
  //       // setLoading(false);
  //     });
  // };

  // console.log(completedCrop)
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer2(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={anchor}
            // open={state2[anchor]}
            open={openEditDrawer}
            // onClose={toggleDrawer2(anchor, false)}
            onClose={() => setOpenEditDrawer(false)}
          >
            <Box
              sx={{
                width:
                  anchor === "top" || anchor === "bottom"
                    ? "auto"
                    : WIDTH_OF_DRAWER,
              }}
              role="presentation"
              onClick={toggleDrawer2(anchor, true)}
            // onKeyDown={toggleDrawer2(anchor, false)}
            >

              <div className="main_container_add_banner_drawer">
                <h1 className="heading_add_drawer">Edit Banner Promotion</h1>
                <div>
                  {/* <form action=""> */}
                  <div class="form-group">


                    <label htmlFor="">Title</label>
                    <div className="eyebrow_title_container">
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        value={values.title}
                        name="title"
                        onChange={handleChangeValues}
                        placeholder="Add Title"
                      />
                    </div>
                    <div className="error-text">{errors?.title}</div>

                    <label htmlFor="">Eyebrow Title</label>
                    <div className="eyebrow_title_container">
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        value={values.eyeBrowText}
                        name="eyeBrowText"
                        onChange={handleChangeValues}
                        placeholder="Add Eyebrow Title"
                      />
                    </div>
                    <div className="error-text">{errors?.title}</div>

                    <label htmlFor="">Background color</label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: '8px'
                      }}
                    >
                      <ChromePicker
                        color={bgColor}
                        style={{ width: "300px" }}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                    <div className="error-text">{errors?.backgroundColor}</div>
                  </div>

                  <div class="form-group">
                    <label htmlFor="">Description</label>
                    <textarea
                      class="form-control"
                      value={values?.details}
                      style={{
                        height: 200,
                        width: "100%",
                      }}
                      onChange={handleChangeValues}
                      name="details"
                    ></textarea>
                    <div className="error-text">{errors?.details}</div>
                  </div>

                  <label htmlFor="">Link/Route</label>
                  <div className="eyebrow_title_container">
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      value={values.link}
                      name="link"
                      onChange={handleChangeValues}
                      placeholder="Link/Route"
                    />
                  </div>
                  <div className="error-text">{errors?.link}</div>



                  <div class="form-group">
                    <label htmlFor="">Featured Image 335x175px(1340 x 700px))</label>
                    <br /><span style={{ fontSize: "10px", color: "#002566" }}> (Accepted format is .jpg, .png, .jpeg and size 300-400kb)</span>

                    {values?.image.loaded == true ? (
                      <div>
                        <img
                          style={{
                            height: 250,
                            width: "100%",
                          }}
                          src={values?.image?.url}
                        />
                        <br></br>
                        <button
                          onClick={() => {
                            setValues({
                              ...values,
                              image: {
                                ...values?.image,
                                loaded: false,
                                url: "",
                              },
                            });
                          }}
                        >
                          Remove Image
                        </button>
                      </div>
                    ) : (
                      <div className="upload_photo_container">
                        <img src={importPhotoIcon} alt="" />
                        <p>
                          Drag and Drop or{" "}
                          <label htmlFor="AddPhoto"> Browse </label> to upload
                          <input
                            style={{ display: "none" }}
                            id="AddPhoto"
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}
                          // onChange={onSelectFile}
                          />
                        </p>
                      </div>
                    )}
                    <div className="error-text">{errors?.image}</div>

                    {/* )} */}
                  </div>

                  <div class="form-group">
                    <label htmlFor="">Interest area</label>
                    <InterestAreaDropDown
                      selectedData={values?.interests}
                      onSelectChange={(data) => {
                        // console.log(data, "data");
                        setValues({
                          ...values,
                          interests: [...data],
                        });
                        // validateForm(
                        //   { ...values, interests: [...data] },
                        //   false
                        // );
                      }}
                    />
                    <div className="error-text">{errors?.interests}</div>
                  </div>

                  <div class="form-group">
                    <label htmlFor="">Industry type</label>
                    <IndustryTypeDropDown
                      selectedData={values?.industries}
                      onSelectChange={(data) => {
                        // console.log(data, "data");
                        setValues({
                          ...values,
                          industries: [...data],
                        });
                      }}
                    />
                    <div className="error-text">{errors?.industries}</div>
                  </div>

                  <div className="title_radioButton_container">

                    <div className="member_free_button_container2">
                      <p className="member_type">Member type</p>
                      <p className="free_premium_container">
                        <span className="free_Font">Free</span>
                        <FormGroup>
                          <FormControlLabel
                            className="status_radio_button custom_status_radio_button "
                            control={
                              <IOSSwitch
                                className="radio_button_background"
                                sx={{ m: 1 }}
                                checked={values?.isPremium}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    isPremium: !values?.isPremium,
                                  });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                        <span className="premium_Font">Premium</span>
                      </p>
                    </div>
                    <div className="member_free_button_container2">
                      <p className="member_type">Certification</p>
                      <p className="free_premium_container">
                        <span className="free_Font">Non-Certified</span>
                        <FormGroup>
                          <FormControlLabel
                            className="status_radio_button custom_status_radio_button "
                            control={
                              <IOSSwitch
                                className="radio_button_background"
                                sx={{ m: 1 }}
                                checked={values?.isCertified}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    isCertified: !values?.isCertified,
                                  });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                        <span className="premium_Font">Certified</span>
                      </p>
                    </div>

                    <div className="member_free_button_container2">
                      <p className="member_type">GCC</p>
                      <p className="free_premium_container">
                        <span className="free_Font">No</span>
                        <FormGroup>
                          <FormControlLabel
                            className="status_radio_button custom_status_radio_button "
                            control={
                              <IOSSwitch
                                className="radio_button_background"
                                sx={{ m: 1 }}
                                checked={values?.isGcc}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    isGcc: !values?.isGcc,
                                  });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                        <span className="premium_Font">Yes</span>
                      </p>
                    </div>

                    <div className="form-group member_free_button_container2">
                      <p className="member_type">BEC</p>
                      <p className="free_premium_container">
                        <span className="free_Font">No</span>
                        <FormGroup>
                          <FormControlLabel
                            className="status_radio_button custom_status_radio_button "
                            control={
                              <IOSSwitch
                                className="radio_button_background"
                                sx={{ m: 1 }}
                                checked={values?.isBec}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    isBec: !values?.isBec,
                                  });
                                }}
                              />
                            }
                          />
                        </FormGroup>
                        <span className="premium_Font">Yes</span>
                      </p>
                    </div>
                  </div>

                  {/* ////////////////////////////////////////// */}
                  {!!imgSrc && openCropSection && (
                    <AddSpeakerVideoModalcontainer>
                      <div className="crop_image_container">
                        <h1>Corp Image</h1>
                        <div className="cropdemo_container">
                          <CropDemo
                            {...{
                              setCrop,
                              crop,
                              onSelectFile,
                              imgSrc,
                              completedCrop,
                              setCompletedCrop,
                              previewCanvasRef,
                              imgRef,
                              aspect,
                              onImageLoad,
                            }}
                          />
                        </div>
                        <div className="crop_button_container">
                          <button
                            type="button"
                          // onClick={cropHandel}
                          >
                            Crop
                          </button>
                          <button
                            type="button"
                            onClick={closeCropSectionHandler}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </AddSpeakerVideoModalcontainer>
                  )}
                  {/* ///////////////////////////////////////// */}

                  {/* <div class="form-group">
                    <label htmlFor="">Industry type</label>
                    <ChipComponent />
                  </div> */}

                  {/* <div class="form-group">
                    <label htmlFor="">Eyebrow Title</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Organization Type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div> */}
                  <div className="banner_next_cancel_container">
                    {/* <button onClick={openBannerPreviewHandler}>Next</button> */}
                    <button
                      onClick={() => {
                        validateEditForm(values, true);

                      }}
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        console.log("Inside close right drawer EDIT")
                        // setState2({ ["right"]: false });
                        setOpenEditDrawer(false)
                        // setOpenEditModel(false);
                        // toggleDrawer2(anchor, false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
