// import logo from './logo.svg';
// import './App.css';
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SplashScreen from "./UICommon/SplashScreen";
import Login from "./pages/Authentication/login/Login";
import RecoverPassword from "./pages/Authentication/ForgotAndReset/RecoverPassword";
import ResetPassword from "./pages/Authentication/ForgotAndReset/ResetPassword";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SidebarLayoutWithHeader } from "./MainComponents/_layout";
import InterestArea from "./pages/InterestArea/InterestArea";
import AddInterestAreaModal from "./UICommon/Modal/Interest-Area-modal/Add-ModalContainer";
import IndustryType from "./pages/IndustryType/IndustryType";
import PracticeArea from "./pages/PracticeArea/PracticeArea";
import AddSpeakers from "./pages/AddSpeakers/AddSpeakers";
import AddSpeakerVideo from "./pages/AddSpeakerVideo/AddSpeakerVideo";
import CaseStudy from "./pages/CaseStudy/CaseStudy";
import ResearchPaper from "./pages/ResearchPaper/ResearchPaper";
import Blogs from "./pages/Blogs/Blogs";
import BestPeoplePractices from "./pages/BestPeoplePractices/BestPeoplePractices";
import Users from "./pages/Users/Users";
import Admins from "./pages/Admins/Admins";
import CommunityGroups from "./pages/CommunityGroups/CommunityGroups";
import Events from "./pages/Events/Events";
import AddNewBlogs from "./pages/AddNewBlogs/AddNewBlogs";
import AdsBanner from "./pages/Banner/AdsBanner";
import DeleteConfirmation from "./pages/InterestArea/DeleteConfirmation/DeleteConfirmation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tryal2 from "./Tryal2";
import Polls from "./pages/Polls/Polls";

const PRELOADER_DURATION = 0;

function App() {
  const [preloader, setPreloader] = useState(false);

  const createToast = (data) => {
    toast(data?.message, {
      position: toast.POSITION.TOP_RIGHT,
      type: data?.type,
    });
  };

  console.log = () => {};

  const subcribeEvent = () => {
    document?.addEventListener("notify", (event) => {
      createToast(event?.detail);
    });
  };
  const unSubcribeEvent = () => {
    document?.removeEventListener("notify", (event) => {
      createToast(event?.detail);
    });
  };
  useEffect(() => {
    subcribeEvent();
    setPreloader(true);
    setTimeout(() => {
      setPreloader(false);
    }, PRELOADER_DURATION);

    return () => {
      unSubcribeEvent();
    };
  }, []);

  const admin = false;
  return (
    <>
      <ToastContainer />
      {/* <AddInterestAreaModal /> */}
      {preloader ? (
        <SplashScreen />
      ) : (
        <Router>
          <div className="App">
            <Routes>
              <Route exact path="/" element={<Login />}>
                {" "}
              </Route>

              <Route
                exact
                path="/RecoverPassword"
                element={<RecoverPassword />}
              ></Route>
              <Route
                exact
                path="/ResetPassword"
                element={<ResetPassword />}
              ></Route>
              <Route
                exact
                path="/PageNotFound"
                element={<PageNotFound />}
              ></Route>

              <Route
                exact
                path="/dashboard"
                element={<SidebarLayoutWithHeader></SidebarLayoutWithHeader>}
              ></Route>
              <Route
                exact
                path="/interest_area"
                element={
                  <SidebarLayoutWithHeader>
                    {" "}
                    <InterestArea />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/industry_type"
                element={
                  <SidebarLayoutWithHeader>
                    {" "}
                    <IndustryType />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/banner_ads"
                element={
                  <SidebarLayoutWithHeader>
                    <AdsBanner />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/add_speakers"
                element={
                  <SidebarLayoutWithHeader>
                    <AddSpeakers />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/speaker_videos"
                element={
                  <SidebarLayoutWithHeader>
                    {" "}
                    <AddSpeakerVideo />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/case_study"
                element={
                  <SidebarLayoutWithHeader>
                    <CaseStudy />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/research_paper"
                element={
                  <SidebarLayoutWithHeader>
                    <ResearchPaper />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/blog"
                element={
                  <SidebarLayoutWithHeader>
                    <Blogs />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/events"
                element={
                  <SidebarLayoutWithHeader>
                    <Events />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/practice_area"
                element={
                  <SidebarLayoutWithHeader>
                    <PracticeArea />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/best_people_practices"
                element={
                  <SidebarLayoutWithHeader>
                    <BestPeoplePractices />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/users"
                element={
                  <SidebarLayoutWithHeader>
                    {" "}
                    <Users />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/admins"
                element={
                  <SidebarLayoutWithHeader>
                    <Admins />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/community_groups"
                element={
                  <SidebarLayoutWithHeader>
                    <CommunityGroups />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/AddNewBlogs"
                element={
                  <SidebarLayoutWithHeader>
                    <AddNewBlogs />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/DeleteConfirmation"
                element={
                  <SidebarLayoutWithHeader>
                    <DeleteConfirmation />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>
              <Route
                exact
                path="/polls"
                element={
                  <SidebarLayoutWithHeader>
                    {" "}
                    <Polls />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>

              <Route
                exact
                path="/tryal2"
                element={
                  <SidebarLayoutWithHeader>
                    <Tryal2 />{" "}
                  </SidebarLayoutWithHeader>
                }
              ></Route>

              {/* <SplashScreen />
        <Login />
        <RecoverPassword />
        <ResetPassword />
        <Sidebar />
        <PageNotFound /> */}
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
