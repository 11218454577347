import React from 'react'
import "../Interest-Area-modal/Add-ModalContainer.scss"
import closeIcon from "../../../Asset/images/icons/closeIcon.svg"
const AddSpeakerVideoModalcontainer = ({ children, openAddVideo, setOpenAddVideo, classShort }) => {

    const closeModel = () => {
        setOpenAddVideo(false)
    }
    return (
        <div className="Main_Modal_container ">
            <div className={`modal_container ${classShort}`} style={{ width: "80%", maxHeight: "100%", overflowY: 'auto' }}>
                {children}
            </div>
        </div>
    )
}
export default AddSpeakerVideoModalcontainer;
