import React, { useState, useRef, useEffect } from "react";

import FullCalendar from "@fullcalendar/react"; // must go before plugins

import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import timeGridDay from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";
import "./events.scss";

import AddEvent from "./modal/AddEvent";
import EditEvent from "./modal/EditEvent";
import SelectSpeaker from "./modal/SelectSpeaker";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import PreviewAddEvent from "./modal/PreviewAddEvent";
import { Tooltip } from "bootstrap";
import CustomModal from "./modal/CustomModal";
import DateRangePicker from "rsuite/DateRangePicker";
import ModalContainer from "../../UICommon/Modal/Industry-Type-modal/Add-ModalContainer";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

import { notify, notifyTypes } from "../../Utils";
import Apilib from "../../Api";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { nanoid } from "nanoid";

let tooltipInstance = null;
const events = [
  {
    // this object will be "parsed" into an Event Object
    id: nanoid(),
    className: "customEventClass",
    title: "The pushpak", // a property!
    start: "2022-12-01", // a property!
    end: "2022-12-05", // a prope:! ** see important note below about 'end' **
    //FOR TIME
    startTime: "13:45",
    endTime: "18:50",
    startRecur: "2022-12-01",
    endRecur: "2022-12-05",
    //END FOR TIME
    overlap: true,
    extendedProps: {
      // title: "event 99",
      // date: "2022-12-01",
      description: "Lorem ipsum sit amet.",
    },
  },
  {
    id: nanoid(),
    className: "customEventClass",
    title: "event gggg",
    date: "2022-12-12",
    overlap: true,

    extendedProps: {
      description: "Lorem ipsum sit amet.",
    },
  },
  {
    id: nanoid(),
    className: "customEventClass",
    title: "event 985",
    date: "2022-12-01",
    overlap: true,
    extendedProps: {
      description: "Lorem ipsum sit amet.",
    },
  },
  {
    id: nanoid(),
    className: "customEventClass33",
    title: "event 445",
    date: "2022-12-01",
    overlap: true,
    extendedProps: {
      description: "Lorem ipsum sit amet.",
    },
  },
  {
    id: nanoid(),
    className: "customEventClass33",
    title: "event 345",
    date: "2022-12-01",
    overlap: true,
    extendedProps: {
      description: "Lorem ipsum sit amet.",
    },
  },
  {
    id: nanoid(),
    className: "customEventClass33",
    title: "event 123",
    date: "2022-12-01",
    overlap: true,
    extendedProps: {
      description: "Lorem ipsum sit amet.",
    },
  },
];
const Events = () => {
  const API = new Apilib();
  
  

  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [openEventPreview, setOpenEventPreview] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [eventObjects, setEventObjects] = useState([]);
  const [previewValues, setPreviewValues] = useState({});
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [showThreeDotMenu, setShowThreeDotMenu] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currEventId, setCurrEventId] = useState("");

  const handleMouseEnter = (info) => {
    if (info.event.extendedProps.description) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  // const selectHandler = (selectionInfo) => {
  // };
  // const eventClickHandler = (eventClickInfo) => {
  // };
  // const showEventContentViewer = (eventInfo) => {
  //   console.log(eventInfo);
  // };
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef = useRef(null);

  const [title, setTitle] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const [state, setState] = useState({});

  const handleDateSelect = (selectInfo) => {
    // console.log(selectInfo.view.type);
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect();
      setState({ selectInfo, state: "create" });
      // Open modal create
      console.log("open modal create");
      // console.log(selectInfo);
      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  };
  const renderEventContent = (eventInfo) => {
    // console.log(eventInfo);

    // console.log("showMoreActionForID --> ", showMoreActionForID, " eventInfo.event.id --> ", eventInfo.event.id);
    return (
      <div >
        {/* <ThreeDotOption
          show={showMoreActionForID == eventInfo.event.id}
          isActive={currentEvent?.isActive}
          changeActiveStatus={(status) => {
            changeActiveStatus(status, eventInfo.event.id);
          }}
          setOpenEditModel={() => {
            setOpenEditEvent(true);
          }}
          onDelete={() => {
            console.log("delete");
          }}
          setDeleteID={() => {
            setDeleteID(eventInfo.event.id);
          }}
          {...{ setOpenDeleteConfirmation }}
        /> */}
        <div
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            whiteSpace: "normal",  
          }}
        >
          {eventInfo.event.title}
          {/* <b>{eventInfo.timeText}</b> */}
        </div>
      </div>
    );
  };
  const handleEventClick = (clickInfo) => {
    // console.log("open modal update, delete");
    console.log("event click -> ", clickInfo.event.id);
    eventObjects.map(event => {
      if (event._id == clickInfo.event.id) {
        setCurrentEvent(event);
        setCurrEventId(clickInfo.event.id);
        setOpenEditEvent(true);
        // setShowThreeDotMenu(true);
        // openMoreMenuHandler(clickInfo.event.id);
      }
    });
    // setModal(true);
  };
  const handleEvents = (events) => {
    // console.log("event in set ", events);
    setCurrentEvents(events);
  };
  const handleEventDrop = (checkInfo) => {
    // console.log(checkInfo.event.start.toISOString());
    // checkInfo.revert();
    setState({ checkInfo, state: "drop" });
    setConfirmModal(true);
  };
  const handleEventResize = (checkInfo) => {
    // console.log(checkInfo);
    setState({ checkInfo, state: "resize" });
    setConfirmModal(true);
  };
  const handleDateClick = (arg) => {
    // bind with an arrow function
    // console.log(arg.dateStr);
  };
  // only arrow function
  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };
  const handleClose = () => {
    setTitle("");
    setStart(new Date());
    setEnd(new Date());
    setState({});
    setModal(false);
  };
  const handleEdit = () => {
    state.clickInfo.event.setStart(start);
    state.clickInfo.event.setEnd(end);
    state.clickInfo.event.mutate({
      standardProps: { title },
    });
    handleClose();
  };
  const handleSubmit = () => {
    // console.log(state.selectInfo.view.calendar);
    const newEvent = {
      id: nanoid(),
      title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    };
    // console.log(newEvent);
    let calendarApi = calendarRef.current.getApi();
    // let calendarApi = selectInfo.view.calendar
    calendarApi.addEvent(newEvent);
    handleClose();
  };
  const handleDelete = () => {
    // console.log(JSON.stringify(state.clickInfo.event));
    // console.log(state.clickInfo.event.id);
    state.clickInfo.event.remove();
    handleClose();
  };


  useEffect(() => {
    fetchEvents();
  }, []);

  // const ref = useRef();
  // useEffect(() => {
  //   console.log("showMoreActionForID --> ", showMoreActionForID);
  //   setAllEvents(allEvents);

  //   const checkIfClickedInside = (e) => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     e.stopPropagation();
  //     document
  //       .querySelector("*")
  //       .addEventListener("mousedown", checkIfClickedOutside);
  //   };
  //   const checkIfClickedOutside = (e) => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     e.stopPropagation();
  //     if (
  //       showMoreActionForID &&
  //       ref.current &&
  //       !ref.current.contains(e.target)
  //     ) {
  //       setShowMoreActionForID(null);
  //     }
  //   };
  //   document
  //     .querySelector("*")
  //     .addEventListener("mousedown", checkIfClickedInside);
  //   return () => {
  //     // Cleanup the event listener
  //     document
  //       .querySelector("*")
  //       .removeEventListener("mousedown", checkIfClickedOutside);
  //     document
  //       .querySelector("*")
  //       .removeEventListener("mousedown", checkIfClickedInside);
  //   };
  // }, [showMoreActionForID]);

  // const openMoreMenuHandler = (id) => {
  //   // console.log(" showMoreActionForID --> ", showMoreActionForID);
  //   // console.log("currentEvent?._id --> ", id);
  //   if (showMoreActionForID == id) {
  //     setShowMoreActionForID(null);
  //   } else {
  //     setShowMoreActionForID(id);
  //   }
  // };

  // useEffect(() => {
  //   console.log('allEvents -> ', allEvents);
  // }, [allEvents]);

  const convertDateTime = (input) => {
    const dateObj = new Date(input);
    const date = dateObj.toISOString().slice(0, 10);
    const time = dateObj.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    return [date, time];
  }

  const fetchEvents = () => {
    API.getEvents()
      .then((res) => {
        console.log("fetching events -> ", res.data.events);
        const eventArr = res.data.events;
        setEventObjects(res.data.events);
        if (eventArr.length > 0) {
          var eventsObjArr = []
          eventArr.map(event => {

            const start = convertDateTime(event?.startDateOfEvent);
            const end = convertDateTime(event?.endDateOfEvent);
            const options = { timeZone: 'Asia/Kolkata' };

            let eventObj = {
              id: event?._id,
              title: event?.title,
              start: new Date(start.toLocaleString('en-US', options)),
              end: new Date(end.toLocaleString('en-US', options)),
              className: "customEventClass",
              extendedProps: {
                description: event?.description
              },
              isActive: event?.isActive,
            }
            // console.log(eventObj);
            eventsObjArr.push(eventObj);
          })
          setAllEvents(eventsObjArr);
        }
      })
  }
  const addEvent = async () => {
    const res1 = await API.addEvent(previewValues);
    console.log(res1.data.event);
    try {
      const res2 = await uploadMedia(res1.data.event._id);
      console.log(res2.data, " media uploaded");
    } catch (err) {
      console.log("err in media upload ", err);
    }
    notify(notifyTypes?.success, "Event Added Successfully");
    fetchEvents();
    setOpenAddEvent(false);
  }
  const editEvent = async () => {
    const res1 = await API.editEvent(currentEvent._id, previewValues);
    console.log("EDIT RESPONSE", res1.data.event);
    try {
      const res2 = await uploadMedia(res1.data.event._id);
      console.log(res2.data, " media uploaded");
    } catch (err) {
      console.log("err in media upload ", err);
    }
    notify(notifyTypes?.success, "Event Edited Successfully");
    fetchEvents();
    setOpenEditEvent(false);
  }
  const uploadMedia = async (id) => {
    if (previewValues?.imageValues?.source) {
      var mediaData = new FormData();
      mediaData.append("thumbnail", previewValues?.imageValues?.source);
      const res = await API.uploadEventMedia(id, mediaData);
      return res;
    }
    else return null;
  }
  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editEvent(id, data)
      .then((res) => {
        notify(notifyTypes?.success, "Updated the status successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        fetchEvents();
      });
  };
  const deleteEvent = async () => {
    const res1 = await API.deleteEvent(currentEvent._id);
    console.log("DELETE RESPONSE", res1.data.event);
    
    notify(notifyTypes?.success, "Event Deleted Successfully");

    fetchEvents();
    setOpenEditEvent(false);
  }

  const [formattedDatePreview, setFormattedDatePreview] = useState()
  const [DateFomat, setDateFomat] = useState()
  const [TimeFomat, setTimeFomat] = useState()
// console.log(durationInHr)
  return (
    <div className="custom_clander_event_container">
      <div className="Page_heading_container">
        <h1>Event Calender</h1>
        <button
          onClick={() => setOpenAddEvent(true)}
          className="Add_Head_Button"
        >
          <img src={AddIcon} alt="" /> Add Event
        </button>
      </div>
      {openAddEvent ? (
        <AddSpeakerVideoModalcontainer>
          <AddEvent {...{ setOpenAddEvent, setOpenEventPreview, setPreviewValues,setDateFomat,setFormattedDatePreview,setTimeFomat }} />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openEventPreview ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewAddEvent {...{ setOpenEventPreview, previewValues, addEvent, editEvent, setOpenEditEvent,DateFomat,formattedDatePreview,TimeFomat }} />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openEditEvent ? (
        <AddSpeakerVideoModalcontainer>
          <EditEvent {...{ setOpenEditEvent, setOpenEventPreview, setPreviewValues, currentEvent, changeActiveStatus, setCurrEventId, setOpenDeleteConfirmation }} />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deleteEvent(currEventId);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}



      <div className="custom_calander_height">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            timeGridDay,
            interactionPlugin,
          ]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth timeGridWeek timeGridDay",
          }}
          fixedWeekCount={false}
          nowIndicator={true}
          // eventContent={renderEventContent} // custom render function
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          dayMaxEventRows={3}
          eventMaxStack={3}
          events={allEvents}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={() => handleEvents(events)}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dateClick={handleDateClick}
        />
      </div>
      <CustomModal
        title={state.state === "update" ? "Update Event" : "Add Event"}
        isOpen={modal}
        toggle={handleCloseModal}
        onCancel={handleCloseModal}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Update" : "Save"}
        onDelete={state.clickInfo && handleDelete}
        deleteText="Delete"
      >
        <FormGroup>
          <Label for="exampleEmail">Title</Label>
          <Input
            type="text"
            name="title"
            placeholder="with a placeholder"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">From - End</Label>

          {/* <DateRangePicker
            initialSettings={{
              locale: {
                format: "M/DD hh:mm A",
              },
              startDate: start,
              endDate: end,
              timePicker: true,
            }}
            onApply={(event, picker) => {
              setStart(new Date(picker.startDate));
              setEnd(new Date(picker.endDate));
            }}
          >
            <input className="form-control" type="text" />
          </DateRangePicker> */}
          <DateRangePicker
            initialSettings={{
              locale: {
                format: "M/DD hh:mm A",
              },
              startDate: start,
              endDate: end,
              timePicker: true,
            }}
            onApply={(event, picker) => {
              setStart(new Date(picker.startDate));
              setEnd(new Date(picker.endDate));
            }}
            format="yyyy-MM-dd hh:mm aa"
            showMeridian
          // defaultCalendarValue={[
          //   new Date("2022-02-01 00:00:00"),
          //   new Date("2022-05-01 23:59:59"),
          // ]}
          />
        </FormGroup>
      </CustomModal>
    </div>
  );
};

export default Events;
