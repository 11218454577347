import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Apilib from "../../Api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ChipComponent = (props) => {
  const [allInterest, setAllInterest] = useState([]);
  const API = new Apilib();

  const theme = useTheme();
  const [selectedInterest, setSelectedInterest] = React.useState([]);


  useEffect(() => {
    // console.log("inside selected0 -----------------", props?.selectedData);
    getPracticeArea();
  }, []);

  const getPracticeArea = () => {
    API.getPracticeAreaActiveOnly()
      .then((res) => {
        // console.log("get interest areas", res?.data?.interests);
        setAllInterest(res?.data?.practiseAreas);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("inside handleChange", value);
    setSelectedInterest(value);
    props?.onSelectChange(value);
  };
  const handleDelete = (InterestId) => {
    const updatedSelectedInterest = selectedInterest.filter(
      (intrested) => intrested._id !== InterestId
    );
    setSelectedInterest(updatedSelectedInterest);
    props?.onSelectChange(updatedSelectedInterest);

    console.log("delete", updatedSelectedInterest);
  };
  return (
    <div>
      <FormControl className="For_chip_section" sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label"></InputLabel>
        {/* {console.log(props, "selectedInterest")} */}
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props?.selectedData}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {console.log("SJKEBWJHEGJHEGJHsE:", selected)}
              {selected?.map((interest) => (
                <Chip
                  key={interest?._id}
                  label={interest?.name}
                  onDelete={() => {
                    handleDelete(interest._id);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {allInterest?.map((interest) => (
            <MenuItem
              key={interest?._id}
              value={interest}
              style={getStyles(interest?.name, selectedInterest, theme)}
            >
              {interest?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default ChipComponent;
