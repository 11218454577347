import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeadingContainer from "../../UICommon/TableHeadContainer/TableHeadingContainer";
import SearchBarNActionButtonContainer from "../../UICommon/SeachBar-ActionButton/SearchBarNActionButtonContainer";
import threeDots from "../../Asset/images/icons/threeDots.svg";
import "../InterestArea/interestArea.scss";

import "../Users/users.scss";

import ModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddPracticeAreaModal from "../../UICommon/Modal/Practice-Area-Modal/AddPracticeAreaModal";
import EditPracticeArea from "../../UICommon/Modal/Practice-Area-Modal/EditPracticeArea";
import AddIcon from "../../Asset/images/interest Area/Plus.svg";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ActionButtonDropdown from "../../UICommon/SeachBar-ActionButton/ActionButtonDropdown";

import importPhotoIcon from "../../Asset/images/icons/importPhotoIcon.svg";
import dropdownArrow from "../../Asset/images/icons/dropdownArrow.svg";
import AddModalContainer from "../../UICommon/Modal/Practice-Area-Modal/Add-ModalContainer";
import AddUserModalcontainer from "../../UICommon/Modal/Add-Speakers-modal/AddUser-Modalcontainer";

import { Editor } from "@tinymce/tinymce-react";
import ThreeDotOption from "../../UICommon/ThreeDotOption/ThreeDotOption";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import AddCommunityGroupModal from "./Modals/AddCommunityGroup-modal";
import { FormGroup } from "@mui/material";
import { blue, pink } from "@mui/material/colors";
import Apilib from "../../Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDateString, notifyTypes, notify } from "../../Utils";
import DeleteConfirmation from "../InterestArea/DeleteConfirmation/DeleteConfirmation";
import PreviewSpeakerVideoModel from "../AddSpeakerVideo/Modals/PreviewSpeakerVideo-model";
import EditCommunityGroupModal from "./Modals/EditCommunityGroup-modal";

const activeButton = <button className="Active_statusButton"> Active </button>;
const DisabledButton = (
  <button className="Disabled_statusButton"> Blocked </button>
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Community Name",
    enableSorting: true,
  },
  {
    id: "interests",
    numeric: false,
    disablePadding: false,
    label: "Interest",
    enableSorting: false,
  },
  {
    id: "industryArea",
    numeric: false,
    disablePadding: false,
    label: "Industry area",
    enableSorting: false,
  },
  {
    id: "totalMembers",
    numeric: true,
    disablePadding: false,
    label: "Total Members",
    enableSorting: false,
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created On ",
    enableSorting: true,
  },

  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
                        className="custom_table_checkbox"
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.enableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <Box
                    className="SortArrow"
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Nutrition */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CommunityGroups = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMoreActionForID, setShowMoreActionForID] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  // const [previewValues, setPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [editID, setEditID] = useState(null);
  const [previousImage, setPreviousImage] = useState(false);

  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
  });

  const [imageValues, setImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    title: "",
    organization: "",
    description: "",
  });

  const [editImageValues, setEditImageValues] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });

  const API = new Apilib();

  useEffect(() => {
    fetchAllCommunity();
    setOrder("desc");
    setOrderBy("createdAt");
  }, []);

  const fetchAllCommunity = (text) => {
    API.getAllCommunity(text)
      .then((res) => {
        setRows(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleRequestSort = (event, property) => {
    debugger;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const needLength = Math.ceil(rows.length / rowsPerPage);

  const [showMoreAction, setShowMoreAction] = useState(false);

  const [openAddCommunity, setOpenAddCommunity] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const openAddUserModelHandler = () => {
    setOpenAddCommunity(true);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  };

  const onDoneHandler = (e) => {
    const payload = {
      email: values.email,
      password: values.password,
      name: values.name,
      role: values.role,
    };
    console.log(payload);
    console.log("values before submit : ", payload);
    API.addAdmin(payload)
      .then((res) => {
        setOpenAddCommunity(false);
        notify(notifyTypes?.success, "User Added Successfully.");
        // fetchAdmins();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, `${e.response.data.error}`);
      })
      .finally((e) => {});
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    console.log(valuesX);
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Name is required.";
    } else if (valuesX.name.length < 8) {
      isValidForm = false;
      errorsX.name = "Name length must be atleast 8 characters long";
    }

    if (valuesX?.mobile == "") {
      isValidForm = false;
      errorsX.mobile = "Phone Number is required.";
    }
    if (valuesX?.email == "") {
      isValidForm = false;
      errorsX.email = "Email is required.";
    }

    if (valuesX?.role == "") {
      isValidForm = false;
      errorsX.role = "Role is required.";
    }

    if (valuesX?.password == "") {
      isValidForm = false;
      errorsX.password = "Password is required.";
    } else if (!checkPassword(valuesX?.password)) {
      isValidForm = false;
      errorsX.password =
        "Password should have minimum length of 8, one special symbol , upper and lowercase characters";
    }

    setErrors({ ...errorsX });

    if (isValidForm && shouldeSubmitForm) {
      onDoneHandler(valuesX);
    }
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const onImageChange = (e) => {
    console.log(e.target);
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      let name = e?.target?.files[0].name;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setImageValues({
          loaded: true,
          url: URL.createObjectURL(img),
          type: type,
          name: name,
          source: e?.target?.files[0],
        });
        setErrors({
          ...errors,
          image: "",
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  const onCancelModal = () => {
    setValues({
      name: "",
      mobile: "",
      email: "",
      password: "",
      role: "",
    });
    setOpenAddCommunity(false);
  };

  const changeActiveStatus = (status, id) => {
    var data = {
      isActive: status,
    };
    API.editAdmin(id, data)
      .then((res) => {
        notify(notifyTypes?.success, "Updated the status successfully");
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to update the status");
      })
      .finally((e) => {
        // fetchAdmins(searchValue);
        // props?.onEdited();
        // setLoading(false);
      });
  };

  const deleteUser = (id) => {
    console.log(
      "Inside delete community --------------------------------------",
      id
    );

    API.deleteCommunity(id)
      .then((res) => {
        console.log("delete User", res?.data);
        notify(notifyTypes?.success, "Community Deleted Successfully.");
        fetchAllCommunity();
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to delete Community.");
      });
  };

  const uploadMedia = (id, previewValues, alreadyNotified = false) => {
    if (!alreadyNotified)
      notify(notifyTypes?.success, "Community Updated Successfully");
    if (
      previewValues?.imageValues?.loaded &&
      previewValues?.imageValues?.source
    ) {
      var mediaData = new FormData();
      mediaData.append("image", previewValues?.imageValues?.source);
      console.log(mediaData, id, "mediaData, id");
      API.addCommunityImage(mediaData, id)
        .then((res) => {
          console.log("Community Image response", res?.data?.data?.path);
          const pathToImage = res?.data?.data?.path;
          var data = {
            ...previewValues,
            profilePic: pathToImage,
          };
          editCommunity(id, data, true);
        })
        .catch((e) => {
          console.log(e);
          notify(notifyTypes?.error, "Failed to Add Image");
        });
    }
    // (previousImage && !previewValues?.imageValues?.loaded) - Earlier delete logic
    if (!previewValues?.imageValues?.loaded) {
      var data = {
        ...previewValues,
        profilePic:
          "https://gptw-test.s3.ap-south-1.amazonaws.com/communities/640079d51c067607b11be0c9/imagedk/fihI4pF6xpzsL", // dummmy image for now as gptw logo, if profile pic is empty.
      };
      editCommunity(id, data, true);
    }
  };

  const createCommunity = (previewValues) => {
    console.log("This is community");
    console.log(previewValues);
    var id = "";
    var data = {
      ...previewValues,
    };
    delete data.imageValues;

    API.addCommunity(data)
      .then((res) => {
        console.log("response", res?.data);
        notify(notifyTypes?.success, "Community  Added Successfully");
        id = res?.data?.data?.community._id;
        uploadMedia(id, previewValues, true);
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Add Community");
      })
      .finally((e) => {
        // props.onAdded();
        // setLoading(false);
        fetchAllCommunity();
        // setPreviewValues({});
        setShowPreview(false);
        setOpenAddCommunity(false);
      });
  };

  const editCommunity = (id, previewValues, backEndUpload = false) => {
    // var data = {
    //   title: previewValues?.title,
    //   video: previewValues?.video,
    //   description: previewValues?.description,
    //   interests: previewValues?.interests,
    //   industries: previewValues?.industries,
    //   speakers: previewValues?.speakers,
    //   contentLevel: previewValues?.contentLevel,
    //   isPremium: previewValues?.isPremium,
    //   isCertified: previewValues?.isCertified,
    //   isActive: previewValues?.isActive,
    //   scheduledTime: previewValues?.scheduledTime,
    // };
    var data = {
      ...previewValues,
    };

    delete data.imageValues;

    console.log("data before editing ----> ", data);
    API.editCommunity(id, data)
      .then((res) => {
        console.log("edit community  response", res.data.data);
        if (!backEndUpload) {
          uploadMedia(id, previewValues);
        }
      })
      .catch((e) => {
        console.log(e);
        notify(notifyTypes?.error, "Failed to Update Community");
      })
      .finally((e) => {
        // props.onAdded();
        // setLoading(false);
        fetchAllCommunity();
        // setPreviewValues({});
        setShowPreview(false);
        setOpenEditModel(false);
        setEditID(null);
      });
  };

  return (
    <>
      <div className="Page_heading_container" style={{ marginBottom: "2rem" }}>
        <h1>Community Group</h1>
        <button onClick={openAddUserModelHandler} className="Add_Head_Button">
          <img style={{ marginRight: "0px" }} src={AddIcon} alt="" />
        </button>
      </div>

      {openEditModel ? (
        <AddSpeakerVideoModalcontainer>
          <EditCommunityGroupModal
            Certification_Switch_Disabled={true}
            id={selectedRow._id}
            editCommunity={editCommunity}
            searchValue={searchValue}
            {...{ setOpenEditModel, setPreviousImage }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {openDeleteConfirmation ? (
        <DeleteConfirmation
          onDelete={() => {
            setOpenDeleteConfirmation(false);
            deleteUser(deleteID);
          }}
          {...{ setOpenDeleteConfirmation }}
        />
      ) : null}

      {openAddCommunity ? (
        <AddSpeakerVideoModalcontainer
          {...{ openAddCommunity, setOpenAddCommunity }}
        >
          <AddCommunityGroupModal
            createCommunity={createCommunity}
            setShowPreview={(values) => {
              // setPreviewValues(values);
              setShowPreview(true);
            }}
            {...{
              showPreview,
              setOpenAddCommunity,
            }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      {showPreview ? (
        <AddSpeakerVideoModalcontainer>
          <PreviewSpeakerVideoModel
            // data={previewValues}
            edit={editID ? true : false}
            // onPublish={() => {
            //     if (editID) {
            //         editSpeakerVideo(editID);
            //     } else {
            //         createSpeakerVideo();
            //     }
            // }}
            {...{ showPreview, setShowPreview }}
          />
        </AddSpeakerVideoModalcontainer>
      ) : null}

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <SearchBarNActionButtonContainer
            className="codered"
            onTextChange={(text) => {
              console.log("This is my seratch text");
              console.log(text);
              setPage(1);
              setSearchValue(text);
              fetchAllCommunity(text);
            }}
            {...{ setOpenDeleteConfirmation }}
          />

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className="custom_table_css"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    // console.log(labelId,"labelId");

                    return (
                      <TableRowComponent
                        // key={row?._id}
                        changeActiveStatus={(status, id) => {
                          changeActiveStatus(status, id);
                        }}
                        {...{
                          row,
                          index,
                          selected,
                          handleSelection,
                          showMoreActionForID,
                          setShowMoreActionForID,
                          setOpenEditModel,
                          setOpenDeleteConfirmation,
                          setSelectedRow,
                          setDeleteID,
                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="table_pageination">
            <TablePagination
              className="rowNo_pageCounting"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Pagination
              className="Table_Pagination_number"
              shape="rounded"
              count={needLength}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </Paper>
      </Box>
    </>
  );
};
const TableRowComponent = ({
  row,
  index,
  selected,
  handleSelection,
  showMoreActionForID,
  setShowMoreActionForID,
  setOpenEditModel,
  setSelectedRow,
  changeActiveStatus,
  setDeleteID,
  setOpenDeleteConfirmation,
}) => {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  //////////////////////////////////////////////////////////////////
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedInside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      document
        .querySelector("*")
        .addEventListener("mousedown", checkIfClickedOutside);
    };
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      e.stopPropagation();
      if (
        showMoreActionForID &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        return setShowMoreActionForID(null);
      }
    };

    document
      .querySelector("*")
      .addEventListener("mousedown", checkIfClickedInside);
    return () => {
      // Cleanup the event listener
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedOutside);
      document
        .querySelector("*")
        .removeEventListener("mousedown", checkIfClickedInside);
    };
  }, [showMoreActionForID]);
  //////////////////////////////////////////////////////////////////////////
  const openMoreMenuHandler = () => {
    if (showMoreActionForID == row?._id) {
      setShowMoreActionForID(null);
    } else {
      setShowMoreActionForID(row._id);
    }
  };

  console.log("Length Count");
  console.log(row?.interests?.length);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
                    className="custom_table_checkbox"
                    onClick={(event) => handleSelection(event, row.name)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                /> */}
      </TableCell>

      <TableCell
        align="left"
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        {row.name ? row.name : "N/A"}
      </TableCell>
      <TableCell
        align="left"
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        {row?.interests?.length > 1
          ? `${row?.interests[0]?.name}, +${row?.interests?.length - 1} more`
          : row?.interests?.length == 1
          ? row?.interests[0]?.name
          : "N/A"}
      </TableCell>
      <TableCell
        align="left"
        style={{ cursor: "alias" }}
        onClick={(event) => {
          setShowMoreActionForID(null);
          setOpenEditModel(true);
          setSelectedRow(row);
        }}
      >
        {row?.industries?.length > 1
          ? `${row?.industries[0]?.name}, +${row?.industries?.length - 1} more`
          : row?.industries?.length == 1
          ? row?.industries[0]?.name
          : "N/A"}
      </TableCell>
      <TableCell align="left">{row.nmembers ? row.nmembers : "N/A"}</TableCell>
      <TableCell className="custom_column_grey_color" align="left">
        {getDateString(row.createdAt)}
      </TableCell>
      {/* <TableCell className="Active_statusButton" align="left">{row.isActive ? 'Active' : 'Not Active'}</TableCell> */}
      {/* <TableCell align="left">
                {row?.isActive ? (
                    <button className="Active_statusButton"> Active </button>
                ) : (
                    <button className="Disabled_statusButton"> InActive </button>
                )}
            </TableCell> */}
      <TableCell id={labelId} align="left" ref={ref}>
        {" "}
        <button className="more_option_manu" onClick={openMoreMenuHandler}>
          <img style={{ cursor: "pointer" }} src={threeDots} alt="" />
        </button>
        {/* {showMoreActionForID === row.id ? */}
        <ThreeDotOption
          show={showMoreActionForID === row._id}
          isActive={row?.isActive}
          changeActiveStatus={(status) => {
            console.log("status", status);
            changeActiveStatus(status, row?._id);
          }}
          setOpenEditModel={() => {
            setShowMoreActionForID(null);
            setOpenEditModel(true);
            setSelectedRow(row);
          }}
          setDeleteID={() => {
            setDeleteID(row?._id);
          }}
          hideEditStatus={true}
          {...{ setOpenDeleteConfirmation }}
        />
      </TableCell>
    </TableRow>
  );
};
export default CommunityGroups;
