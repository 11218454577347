// import React from 'react'

//  const AddNewBlogs = () => {
//   return (
//     <div>AddNewBlogs</div>
//   )
// }
// export default AddNewBlogs;



import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DropDown from "./DropDown";

// https://gptw-app.s3.ap-south-1.amazonaws.com/blogs/636b5bf586e397d192d97e64/image1ev507GhPVumk6bl
// https://gptw-app.s3.ap-south-1.amazonaws.com/blogs/636b5bf586e397d192d97e64/image8ZPNOuQzfr%2B6vAcj
// https://gptw-app.s3.ap-south-1.amazonaws.com/blogs/636b601730cfd54300a0ebb9/image58Lr3W4QFrNST36g

let baseApiUrl = "https://api.forallcommunity.com/api/v1/";

// casestudies, researchpapers, blogs, bestpeoplepractises
let contentType = "blogs";

export default function AddNewBlogs() {
  const [token, setToken] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzQ0MTg2YWM5MzkyNDRiOGFmYTI0NTEiLCJpYXQiOjE2NzMwNzUzNDgsImV4cCI6MTY3NDM3MTM0OH0.Kl1cvV0J_Gp_zBfGoNpEa1_nVTgGjcnREbOBHXb7zm4"
  );
  const [contents, setContents] = useState([]);
  const [id, setId] = useState("");
  const editorRef = useRef(null);
  useEffect(() => {
    const getAllContents = async () => {
      const res = await fetch(`${baseApiUrl}/admin/${contentType}`, {
        method: "GET",
         
        headers: {
            mode:'cors',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (!data.success) {
        alert(data.error);
        return;
      }
      setContents(data[contentType]);
    };

    getAllContents();
  }, []);

  const images_upload_handler = (blobInfo, progress) =>
    new Promise(async (resolve, reject) => {
      let formData = new FormData();
      formData.append("image", blobInfo.blob(), blobInfo.filename());
      formData.append("contentType", contentType);

      try {
        const res = await fetch(`${baseApiUrl}/admin/content/${id}/`, {
          method: "POST",
          body: formData,
          redirect: "follow",
           
          headers: {
            mode:'cors',
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        const data = await res.json();
        if (!data.success) {
          reject(data.message);
          return;
        }
        resolve(data.location);
      } catch (err) {
        return reject(err);
      }
    });
  const upload = async () => {
    if (!token) {
      alert("Login Required");
    }
    if (!id) {
      alert("Id Required");
    }

    if (editorRef.current) {
      let head = `<html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <style>
          * {
            background: #F9F9FA;
          }
          img {
            width: 100%;
          }
          </style>
        </head>
        <body>`;
      let content = editorRef.current.getContent();
      let htmlclose = `</body></html>`;
      let finalHtml = head + content + htmlclose;
      let formData = new FormData();
      formData.append("content", finalHtml);
      try {
        const res = await fetch(`${baseApiUrl}/${contentType}/${id}`, {
          method: "PUT",
          body: formData,
          redirect: "follow",
           
          headers: {
            mode:'cors',
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        });
        const data = await res.json();
        if (!data.success) {
          alert(data.message);
          return;
        }

        alert("Uploaded!!");
      } catch (err) {
        return alert(err);
      }
    }
  };
  const login = async () => {
    const credentials = {
      email: "arkaraj@test.com",
      password: "test1234",
    };
    const res = await fetch(`${baseApiUrl}/admin/login`, {
      method: "POST",
       
      headers: {
        mode:'cors',
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });
    const data = await res.json();
    if (!data.success) {
      alert(data.error);
      return;
    }
    console.log(data);
    setToken(data.accessToken);
  };

  return (
    <>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue="<p>Write your content Here</p>"
        init={{
          height: 750,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "code",
            "image",
          ],
          selector: "textarea#file-picker",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code",
          image_title: true,
          automatic_uploads: true,
          file_picker_types: "image media",
          images_upload_handler,
          // image_dimensions: false,
          // image_class_list: [{ title: "Responsive", value: "img-responsive" }],
          // style_formats: [
          //   {
          //     title: "Image responsive",
          //     selector: "img",
          //     styles: {
          //       width: "100%",
          //       height: "auto",
          //     },
          //   },
          // ],
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <button onClick={upload}>Upload Content</button>
      <br />
      <DropDown contents={contents} setId={setId} />
      <br />
      <button onClick={login}>Login Admin</button>
    </>
  );
}

// init={{
//   selector: 'textarea#file-picker',
//   plugins: 'image code',
//   toolbar: 'undo redo | link image | code',
//   /* enable title field in the Image dialog*/
//   image_title: true,
//   /* enable automatic uploads of images represented by blob or data URIs*/
//   automatic_uploads: true,
//   /*
//     URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
//     images_upload_url: 'postAcceptor.php',
//     here we add custom filepicker only to Image dialog
//   */
//   file_picker_types: 'image',
//   /* and here's our custom image picker*/
//   file_picker_callback: function (cb, value, meta) {
//     var input = document.createElement('input');
//     input.setAttribute('type', 'file');
//     input.setAttribute('accept', 'image/*');

//     /*
//       Note: In modern browsers input[type="file"] is functional without
//       even adding it to the DOM, but that might not be the case in some older
//       or quirky browsers like IE, so you might want to add it to the DOM
//       just in case, and visually hide it. And do not forget do remove it
//       once you do not need it anymore.
//     */

//     input.onchange = function () {
//       var file = this.files[0];

//       var reader = new FileReader();
//       reader.onload = function () {
//         /*
//           Note: Now we need to register the blob in TinyMCEs image blob
//           registry. In the next release this part hopefully won't be
//           necessary, as we are looking to handle it internally.
//         */
//         var id = 'blobid' + (new Date()).getTime();
//         var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
//         var base64 = reader.result.split(',')[1];
//         var blobInfo = blobCache.create(id, file, base64);
//         blobCache.add(blobInfo);

//         /* call the callback and populate the Title field with the file name */
//         cb(blobInfo.blobUri(), { title: file.name });
//       };
//       reader.readAsDataURL(file);
//     };

//     input.click();
//   },
//   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
// }}