import React from 'react'
import "./Import-ModelContainer.scss"
import closeIcon from "../../../Asset/images/icons/closeIcon.svg"

const ImportModelContainer = ({children,importOpen, setImportOpen,className}) => {

    const closeModel = () =>{
        setImportOpen(false)
    }

    return (
        <div className={`Main_Modal_container ${className.className}`} >
            <div className="modal_container">
                <button className='CloseButton'
                 onClick={closeModel}
                 >
                    <img src={closeIcon} alt="" /></button> 
               {children}
            </div>
        </div>
    )
}

export default ImportModelContainer;
