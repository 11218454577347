import React, { useState, useRef, useEffect } from "react";
import AddSpeakerVideoModalcontainer from "../../UICommon/Modal/Add-Speaker-Video-model/AddSpeakerVideo-Modalcontainer";
import importPhotoIcon from "../../Asset/images/icons/importPhotoIcon.svg";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import ChipComponent from "../ChipComponent";
import InterestAreaDropDown from "../../UICommon/DropDowns/InterestAreaDropDown";
import IndustryTypeDropDown from "../../UICommon/DropDowns/IndustryTypeDropDown";
import download from "../../Asset/images/download.svg";
import moment from "moment";

import Apilib from "../../Api";

const ResultQuestions = (props) => {
  const API = new Apilib();
  const imageref = useRef();
  const id = props?.id;
  const [values, setValues] = useState({
    name: "",
    options: [],
    startDate: "",
    endDate: "",
  });
  const [pollCsv, setPollCsv] = useState();

  const [errors, setErrors] = useState({
    name: "",
  });

  const [selectedOption, setSelectedOption] = useState({
    option1: true,
    option2: false,
    option3: false,
    option4: false,
  });

  const [viewCommunityMembers, setViewCommunityMembers] = useState(false);

  useEffect(() => {
    fetchSpecificPolls(id);
    fetchCsv();
  }, [id]);

  const fetchSpecificPolls = (id) => {
    API.getSpecificPolls(id)
      .then((res) => {
        console.log("Get specific polls");
        console.log(res?.data);
        var data = res?.data?.poll;
        setValues({
          name: data?.title,
          options: data?.options,
          startDate: data?.startDate,
          endDate: data?.endDate,
        });
        props.setPreviousImage(data?.profilePic ? true : false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        // setLoading(false);
      });
  };

  const fetchCsv = () => {
    API.getPollCsv(id)
      .then((res) => {
        console.log(res?.data?.csv);
        setPollCsv(res?.data?.csv);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const validateForm = (valuesX, shouldeSubmitForm) => {
    var isValidForm = true;
    var errorsX = {};

    if (valuesX?.name == "") {
      isValidForm = false;
      errorsX.name = "Community Name is required.";
    }

    if (valuesX?.description == "") {
      isValidForm = false;
      errorsX.description = "Description is required.";
    }

    setErrors({ ...errorsX });
    if (isValidForm && shouldeSubmitForm) {
      valuesX.userLevel = ["free", "non-certified"];

      if (valuesX.isPremium && !valuesX.userLevel.includes("premium"))
        valuesX.userLevel.push("premium");
      // if (!valuesX.isPremium && !valuesX.userLevel.includes("free"))
      //   valuesX.userLevel.push("free");
      if (valuesX.isCertified && !valuesX.userLevel.includes("certified"))
        valuesX.userLevel.push("certified");
      // if (
      //   !valuesX.isCertified &&
      //   !valuesX.userLevel.includes("non-certified")
      // )
      //   valuesX.userLevel.push("non-certified");
      if (valuesX.isGcc && !valuesX.userLevel.includes("GCC"))
        valuesX.userLevel.push("GCC");
      if (valuesX.isBec && !valuesX.userLevel.includes("BEC"))
        valuesX.userLevel.push("BEC");

      console.log(valuesX, "scheduledTime ");
      props.editCommunity(id, valuesX);
      // props?.setShowPreview(valuesX);

      setValues({
        name: "",
        description: "",
        interests: [],
        industries: [],
        imageValues: {
          loaded: false,
          url: "",
          type: "",
          name: "",
          source: "",
        },
        userLevel: [],
        isPremium: false,
        isCertified: false,
        isActive: false,
        isGcc: false,
        isBec: false,
      });

      setErrors({
        name: "",
      });
    }
  };

  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        setValues({
          ...values,
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: URL.createObjectURL(img),
          },
        });

        imageref.current.value = null;
      }
    }
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const _viewMembers = () => {
    setViewCommunityMembers(true);
  };

  return (
    <>
      <div className="add_speaker_video_main">
        <div className="body_add_speaker">
          <div className="Add_speaker_video_form">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">Question</label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Title"
                    name="name"
                    value={values.name}
                    onChange={handleChangeValues}
                    disabled
                    style={{ backgroundColor: "white" }}
                  />
                  <div className="error-text">{errors?.name}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">Options</label>
                  {values.options?.map((_data) => (
                    <input
                      key={_data?._id}
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Option Name"
                      name="name"
                      value={_data.option}
                      onChange={handleChangeValues}
                      disabled
                      style={{ marginTop: 10, backgroundColor: "white" }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">Start Date</label>
                      <div
                        className="form-control"
                        style={{
                          borderRadius: "1rem",
                          display: "flex",
                          flex: 1,
                          padding: 0,
                          marginTop: 8,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            padding: "0.37rem",
                            borderRight: "1px solid #ced4da",
                            justifyContent: "center",
                            fontSize: "0.9rem",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {moment(values.startDate).format("HH:mm")}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            padding: "0.37rem",
                            justifyContent: "center",
                            fontSize: "0.9rem",
                            alignItems: "center",

                            textAlign: "center",
                          }}
                        >
                          {moment(values.startDate).format("DD.MM.YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">End Date</label>
                      <div
                        className="form-control"
                        style={{
                          borderRadius: "1rem",
                          display: "flex",
                          flex: 1,
                          padding: 0,
                          marginTop: 8,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            padding: "0.37rem",
                            borderRight: "1px solid #ced4da",
                            justifyContent: "center",
                            fontSize: "0.9rem",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {moment(values.endDate).format("HH:mm")}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            padding: "0.37rem",
                            justifyContent: "center",
                            fontSize: "0.9rem",
                            alignItems: "center",

                            textAlign: "center",
                          }}
                        >
                          {moment(values.endDate).format("DD.MM.YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="speaker_video_head">
          <h1 style={styles.headingCommunity}>Results</h1>
          <div className="body_add_speaker">
            <div className="Add_speaker_video_form">
              <div className="downloadPollButton">
                <form method="get" action={pollCsv}>
                  <button>
                    <img src={download} alt="" style={styles.imgDownload} />
                    Download Data
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResultQuestions;

const styles = {
  headingCommunity: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#3f434a",
    marginTop: 15,
    marginBottom: 15,
  },
  spanForViewButton: {
    color: "#002566",
    cursor: "pointer",
  },
  viewMembersTag: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgDownload: {
    marginRight: 10,
  },
};
