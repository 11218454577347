import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import statusIcon from "../../Asset/images/icons/status.svg";
import edit from "../../Asset/images/icons/edit.svg";
import deleteIcon from "../../Asset/images/icons/deleteIcon.svg";

import "../SeachBar-ActionButton/action-button-dropdown.scss";
import EditInterestAreaModal from "../Modal/Interest-Area-modal/EditInterestAreaModal";

const ThreeDotOption = (props) => {
  const [isActive, setIsActive] = useState(props?.isActive == true ? true : false);
  const opeditHandler = () => {
    props?.setOpenEditModel(true);
    // props.showEditSliderHandler("right", true);

    // console.log("pushhhhhhhhhhh");
    // props.openedit(true);
  };
  const openDeleteConfirmationHandler = () => {
    console.log("inside openDeleteConfirmationHandler-----------");
    props?.setDeleteID();
    props?.setOpenDeleteConfirmation(true);
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#002566",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));


  return (
    <div
      style={{ display: props?.show ? "block" : "none" }}
      className="action_dropdown_container custom_more_option_manu custom_three_dropdown_option"
    >
      {!props.hideEditStatus && (
        <div className="status_and_radio">
          <button>
            {" "}
            <img src={statusIcon} alt="" /> Status
          </button>
          <FormGroup>
            <FormControlLabel
              className="status_radio_button"
              control={
                <IOSSwitch
                  className="radio_button_background"
                  sx={{ m: 1 }}
                  checked={props.isActive}
                  onChange={() => {
                    setIsActive(!isActive);
                    // console.log(props, "props");
                    props?.changeActiveStatus(!isActive);
                  }}
                // defaultChecked
                />
              }
            />
          </FormGroup>
        </div>

      )}

      <button onClick={opeditHandler} className={props.cannotDeleteSelf ? 'mr-4' : 'mt-2'}>
        <img src={edit} alt="" /> Edit
      </button>
      {
        props.cannotDeleteSelf ? null :
          <>
            <div className="custom_hr_container">
              <hr className="" />
            </div>
            <button
              className={props.hideEditStatus ? `custom_delete_button mt-3 mb-1` : `custom_delete_button`}
              onClick={openDeleteConfirmationHandler}
            >
              <img src={deleteIcon} alt="" /> Delete{" "}
            </button>
          </>
      }
    </div>
  );
};
export default ThreeDotOption;
